import { Form } from '../form/form';
import { Stack } from '@mui/material';
import { SectionHeader, SectionHeaderProps } from '../section-header';
import { BasicJoinedUserSettingsType } from 'pages/user-profile/components/types';
import { FormValuesStateAndSetter } from '../../user-profile';
import { TIMEZONES } from '../utilities/timezones';
import { FormControlFields } from 'pages/user-profile/components/types';
import { ProfilePictureControl } from './profile-picture-control';
import { ExtendedUserSettingsData } from '../../../../react-query-toolkit/state/user-settings-context';
import { User } from '../../../../react-query-toolkit/state/types';
import { ExtendedSlUserData } from '../../../../react-query-toolkit/state/user-context';

const profileControls: FormControlFields[] = [
  {
    name: 'firstname',
    displayedName: 'First Name',
    controlType: 'input',
    inputType: 'text',
    isRequired: true,
  },
  {
    name: 'lastname',
    displayedName: 'Last Name',
    controlType: 'input',
    inputType: 'text',
    isRequired: true,
  },
  {
    name: 'phone',
    displayedName: 'Phone',
    controlType: 'input',
    inputType: 'tel',
    isRequired: true,
  },
  {
    name: 'jobtitle',
    displayedName: 'Job Title',
    controlType: 'input',
    inputType: 'text',
    isRequired: false,
  },
  {
    name: 'email',
    displayedName: 'Login Email',
    controlType: 'input',
    inputType: 'email',
    isRequired: true,
  },
  {
    name: 'secondaryemail',
    displayedName: 'Notice Email',
    controlType: 'input',
    inputType: 'email',
    isRequired: true,
  },

  {
    name: 'timezone',
    displayedName: 'Time Zone',
    controlType: 'select',
    options: TIMEZONES,
    isRequired: true,
  },
  {
    name: 'location',
    displayedName: 'Location',
    controlType: 'select',
    options: [
      { value: 'New York' },
      { value: 'Vancouver' },
      { value: 'Paris' },
      { value: 'Tokyo' },
      { value: 'Mumbai' },
      { value: 'Moscow' },
    ],
    isRequired: true,
  },
];

export const Profile = ({
  formInputValuesStateAndSetter,
  fetchedUserSettings,
  handleSubmit,
  userData,
  openStateSetter,
}: {
  formInputValuesStateAndSetter: FormValuesStateAndSetter;
  fetchedUserSettings: BasicJoinedUserSettingsType;
  handleSubmit: (
    userSettingsData: ExtendedUserSettingsData,
    slUserData?: ExtendedSlUserData,
  ) => Promise<void>;
  userData: User;
  openStateSetter: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sectionHeader: SectionHeaderProps = {
    title: 'Profile',
    subtitle: 'Update your photo and personal details here.',
  };

  return (
    <Stack
      rowGap={{ xs: 6 }}
      paddingY={{ xs: 9 }}
      paddingX={{ xs: 7, md: 10 }}
      borderRadius={1}
      bgcolor={'white'}
    >
      <SectionHeader
        title={sectionHeader.title}
        subtitle={sectionHeader.subtitle}
      />
      <ProfilePictureControl
        userData={userData}
        openStateSetter={openStateSetter}
      />
      <Form
        area="profile"
        formControlsInfo={profileControls}
        formInputValuesStateAndSetter={formInputValuesStateAndSetter}
        fetchedUserSettings={fetchedUserSettings}
        handleSubmit={handleSubmit}
      />
    </Stack>
  );
};
