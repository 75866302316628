export const Clients = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9137 8.72727C11.9137 10.9866 10.0821 12.8182 7.82279 12.8182C5.56344 12.8182 3.73188 10.9866 3.73188 8.72727C3.73188 6.46793 5.56344 4.63636 7.82279 4.63636C10.0821 4.63636 11.9137 6.46793 11.9137 8.72727Z"
      fill={color}
    />
    <path
      d="M20.0955 6.68182C20.0955 8.71523 18.4471 10.3636 16.4137 10.3636C14.3803 10.3636 12.7319 8.71523 12.7319 6.68182C12.7319 4.64841 14.3803 3 16.4137 3C18.4471 3 20.0955 4.64841 20.0955 6.68182Z"
      fill={color}
    />
    <path
      d="M14.3682 21C14.8201 21 15.1911 20.6325 15.141 20.1834C14.9567 18.5309 14.2168 16.9802 13.0297 15.7931C11.6487 14.4122 9.77575 13.6364 7.82279 13.6364C5.86983 13.6364 3.99686 14.4122 2.61591 15.7931C1.42881 16.9802 0.688885 18.5309 0.504544 20.1834C0.454448 20.6325 0.825467 21 1.27734 21L14.3682 21Z"
      fill={color}
    />
    <path
      d="M23.3229 18.5471C23.373 18.9962 23.0019 19.3636 22.5501 19.3636H15.8547C15.2692 16.2776 13.0859 14.4568 11.3808 13.6326C12.6841 12.5811 14.3144 12 16.0046 12C17.9576 12 19.8305 12.7758 21.2115 14.1568C22.3986 15.3439 23.1385 16.8945 23.3229 18.5471Z"
      fill={color}
    />
    <path
      d="M10.7238 14.2318C12.2526 14.8758 14.4069 16.4951 15.0186 19.3636L9.45915 19.3636C9.00729 19.3636 8.63627 18.9962 8.68636 18.5471C8.86684 16.9291 9.5799 15.4088 10.7238 14.2318Z"
      fill={color}
    />
  </svg>
);
