export const SM = 411;
export const MD = 768;
export const DESKTOP = 1280;
export const LG = 992;

export const breakpoints = {
  xs: '375px',
  sm: `${SM}px`,
  md: `${MD}px`,
  lg: `${LG}px`,
  xl: '1200px',
  xxl: '1440px',
  xxxl: '1600px',
  desktop: `${DESKTOP}px`,
  widescreen: '1600px',
};
