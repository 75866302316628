import React, { createContext, ReactNode, useContext } from 'react';
import {
  useGetPlAllQuery,
  useUpdatePlMutation,
  useUpdateSlMutation,
} from '../services/ghgci-sfe';
import { PlEntities } from '../services/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import useAuth from 'configs/AuthContext';
import { formatClientData } from 'pages/clients/components/format-client-data';
import { NewClientDataType } from 'pages/clients/api/get-clients';

type ClientContextType = {
  handleGetClients: () => NewClientDataType[];
  clientListIsLoading: boolean;
  clientsFetchingError: FetchBaseQueryError | SerializedError | undefined;

  isUpdateClientSuccess: boolean;
  isUpdateClientLoading: boolean;
  resetUpdateClientQueryValues: () => void;

  handleUpdateClient: (
    clientId: string,
    clientName: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    country: string,
    city: string,
    municipality: string,
    state: string,
    activeDate: string,
    closeDate: string,
    address: string,
    postalCode: string,
    organizationType: string,
  ) => void;
};

const defaultClientContext: ClientContextType = {
  handleGetClients: () => [], // Provide a default implementation for handleGetClients
  clientListIsLoading: false,
  clientsFetchingError: {},

  isUpdateClientSuccess: false,
  isUpdateClientLoading: false,
  resetUpdateClientQueryValues: () => {},

  handleUpdateClient: () => {},
};
const DataContext = createContext(defaultClientContext);

export const ClientDataProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const ghgciId: string = auth.user?.getSignInUserSession()!.getIdToken()
    .payload['custom:ghgci_id'];

  const [
    updateClient,
    {
      isLoading: isUpdateClientLoading,
      isSuccess: isUpdateClientSuccess,
      reset: resetUpdateClientQueryValues,
    },
  ] = useUpdateSlMutation();

  //Update Client Info
  const handleUpdateClient = (
    clientId: string,
    clientName: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    country: string,
    city: string,
    municipality: string,
    state: string,
    activeDate: string,
    closeDate: string,
    address: string,
    postalCode: string,
    organizationType: string,
  ) => {
    updateClient({
      typename: PlEntities.Client,
      id: clientId,
      requestBody: {
        typename: PlEntities.Client,
        clientname: clientName,
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        email: email,
        location: country,
        activedate: activeDate,
        closedate: closeDate,
        address: address,
        postalcode: postalCode,
        organizationtype: organizationType,
        id: clientId,
      },
    }).unwrap();
  };

  interface ExtendedPlEntity {
    typename: string;
    id: string;
    lookUp: string;
  }
  // This gets all clients under User (provided userId)
  const { isLoading: clientListIsLoading, error: clientsFetchingError } =
    useGetPlAllQuery({
      typename: PlEntities.Client,
      id: ghgciId,
      lookUp: PlEntities.Client,
    } as ExtendedPlEntity);

  const handleGetClients = () => {
    const { data } = useGetPlAllQuery({
      typename: PlEntities.Client,
    });

    if (data) {
      // Assuming formatClientData returns the expected array but missing 'typename'
      const formattedData = formatClientData(data.Items || []) as Omit<
        NewClientDataType,
        'typename'
      >[];

      // Add typename as 'client' for each item to match NewClientDataType
      return formattedData.map((item) => ({
        ...item,
        typename: 'client',
      })) as NewClientDataType[];
    }

    return [];
  };

  return (
    <DataContext.Provider
      value={{
        handleGetClients,
        clientListIsLoading,
        clientsFetchingError,
        handleUpdateClient,

        isUpdateClientLoading,
        isUpdateClientSuccess,
        resetUpdateClientQueryValues,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useClientDataContext = () => useContext(DataContext);
