import { NewClientDataType } from 'pages/clients/api/get-clients';

export const findClientNameById = (
  clientId: string,
  clientData: NewClientDataType[],
): string | undefined => {
  const matchingClient = clientData.filter(
    (client) => client.clientId === clientId,
  );
  return matchingClient[0]?.clientName;
};
