import React, { useEffect } from 'react';
import { Badge, IconButton } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  NotificationDataProvider,
  useNotificationDataContext,
} from '../../../../react-query-toolkit/state/notifications-context';

interface NotificationsProps {
  onClick?: () => void;
}

function notificationsLabel(count: number) {
  if (count === 0) {
    return 'no notifications';
  }
  if (count > 99) {
    return 'more than 99 notifications';
  }
  return `${count} notifications`;
}

export function NotificationsContainer({ onClick }: NotificationsProps) {
  const navigate = useNavigate();
  const { handleGetNotifications } = useNotificationDataContext();
  const notifications = handleGetNotifications() || [];
  const notificationCount = notifications.length;

  return (
    <NotificationDataProvider>
      <IconButton
        aria-label={notificationsLabel(notificationCount)}
        onClick={() => navigate('/notifications')}
      >
        <Badge
          color="secondary"
          badgeContent={notificationCount}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </NotificationDataProvider>
  );
}
