// This is a wrapper around icons to add hoverable styling
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { defaultTransitionTimeAndStyle } from 'utils/styles';

type Props = {
  children: ReactNode;
  hoverColor: string;
};

const HoverableContainer = styled.div<{ hoverColor: string }>`
  cursor: pointer;
  > svg {
    transition: fill ${defaultTransitionTimeAndStyle};
    > path {
      transition: fill ${defaultTransitionTimeAndStyle};
    }
  }
  &:hover {
    > svg {
      fill: ${({ hoverColor }) => hoverColor};
      > path {
        fill: ${({ hoverColor }) => hoverColor};
      }
    }
  }
`;

export const Hoverable = ({ children, hoverColor }: Props) => {
  return (
    <HoverableContainer hoverColor={hoverColor}>{children}</HoverableContainer>
  );
};
