import React, { useRef, useState } from 'react';
import {
  GlassContainer,
  InputContainer,
  TaskSearchInput,
} from 'pages/task-manager/components/styled';
import { MagnifyingGlass } from 'icons';

export function TaskSearchBar({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const [focussed, setFocussed] = useState(false);
  const onFocus = () => setFocussed(true);
  const onBlur = () => {
    //We need to check if mobile should be open so the user can see there is active filtering
    if (value === '') {
      setFocussed(false);
    }
  };
  const handleClick = () => {
    setFocussed(true);
    //      inputRef does not find the focus as the css is rendering the bar
    // Adding the timeout will ensure the browser has enough time to display the element
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1);
  };

  return (
    <InputContainer
      margin={'0 0 0 24px'}
      onClick={handleClick}
      active={focussed}
    >
      <GlassContainer>
        <MagnifyingGlass />
      </GlassContainer>
      <TaskSearchInput
        {...props}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={inputRef}
        value={value}
        active={focussed}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputContainer>
  );
}
