import { colors } from 'utils/colors';

export const ClientIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      d="M6.5 7C8.29512 7 9.75 5.54512 9.75 3.75C9.75 1.95488 8.29512 0.5 6.5 0.5C4.70488 0.5 3.25 1.95488 3.25 3.75C3.25 5.54512 4.70488 7 6.5 7ZM8.775 7.8125H8.35098C7.7873 8.07148 7.16016 8.21875 6.5 8.21875C5.83984 8.21875 5.21523 8.07148 4.64902 7.8125H4.225C2.34102 7.8125 0.8125 9.34101 0.8125 11.225V12.2812C0.8125 12.9541 1.3584 13.5 2.03125 13.5H10.9688C11.6416 13.5 12.1875 12.9541 12.1875 12.2812V11.225C12.1875 9.34101 10.659 7.8125 8.775 7.8125Z"
      fill={colors.secondaryBlue}
    />
  </svg>
);
