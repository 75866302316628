export const GhgciTextLogo = () => (
  <svg
    width="67"
    height="16"
    viewBox="0 0 67 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.84385 7.73584H12.1794V15.0612C11.3067 15.3473 10.467 15.5502 9.66038 15.67C8.86038 15.7897 7.99426 15.8496 7.06203 15.8496C4.86699 15.8496 3.17443 15.1976 1.98434 13.8935C0.79426 12.5828 0.199219 10.7365 0.199219 8.3546C0.199219 6.00596 0.870293 4.16964 2.21244 2.84562C3.55459 1.51495 5.40252 0.849609 7.75624 0.849609C9.27029 0.849609 10.705 1.14236 12.0604 1.72785L11.2273 3.71388C10.0505 3.16831 8.8736 2.89552 7.69674 2.89552C6.14963 2.89552 4.92649 3.38454 4.02732 4.36258C3.12814 5.34063 2.67856 6.67795 2.67856 8.37456C2.67856 10.1577 3.08186 11.5149 3.88847 12.4464C4.7017 13.3712 5.86864 13.8336 7.3893 13.8336C8.15624 13.8336 8.97608 13.7372 9.84881 13.5442V9.78174H6.84385V7.73584Z"
      fill="white"
    />
    <path
      d="M27.105 15.65H24.7248V9.07316H18.0405V15.65H15.6703V1.05919H18.0405V7.02725H24.7248V1.05919H27.105V15.65Z"
      fill="white"
    />
    <path
      d="M36.8538 7.73584H42.1893V15.0612C41.3166 15.3473 40.4769 15.5502 39.6703 15.67C38.8703 15.7897 38.0042 15.8496 37.0719 15.8496C34.8769 15.8496 33.1843 15.1976 31.9943 13.8935C30.8042 12.5828 30.2091 10.7365 30.2091 8.3546C30.2091 6.00596 30.8802 4.16964 32.2224 2.84562C33.5645 1.51495 35.4124 0.849609 37.7662 0.849609C39.2802 0.849609 40.7149 1.14236 42.0703 1.72785L41.2372 3.71388C40.0604 3.16831 38.8835 2.89552 37.7067 2.89552C36.1595 2.89552 34.9364 3.38454 34.0372 4.36258C33.1381 5.34063 32.6885 6.67795 32.6885 8.37456C32.6885 10.1577 33.0918 11.5149 33.8984 12.4464C34.7116 13.3712 35.8786 13.8336 37.3992 13.8336C38.1662 13.8336 38.986 13.7372 39.8587 13.5442V9.78174H36.8538V7.73584Z"
      fill="white"
    />
    <path
      d="M57.1248 2.89552C55.7629 2.89552 54.6918 3.38121 53.9116 4.3526C53.1315 5.32399 52.7414 6.66465 52.7414 8.37456C52.7414 10.1643 53.1149 11.5183 53.862 12.4364C54.6157 13.3546 55.7034 13.8137 57.1248 13.8137C57.7397 13.8137 58.3348 13.7538 58.91 13.634C59.4852 13.5076 60.0835 13.3479 60.705 13.155V15.2009C59.5678 15.6334 58.2786 15.8496 56.8372 15.8496C54.7149 15.8496 53.0852 15.2042 51.948 13.9135C50.8108 12.6161 50.2422 10.7631 50.2422 8.3546C50.2422 6.83763 50.5166 5.51029 51.0653 4.37256C51.6207 3.23484 52.4207 2.36325 53.4653 1.75779C54.51 1.15234 55.7364 0.849609 57.1447 0.849609C58.6257 0.849609 59.9943 1.16232 61.2505 1.78773L60.3976 3.77376C59.9083 3.54089 59.3893 3.33797 58.8405 3.16498C58.2984 2.98534 57.7265 2.89552 57.1248 2.89552Z"
      fill="white"
    />
    <path d="M63.829 15.65V1.05919H66.1992V15.65H63.829Z" fill="white" />
  </svg>
);
