export const Config = () => (
  <svg
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2H20.5479"
      stroke="#A6AFB6"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M1 10H20.5479"
      stroke="#A6AFB6"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9.34352 2C9.34352 2.86277 8.70073 3.5 7.98182 3.5C7.26291 3.5 6.62012 2.86277 6.62012 2C6.62012 1.13723 7.26291 0.5 7.98182 0.5C8.70073 0.5 9.34352 1.13723 9.34352 2Z"
      fill="white"
      stroke="#A6AFB6"
    />
    <path
      d="M17.2551 10C17.2551 10.8628 16.6123 11.5 15.8934 11.5C15.1745 11.5 14.5317 10.8628 14.5317 10C14.5317 9.13723 15.1745 8.5 15.8934 8.5C16.6123 8.5 17.2551 9.13723 17.2551 10Z"
      fill="white"
      stroke="#A6AFB6"
    />
  </svg>
);
