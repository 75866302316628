import ReactCalendar, { ViewCallback } from 'react-calendar';
import React, { SetStateAction, useEffect, useState } from 'react';
import {
  RightSideContainer,
  CalendarContainer,
  PriorityDot,
  Dot,
  DateCellWrapper,
  ModalContentContainer,
  Modal,
  ModalHeader,
  CloseButton,
  ModalText,
  SeeAllText,
  ModalContentContainerInner,
} from './styled';
import { CardHeader, TextContainer } from 'pages/dashboard/styled';
import { Heading, Hoverable } from 'components';
import { ArrowLeft, ArrowRight } from './icons';
import { isBefore, isSameDay, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useTaskDataContext } from '../../../../react-query-toolkit/state/tasks-context';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { parseInt } from 'lodash';
import { formatTaskData } from 'pages/task-manager/api/format-task-data';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { colors } from 'utils/colors';

const monthAndYearToString = (year: number, month: number) => {
  const DEFAULT_DATE = 1;
  const date = new Date(Date.UTC(year, month, DEFAULT_DATE));

  return date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });
};

export const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAll, setShowAll] = useState(false);
  const [selectedDayInMonth, setSelectedDayInMonth] = useState(date.getDate()); // Store selected day

  const monthAndYearToDisplay = monthAndYearToString(
    date.getFullYear(),
    date.getMonth() + 1,
  ).toUpperCase();

  const handleClickDay = (args: Date) => {
    handleClick(args);
  };

  const handleActiveDateChange: ViewCallback = ({ activeStartDate }) => {
    setDate(activeStartDate);
  };

  ///Fetching the task data
  const { handleGetTasks } = useTaskDataContext();
  const rawTaskData = handleGetTasks() || [];
  const { handleGetClients } = useUserDataContext();

  const clientData = handleGetClients() || [];

  function transformTaskData(taskData: NewTaskDataType[]) {
    return taskData
      .filter((task) => !task.isCompleted) // Filter out completed tasks
      .map((task) => ({
        date: task.dueDate,
        title: task.taskName,
        priority: task.priority,
      }));
  }

  const dates = transformTaskData(formatTaskData(rawTaskData, clientData));

  const [modal, setModal] = useState<{
    visible: boolean;
    content: { title: string; priority: string }[];
    remainingCount: number;
  }>({
    visible: false,
    content: [],
    remainingCount: 0,
  });

  const today = new Date();

  const openModal = (events: { title: string; priority: string }[]) => {
    const displayedEvents = events.slice(0, 3);
    const remainingCount = events.length - 3;
    setModal({
      visible: true,
      content: displayedEvents,
      remainingCount: remainingCount > 0 ? remainingCount : 0,
    });
    setShowAll(false);
  };

  const closeModal = () => {
    setModal({ visible: false, content: [], remainingCount: 0 });
    setShowAll(false);
    setDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDayInMonth,
      ),
    ); // Set date with selected day so the calendar knows which day is selected after closing modal
  };

  const handleClick = (date: Date) => {
    // This selection is needed so the calendar knows which day was previously selected before opening modal
    setSelectedDayInMonth(parseInt(dayjs(date).format('DD')));
    const dateEvents = dates.filter((d) => isSameDay(parseISO(d.date), date));
    if (dateEvents.length > 0) {
      const events = dateEvents.map((event) => ({
        title: event.title,
        priority: event.priority,
      }));
      setSelectedDate(date); // Update selectedDate state
      openModal(events);
    } else {
      setSelectedDate(date); // Update selectedDate state even if no events
    }
  };

  const renderTileContent = ({ date }: { date: Date }) => {
    const dateEvents = dates.filter((d) => isSameDay(parseISO(d.date), date));
    const past = isBefore(date, today);
    const isTodayDue = isSameDay(date, today);
    if (dateEvents.length > 0) {
      return (
        <DateCellWrapper hasEvents onClick={() => handleClick(date)}>
          <Dot
            isTodayDue={isTodayDue}
            past={past}
            onClick={() => handleClick(date)}
          />
        </DateCellWrapper>
      );
    } else {
      return (
        <DateCellWrapper onClick={() => handleClick(date)}>
          {/* No dot for this day */}
        </DateCellWrapper>
      );
    }
  };

  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const handleSeeAllClick = () => {
    setModal((prevModal) => ({
      ...prevModal,
      content: dates
        .filter((d) => isSameDay(parseISO(d.date), selectedDate))
        .map((event) => ({ title: event.title, priority: event.priority })),
    }));
    setShowAll(true);
  };

  const handleArrowLeftClick = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setDate(newDate);
  };

  const handleArrowRightClick = () => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setDate(newDate);
  };

  const [key, setKey] = useState(0);
  // We need to re-initialize the Calendar because it loses reference when we close the modal. This ensures we are able to continue navigating next and before months.
  useEffect(() => {
    // Effect to update key whenever selectedDate changes
    setKey((prevKey) => prevKey + 1);
  }, [selectedDate]);

  return (
    <RightSideContainer className="calendar-temp">
      <CalendarContainer key={key}>
        <CardHeader>
          <TextContainer onClick={() => navigate('/calendar')}>
            <Heading level={3} color={colors.darkPrimaryBlue}>
              {monthAndYearToDisplay}
            </Heading>
          </TextContainer>
          <div style={{ gap: '30px', display: 'flex' }}>
            <Hoverable onClick={() => handleArrowLeftClick()}>
              <ArrowLeft />
            </Hoverable>
            <Hoverable onClick={() => handleArrowRightClick()}>
              <ArrowRight />
            </Hoverable>
          </div>
        </CardHeader>
        <ReactCalendar
          tileContent={renderTileContent}
          formatShortWeekday={(_locale, date) =>
            [
              `${translate?.('calendarShortWeekDaySunday')}`,
              `${translate?.('calendarShortWeekDayMonday')}`,
              `${translate?.('calendarShortWeekDayTuesday')}`,
              `${translate?.('calendarShortWeekDayWednesday')}`,
              `${translate?.('calendarShortWeekDayThursday')}`,
              `${translate?.('calendarShortWeekDayFriday')}`,
              `${translate?.('calendarShortWeekDaySaturday')}`,
            ][date.getDay()]
          }
          value={date}
          onActiveStartDateChange={handleActiveDateChange}
          onChange={(value: SetStateAction<Date>) => {
            setDate(value);
          }}
          onClickDay={handleClickDay}
        />
        {modal.visible && (
          <Modal>
            <ModalHeader>
              <h4>{dayjs(date).format('DD')}</h4>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalContentContainer>
              {modal.content.map((event, index) => (
                <ModalContentContainerInner display={'flex'} key={index}>
                  <PriorityDot priority={event.priority} />
                  <ModalText>{event.title}</ModalText>
                  <br />
                </ModalContentContainerInner>
              ))}
              {!showAll && modal.remainingCount > 0 && (
                <>
                  <SeeAllText onClick={handleSeeAllClick}>
                    {modal.remainingCount} more
                  </SeeAllText>
                </>
              )}
              {showAll ? (
                <SeeAllText onClick={() => navigate('/task-manager')}>
                  See all
                </SeeAllText>
              ) : null}
              {modal.remainingCount === 0 && (
                <>
                  <SeeAllText onClick={() => navigate('/task-manager')}>
                    See all
                  </SeeAllText>
                </>
              )}
            </ModalContentContainer>
          </Modal>
        )}
      </CalendarContainer>
    </RightSideContainer>
  );
};
