import React, { useEffect, useState } from 'react';
import { Container } from 'pages/dashboard/styled';
import { Headline } from 'components';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  ButtonWrapper,
  CenterWrapper,
  CheckboxContainer,
  GreenButton,
  InputRow,
  PrevButton,
  StyledCheckboxContainer,
  StyledSpan,
  SubTitle,
  TellUsContainer,
  TellUsMoreContainer,
} from 'pages/request-account/components/styled';
import { RequestInformationType } from 'pages/request-account';
import RadioButton from 'pages/request-account/components/tell-us-more/radio-button';

export interface ClientAccountInfoProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
  prevStep: () => void;
}

export function TellUsMore(props: ClientAccountInfoProps): JSX.Element {
  const { requestedInformation, setRequestedInformation, nextStep, prevStep } =
    props;
  const [error, setError] = useState(false);
  const translation = useTranslation();
  const { t: translate } = translation;

  const organizationTypeArr = [
    translate?.('tellUsMoreOrgTypeArrItem1'),
    translate?.('tellUsMoreOrgTypeArrItem2'),
    translate?.('tellUsMoreOrgTypeArrItem3'),
    translate?.('tellUsMoreOrgTypeArrItem4'),
    translate?.('tellUsMoreOrgTypeArrItem5'),
    translate?.('tellUsMoreOrgTypeArrItem6'),
    translate?.('tellUsMoreOrgTypeArrItem7'),
  ];

  const organizationSizeArr = [
    translate?.('tellUsMoreOrgSizeArrItem1'),
    translate?.('tellUsMoreOrgSizeArrItem2'),
    translate?.('tellUsMoreOrgSizeArrItem3'),
    translate?.('tellUsMoreOrgSizeArrItem4'),
  ];

  const [, setRadio] = useState(new Map<string, { val: string; id: string }>());

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRadio((radio) =>
      new Map(radio).set(e.target.name, {
        val: e.target.value,
        id: e.target.id,
      }),
    );
    if (e.target.name === 'organizationType')
      setRequestedInformation({
        ...requestedInformation,
        organizationType: organizationTypeArr[Number(e.target.value)],
      });
    else {
      setRequestedInformation({
        ...requestedInformation,
        size: organizationSizeArr[Number(e.target.value)],
      });
    }
  }

  const checkedHandler = (checkboxIndex: number, questionnaire: string) => {
    if (questionnaire === 'organizationType') {
      const testIndex = organizationTypeArr.indexOf(
        requestedInformation.organizationType,
      );
      return testIndex === checkboxIndex;
    } else {
      const testIndex = organizationSizeArr.indexOf(requestedInformation.size);
      return testIndex === checkboxIndex;
    }
  };

  const handleNext = async () => {
    if (
      requestedInformation.size !== '' &&
      requestedInformation.organizationType !== ''
    ) {
      nextStep();
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setError(false);
  }, [requestedInformation]);

  return (
    <CenterWrapper>
      <Container width="100%" margin="62px 0 0 0">
        <Headline fontSize={34} fontSizeSmall={24} color="secondaryBlue">
          {translate?.('tellUsMoreTitle')}
        </Headline>
      </Container>
      <TellUsContainer margin={'0'} flexDirection={'column'}>
        <TellUsMoreContainer>
          <InputRow>
            <CheckboxContainer>
              <SubTitle>{translate?.('tellUsMoreSubTitle1')}</SubTitle>
              {organizationTypeArr.map((item, index) => (
                <StyledCheckboxContainer key={`${index}+a`}>
                  <RadioButton
                    id={`${index}+typeId`}
                    key={`${index}+b`}
                    label={item}
                    name="organizationType"
                    value={index}
                    onChange={handleChange}
                    checked={checkedHandler(index, 'organizationType')}
                  />
                </StyledCheckboxContainer>
              ))}
            </CheckboxContainer>
          </InputRow>
          <InputRow>
            <CheckboxContainer>
              <SubTitle>{translate?.('tellUsMoreSubTitle2')}</SubTitle>
              {organizationSizeArr.map((item, index) => (
                <StyledCheckboxContainer key={`${index}+a`}>
                  <RadioButton
                    id={`${index}+sizeId`}
                    key={`${index}+b`}
                    label={item}
                    name="organizationSize"
                    value={index}
                    onChange={handleChange}
                    checked={checkedHandler(index, 'size')}
                  />
                </StyledCheckboxContainer>
              ))}
            </CheckboxContainer>
          </InputRow>
        </TellUsMoreContainer>
        <ButtonContainer>
          <>{error && <StyledSpan>Selection is required.</StyledSpan>}</>
          <ButtonWrapper>
            <PrevButton onClick={() => prevStep()}>
              {translate?.('backButton')}
            </PrevButton>
            <GreenButton onClick={() => handleNext()}>
              {translate?.('nextButton')}
            </GreenButton>
          </ButtonWrapper>
        </ButtonContainer>
      </TellUsContainer>
    </CenterWrapper>
  );
}
