import { ArrowDown } from 'icons/arrow-down';
import { useState } from 'react';
import { useLanguageContext } from 'src/locales/context/LanguageContext';
import styled from 'styled-components';
import { colors, fontSize, fontWeight, spacingWithPx } from 'utils/styles';
import { useNavAndSideBarContext } from 'components';

const LanguageSelectorContainer = styled.div<{ isOpen: boolean }>`
  position: relative;
  svg {
    transition: transform 150ms ease-in-out;
    ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg);' : '')}
  }
`;

const Selector = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Text = styled.span`
  font-family: Open Sans;
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.bold};
  line-height: 30px;
  letter-spacing: 0.15px;
  color: ${colors.white};
  width: 25px;
`;

const LanguageDropdown = styled.div`
  position: absolute;
  z-index: 10;
  width: 62px;
  border-radius: 4px;
  background-color: ${colors.bluePrimary};
  border: 2px solid ${colors.secondaryBlue};
`;

const LanguageList = styled.ul`
  list-style: none;
  padding: ${spacingWithPx.xs} ${spacingWithPx.xs};
  margin: 0;
`;

const LanguageSelection = styled.li`
  cursor: pointer;
`;

export const LanguageSelector = () => {
  const { selected, language, changeLanguage } = useLanguageContext();
  const [isOpen, setIsOpen] = useState(false);
  const { setShowMenuBar } = useNavAndSideBarContext();

  return (
    <LanguageSelectorContainer isOpen={isOpen}>
      <Selector
        onClick={() => {
          setIsOpen(!isOpen);
          setShowMenuBar(false);
        }}
      >
        <Text>{language}</Text>
        <ArrowDown />
      </Selector>
      {isOpen ? (
        <LanguageDropdown>
          <LanguageList>
            {Object.keys(selected).map((selection) => (
              <LanguageSelection
                key={selection}
                onClick={() => {
                  changeLanguage?.(selection);
                  setIsOpen(false);
                }}
              >
                <Text>{selection.toUpperCase()}</Text>
              </LanguageSelection>
            ))}
          </LanguageList>
        </LanguageDropdown>
      ) : null}
    </LanguageSelectorContainer>
  );
};
