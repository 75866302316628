import React from 'react';
import { IconButton } from '@mui/material';
import { Calendar } from 'icons';
import { useNavigate } from 'react-router-dom';

interface CalendarContainerProps {
  onClick?: () => void;
}

export function CalendarContainer({ onClick }: CalendarContainerProps) {
  const navigate = useNavigate();
  return (
    <IconButton
      aria-label="calendar icon"
      onClick={() => navigate('/calendar')}
    >
      <Calendar />
    </IconButton>
  );
}
