import React from 'react';
import { Container } from 'pages/dashboard/styled';
import { Heading } from 'components';
import { useTranslation } from 'react-i18next';
import { colors } from 'utils/colors';
import LoadingComponent from 'components/loading-icon-component';
import NotificationCard from 'pages/notifications/components/notifications-card';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { useNotificationDataContext } from '../../react-query-toolkit/state/notifications-context';
import { Box } from '@mui/material';

export const Notifications: React.FC = () => {
  const { t: translate } = useTranslation();
  const {
    handleGetNotifications,
    notificationsFetchingError,
    notificationsListIsLoading,
    deleteNotificationLoading,
    deleteNotificationError,
  } = useNotificationDataContext();

  const notifications = handleGetNotifications() || [];

  return (
    <PageWrapper>
      <Container width="100%" margin="20px 0 0 0">
        <Container width="100%" margin="0">
          <Heading padding={'0 0 0 2px'} level={4} color={colors.secondaryBlue}>
            {translate?.('headerNotifications')}
          </Heading>
        </Container>
        <Container
          justifyContent="center"
          width="100%"
          flexDirection="column"
          margin="20px 0 0 0"
        >
          {notificationsListIsLoading ? (
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box width={'100px'}>
                <LoadingComponent size="50px" />
              </Box>
            </Box>
          ) : notificationsFetchingError ? (
            <Box>Error loading notifications</Box>
          ) : (
            notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
              />
            ))
          )}
          {deleteNotificationLoading && <Box>Deleting notification...</Box>}
          {deleteNotificationError && <Box>Error deleting notification</Box>}
        </Container>
      </Container>
    </PageWrapper>
  );
};
