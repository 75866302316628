import { colors } from 'utils/colors';

export const DEFAULT_CARD_DATE_COLORS = {
  color: colors.darkGrey,
  backgroundColor: `${colors.lighterSecondaryBlue}99`, // Adding opacity
};

export const getDayYyyyMmDd = (date?: string | number | Date) => {
  const currentDate = date ? new Date(date) : new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to account for zero-based months and padding with '0'
  const day = currentDate.getDate().toString().padStart(2, '0'); // Padding with '0'

  return `${year}-${month}-${day}`;
};

export const todayData = {
  color: '#5C6433',
  backgroundColor: colors.bluePrimary,
  text: 'Today',
};

export const tomorrowData = {
  color: '#5C6433',
  backgroundColor: colors.bluePrimary,
  text: 'Tomorrow',
};

export const notDue = {
  ...DEFAULT_CARD_DATE_COLORS,
  text: 'About 1 week(s) left',
};

export const overdue = {
  color: colors.white,
  backgroundColor: `${colors.red}99`, // Adding opacity
  text: 'Overdue',
};

export const getCardDateData = (dueDate: string) => {
  const YYYY_MM_DD = /^\d{4}-\d{2}-\d{2}$/;

  if (!YYYY_MM_DD.test(dueDate)) {
    console.error('Bad date format');
    return;
  }
  const split = dueDate.split('-');
  const dueDateYear = Number(split[0]);
  const dueDateMonth = Number(split[1]) - 1;
  const dueDateDay = Number(split[2]);
  const dueDateHour = 23; // 23 represents 11 PM
  const dueDateMinOrSecond = 59;

  const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
  const dueDateFull = new Date(
    dueDateYear,
    dueDateMonth,
    dueDateDay,
    dueDateHour,
    dueDateMinOrSecond,
    dueDateMinOrSecond,
  );
  const today = getDayYyyyMmDd();
  const currentDateFull = new Date();
  const timeDiff = dueDateFull.valueOf() - currentDateFull.valueOf();

  if (today === dueDate) {
    return todayData;
  }

  if (timeDiff >= ONE_DAY_IN_MS && timeDiff <= ONE_DAY_IN_MS * 2) {
    return tomorrowData;
  }

  if (timeDiff > ONE_DAY_IN_MS) {
    return notDue;
  }

  if (timeDiff < -ONE_DAY_IN_MS) {
    return overdue;
  }

  return notDue;
};
