export function getCountry() {
  return [
    { value: 'Canada', label: 'Canada' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Colombia', label: 'Colombia' },
  ];
}

export function getCity() {
  //Here we will fetch the city According to country Selection
  return [
    { value: 'Vancouver', label: 'Vancouver' },
    { value: 'Burnaby', label: 'Burnaby' },
    { value: 'Surrey', label: 'Surrey' },
    { value: 'Quebec', label: 'Quebec' },
  ];
}

export function getState() {
  //Here we will fetch the city According to country Selection
  return [
    { value: 'Ontario', label: 'Ontario' },
    { value: 'British Columbia', label: 'British Columbia' },
    { value: 'new brunswick', label: 'new brunswick' },
    { value: 'Quebec', label: 'Quebec' },
  ];
}

export function getRegion() {
  //Here we will fetch the city According to country Selection
  return [{ value: 'MetroVancouver', label: 'MetroVancouver' }];
}
