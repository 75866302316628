import { colors } from 'utils/colors';
export const GHGLogo = () => (
  <svg
    width="41"
    height="34"
    viewBox="0 0 41 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1343 31.2725V28.0955H19.0695V31.2725L13.8406 33.9533C13.8406 33.9533 0.153664 32.3102 0 18.7582C0 -2.56331 14.9163 0.961818 14.9163 0.961818V5.88881C1.48903 5.88881 5.1272 27.1056 12.3018 27.1056C15.1349 27.1056 14.9163 21.2205 14.9163 21.2205H19.0674V24.3974H21.1321V21.2228H25.2832C25.2832 21.2228 25.0646 27.1079 27.8977 27.1079C35.0723 27.1079 38.7105 5.89108 25.2832 5.89108V0.964094C25.2832 0.964094 40.1995 -2.56104 40.1995 18.7604C40.0458 32.3125 26.3589 33.9556 26.3589 33.9556L21.1343 31.2725Z"
      fill={colors.white}
    />
  </svg>
);
