import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const ProjectsCardsGrid = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (min-width: ${breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    max-width: 622px;
  }
  @media only screen and (min-width: ${breakpoints.lg}) {
    max-width: 1046px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;
