import { colors } from 'utils/colors';

export const PageArrowBack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <rect width="16" height="16" transform="translate(0 0.5)" fill="white" />
    <path d="M10 12.5L5 8.5L10 4.5V12.5Z" fill={colors.spanText} />
  </svg>
);
