import { ReactChild } from 'react';
import styled from 'styled-components';
import { defaultFontFamily, fontSize as fontSizes, colors } from 'utils/styles';

import { TextFontSize } from './types';

const P = styled.p<{
  fontFamily?: string;
  fontSize?: TextFontSize;
  color?: keyof typeof colors;
}>`
  font-family: ${({ fontFamily }) => fontFamily ?? defaultFontFamily};
  font-size: ${({ fontSize }) => fontSize ?? fontSizes.text};
  color: ${({ color }) => color ?? colors.darkGrey};
  margin: 0;
`;

type Props = {
  color?: keyof typeof colors;
  children: string | ReactChild;
};

export const Paragraph = ({ color, children }: Props) => {
  return <P color={color}>{children}</P>;
};
