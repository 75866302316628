import React from 'react';
import { PlusIcon } from 'icons/plus-icon';
import {
  AddButtonContainer,
  ButtonText,
} from 'pages/task-manager/components/styled';
import { useTranslation } from 'react-i18next';

function AddTask({
  onClickAction,
  hideButtonText,
}: {
  onClickAction?: React.MouseEventHandler;
  hideButtonText?: boolean;
}) {
  const { t: translate } = useTranslation();
  return (
    <AddButtonContainer onClick={onClickAction}>
      <PlusIcon />
      {hideButtonText ? null : (
        <ButtonText>{translate?.('addTaskTitle')}</ButtonText>
      )}
    </AddButtonContainer>
  );
}

export default AddTask;
