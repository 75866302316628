import { GenericContainer, Heading } from './atoms';

type PageHeading = {
  headline: string;
};

export const PageHeading = ({ headline }: PageHeading) => {
  return (
    <GenericContainer escapeHatch="gap: 44px; justify-content: flex-start;">
      <Heading color="secondaryBlue" level={4}>
        {headline}
      </Heading>
      <div>SEARCH BAR PLACEHOLDER</div>
    </GenericContainer>
  );
};
