import { Heading, Span } from 'components';
import { CalendarIcon } from './calendar-icon';
import { getCardDateData } from './get-card-date-data';
import { PlaceholderImage } from './placeholder-image';
import { Card, CardContainer, CardDateLabel, Row } from './styled';
import { generatePath, useNavigate } from 'react-router-dom';

type Props = {
  projectId: string;
  projectImageUrl?: string;
  projectName: string;
  clientName: string;
  consultant: string;
  step: string;
  dueDate: string;
};

export const ProjectCard = ({
  projectId,
  projectImageUrl,
  projectName,
  clientName,
  consultant,
  step,
  dueDate,
}: Props) => {
  const cardLabelColor = getCardDateData(dueDate);

  if (!cardLabelColor) {
    return null;
  }

  const navigate = useNavigate();
  const handleProceed = (projectId: string) => {
    projectId &&
      navigate(
        generatePath('/my-projects/project-id/:projectId', { projectId }),
      );
  };

  return (
    <CardContainer onClick={() => handleProceed(projectId)}>
      <Card>
        {projectImageUrl ? (
          <img src={projectImageUrl} alt={`Project ${projectName}`} />
        ) : (
          <PlaceholderImage />
        )}
        <div style={{ width: '100%' }}>
          <div style={{ margin: '4px 0 18px 0' }}>
            <Heading level={3} fontWeight={600} color="secondaryBlue">
              {projectName.toUpperCase()}
            </Heading>
            <Span fontSize="12px" fontWeight="600" color="lightGrey">
              {clientName}
            </Span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              marginBottom: '18px',
            }}
          >
            <Row>
              <Span fontSize="14px" fontWeight="600" color="secondaryBlue">
                Consultant:
              </Span>
              {/* Profile Image from clients page replaces SA */}
              <div>
                <span style={{ marginRight: '4px' }}>SA</span>
                <Span fontSize="14px" color="darkGrey">
                  {consultant.split(' ')[0]}
                </Span>
              </div>
            </Row>
            <Row>
              <Span fontSize="14px" fontWeight="600" color="secondaryBlue">
                Step:
              </Span>
              <Span fontSize="14px" color="darkGrey">
                {step}
              </Span>
            </Row>
            <Row>
              <Span fontSize="14px" fontWeight="600" color="secondaryBlue">
                Due Date:
              </Span>
              <Span color="darkGrey">{dueDate}</Span>
            </Row>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            <CardDateLabel color={cardLabelColor.backgroundColor}>
              <CalendarIcon color={cardLabelColor.color} />
              <Span fontSize="12px" color="darkGrey" fontWeight="400">
                {cardLabelColor.text}
              </Span>
            </CardDateLabel>
          </div>
        </div>
      </Card>
    </CardContainer>
  );
};
