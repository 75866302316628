import { colors } from 'utils/colors';

export const ArrowRightWithBorder = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill={color ?? colors.tableGray}
  >
    <path
      d="M6.46967 10.9365C6.17678 11.2294 6.17678 11.7042 6.46967 11.9971C6.76256 12.29 7.23744 12.29 7.53033 11.9971L10.0303 9.49713C10.3232 9.20423 10.3232 8.72936 10.0303 8.43647L7.53033 5.93647C7.23744 5.64357 6.76256 5.64357 6.46967 5.93647C6.17678 6.22936 6.17678 6.70423 6.46967 6.99713L8.43934 8.9668L6.46967 10.9365Z"
      fill={color ?? colors.tableGray}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.9668C11.866 1.9668 15 5.1008 15 8.9668C15 12.8328 11.866 15.9668 8 15.9668C4.13401 15.9668 1 12.8328 1 8.9668C1 5.1008 4.13401 1.9668 8 1.9668ZM8 3.4668C11.0376 3.4668 13.5 5.92923 13.5 8.9668C13.5 12.0044 11.0376 14.4668 8 14.4668C4.96243 14.4668 2.5 12.0044 2.5 8.9668C2.5 5.92923 4.96243 3.4668 8 3.4668Z"
      fill={color ?? colors.tableGray}
    />
  </svg>
);
