import { UtcTimeZonesOptions } from '../types';
//todo-cg: add an alert for when timezone isnt supported by browser.

export const TIMEZONES: UtcTimeZonesOptions[] = [
  {
    value: ['UTC'],
    standardOffset: 'UTC±00:00',
    dstOffset: 'UTC±00:00',
    iana: 'UTC',
  },
  {
    value: ['EST', 'EDT'],
    standardOffset: 'UTC-05:00',
    dstOffset: 'UTC-04:00',
    iana: 'America/New_York',
  },
  {
    value: ['CST', 'CDT'],
    standardOffset: 'UTC-06:00',
    dstOffset: 'UTC-05:00',
    iana: 'America/Chicago',
  },
  {
    value: ['MST', 'MDT'],
    standardOffset: 'UTC-07:00',
    dstOffset: 'UTC-06:00',
    iana: 'America/Denver',
  },
  {
    value: ['PST', 'PDT'],
    standardOffset: 'UTC-08:00',
    dstOffset: 'UTC-07:00',
    iana: 'America/Los_Angeles',
  },
  {
    value: ['GMT', 'BST'],
    standardOffset: 'UTC±00:00',
    dstOffset: 'UTC+01:00',
    iana: 'Europe/London',
  },
  {
    value: ['CET', 'CEST'],
    standardOffset: 'UTC+01:00',
    dstOffset: 'UTC+02:00',
    iana: 'Europe/Berlin',
  },
  {
    value: ['EET', 'EEST'],
    standardOffset: 'UTC+02:00',
    dstOffset: 'UTC+03:00',
    iana: 'Europe/Athens',
  },
  {
    value: ['MSK'],
    standardOffset: 'UTC+03:00',
    dstOffset: 'UTC+03:00',
    iana: 'Europe/Moscow',
  },
  {
    value: ['SGT'],
    standardOffset: 'UTC+08:00',
    dstOffset: 'UTC+08:00',
    iana: 'Asia/Singapore',
  },
  {
    value: ['JST'],
    standardOffset: 'UTC+09:00',
    dstOffset: 'UTC+09:00',
    iana: 'Asia/Tokyo',
  },
  {
    value: ['AEST', 'AEDT'],
    standardOffset: 'UTC+10:00',
    dstOffset: 'UTC+11:00',
    iana: 'Australia/Sydney',
  },
  {
    value: ['NCT'],
    standardOffset: 'UTC+11:00',
    dstOffset: 'UTC+11:00',
    iana: 'Pacific/Noumea',
  },
  {
    value: ['FJT', 'FJST'],
    standardOffset: 'UTC+12:00',
    dstOffset: 'UTC+13:00',
    iana: 'Pacific/Fiji',
  },
];
