import { GenericContainer, PageHeading } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProjectCard } from './project-card';
import { ProjectFilter } from './projects-filter';
import { ProjectsPageFilter } from './types';

import { breakpoints } from 'utils/breakpoints';
import { ProjectsCardsGrid } from './styled';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { Item, Project } from '../../react-query-toolkit/state/types';
import LoadingComponent from 'components/loading-icon-component';
import { Box } from '@mui/material';

export const MOCK_FILTER_COUNT = {
  ALL: 30,
  STARTED: 6,
  REVIEW: 5,
  COMPLETED: 3,
};

const PageHeadingContainer = styled(GenericContainer)`
  flex-direction: column;
  #projects-page-filter {
    display: none;
  }
  @media only screen and (min-width: ${breakpoints.md}) {
    gap: 26px;
    align-items: flex-start;
    #projects-page-filter {
      display: flex;
    }
  }
  @media only screen and (min-width: ${breakpoints.lg}) {
    gap: 0;
    flex-direction: row;
  }
`;

export const Projects = () => {
  const [filter, setFilter] = useState<ProjectsPageFilter>('ALL');
  const handleFilterClick = (filterName: ProjectsPageFilter) => {
    setFilter(filterName);
  };

  const { t: translate } = useTranslation();
  const { handleGetUser, isGetUserFetching, isGetUserLoading } =
    useUserDataContext();

  // Adjust rawUserData to be Item[] | null
  const rawUserData: Item[] | null = handleGetUser() || null;

  const userProjects: Project[] =
    rawUserData && Array.isArray(rawUserData)
      ? findItemsByTypename(rawUserData, 'ref:project')
      : [];

  return (
    <>
      <PageWrapper>
        <PageHeadingContainer
          id="projects-page-heading"
          escapeHatch="gap: 96px;"
        >
          <PageHeading headline={translate?.('headerProjects')} />
          {/*<ProjectFilter*/}
          {/*  active={filter}*/}
          {/*  filterCount={userProjects.length}*/}
          {/*  onClick={handleFilterClick}*/}
          {/*/>*/}
          {/*todo - Add this or delete according to the new ui*/}
        </PageHeadingContainer>
        {isGetUserLoading ? (
          <Box
            width={'100%'}
            height={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box width={'200px'} height={'200px'}>
              <LoadingComponent size="20px" />
            </Box>
          </Box>
        ) : (
          <ProjectsCardsGrid>
            {userProjects.map((project) => (
              <ProjectCard
                key={`${project.typename}-${project.id}`}
                projectId={project.id}
                projectName={project.projecttype}
                clientName={project.client}
                consultant={project.accountmanager}
                dueDate={project.duedate}
                step={project.step}
              />
            ))}
          </ProjectsCardsGrid>
        )}
      </PageWrapper>
    </>
  );
};
