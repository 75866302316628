export const CommentsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30341 9.6836C1.30341 5.41461 4.75101 1.95703 8.99999 1.95703C13.249 1.95703 16.6966 5.41461 16.6966 9.6836C16.6966 13.9526 13.249 17.4102 8.99999 17.4102C7.82557 17.4102 6.71385 17.1465 5.7192 16.6752C5.60725 16.6221 5.47959 16.6126 5.36096 16.6483L2.19238 17.6023L3.60849 15.8257C3.76714 15.6267 3.75095 15.3402 3.57089 15.1603C2.16997 13.7607 1.30341 11.8244 1.30341 9.6836ZM8.99999 0.957031C4.19528 0.957031 0.303406 4.86577 0.303406 9.6836C0.303406 11.9383 1.15617 13.9943 2.55513 15.5429L0.412417 18.2311C0.276082 18.4021 0.266748 18.6419 0.389374 18.823C0.512001 19.0042 0.738122 19.0845 0.947563 19.0215L5.46811 17.6604C6.54791 18.1424 7.7434 18.4102 8.99999 18.4102C13.8047 18.4102 17.6966 14.5014 17.6966 9.6836C17.6966 4.86577 13.8047 0.957031 8.99999 0.957031ZM5.84808 7.28476C5.57194 7.28476 5.34808 7.50862 5.34808 7.78476C5.34808 8.0609 5.57194 8.28476 5.84808 8.28476H12.1531C12.4293 8.28476 12.6531 8.0609 12.6531 7.78476C12.6531 7.50862 12.4293 7.28476 12.1531 7.28476H5.84808ZM5.84808 9.81629C5.57194 9.81629 5.34808 10.0401 5.34808 10.3163C5.34808 10.5924 5.57194 10.8163 5.84808 10.8163H12.1531C12.4293 10.8163 12.6531 10.5924 12.6531 10.3163C12.6531 10.0401 12.4293 9.81629 12.1531 9.81629H5.84808ZM5.34808 12.8469C5.34808 12.5708 5.57194 12.3469 5.84808 12.3469H12.1531C12.4293 12.3469 12.6531 12.5708 12.6531 12.8469C12.6531 13.1231 12.4293 13.3469 12.1531 13.3469H5.84808C5.57194 13.3469 5.34808 13.1231 5.34808 12.8469Z"
      fill="#53708A"
      fillOpacity="0.5"
    />
  </svg>
);
