import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Badge,
  Tooltip,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Notification } from '../../../react-query-toolkit/state/types';
import { InformationIcon } from 'icons/information-icon';
import { AlertIcon } from 'icons/alert-icon';
import { EmergencyIcon } from 'icons/emergency-icon';
import { CalendarIcon } from 'pages/projects/project-card/calendar-icon';
import { useNotificationDataContext } from '../../../react-query-toolkit/state/notifications-context';
import LoadingComponent from 'components/loading-icon-component';

const notificationTitles: Record<string, string> = {
  note_duedateChangePS: 'Project Step Due Date Changed',
  note_duedateChangeProject: 'Project Due Date Changed',
  note_newtask: 'Task Created',
  note_statusChangeProjectstep: 'Project Step Status Changed',
};

interface NotificationCardProps {
  notification: Notification;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
}) => {
  const {
    deleteNotificationLoading,
    deleteNotificationError,
    deletingNotificationId,
    handleDeleteNotification,
  } = useNotificationDataContext();

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [deletingNotificationClicked, setDeletingNotificationClicked] =
    useState(false);
  const handleCloseDialog = () => setOpenErrorDialog(false);

  // Open error dialog when deleteNotificationError occurs for this notification
  useEffect(() => {
    if (deleteNotificationError && deletingNotificationId === notification.id) {
      setOpenErrorDialog(true);
    }
  }, [deleteNotificationError, deletingNotificationId, notification.id]);

  const handleRemoveNotification = () => {
    setDeletingNotificationClicked(true);
    handleDeleteNotification(notification.id);
  };

  // Determine the due date status and corresponding icon and label
  function getDueDateStatus(
    notificationDueDate: string,
  ): 'emergency' | 'alert' | 'info' {
    const currentDate = dayjs().startOf('day');
    const dueDate = dayjs(notificationDueDate).startOf('day');

    if (dueDate.isBefore(currentDate)) {
      return 'emergency';
    } else if (dueDate.diff(currentDate, 'day') <= 1) {
      return 'alert';
    } else {
      return 'info';
    }
  }

  const dueDateStatus = getDueDateStatus(notification.duedate);

  const icon = {
    info: <InformationIcon />,
    alert: <AlertIcon />,
    emergency: <EmergencyIcon />,
  }[dueDateStatus];

  const dueDateLabel = {
    info: dayjs(notification.duedate).format('MMMM D'),
    alert: 'Tomorrow',
    emergency: 'Yesterday',
  }[dueDateStatus];

  const title = notificationTitles[notification.type] || 'Notification';

  const description = (() => {
    switch (notification.type) {
      case 'note_duedateChangePS':
        return `${notification.InterpolationValues.name} step's due date was changed`;
      case 'note_duedateChangeProject':
        return `Project due date was changed to ${dayjs(notification.duedate).format('MMMM D')}`;
      case 'note_newtask':
        return `A new task "${notification.InterpolationValues.name}" was created`;
      case 'note_statusChangeProjectstep':
        return `Status for "${notification.InterpolationValues.name}" step was changed to ${notification.InterpolationValues.status}`;
      default:
        return 'No additional details available.';
    }
  })();

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          padding: 2,
          marginY: 1,
          position: 'relative',
        }}
      >
        <Box sx={{ marginRight: 2, marginTop: 1, fontSize: 24 }}>
          <Tooltip
            title={
              dueDateStatus.charAt(0).toUpperCase() + dueDateStatus.slice(1)
            }
          >
            <Badge>{icon}</Badge>
          </Tooltip>
        </Box>
        <CardContent sx={{ flexGrow: 1, padding: 0 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box display="flex">
              <Typography variant="h6">{title}</Typography>
              <Box
                display="flex"
                sx={{
                  paddingX: 1,
                  borderRadius: 1,
                  bgcolor:
                    dueDateStatus === 'alert'
                      ? 'warning.light'
                      : dueDateStatus === 'emergency'
                        ? 'error.light'
                        : 'grey.200',
                }}
              >
                <CalendarIcon
                  color={dueDateStatus === 'emergency' ? 'error' : 'primary'}
                />
                <Typography
                  variant="body2"
                  color={
                    dueDateStatus === 'emergency'
                      ? 'error.main'
                      : 'text.secondary'
                  }
                >
                  {dueDateLabel}
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={handleRemoveNotification}
              sx={{
                color: 'grey.500',
                '&:hover': { color: 'grey.700' },
              }}
            >
              {deletingNotificationClicked &&
              deletingNotificationId === notification.id ? (
                <Box height="24px" width="24px">
                  <LoadingComponent />
                </Box>
              ) : (
                <Close />
              )}
            </IconButton>
          </Box>
          <Link
            href={notification.link}
            underline="hover"
            color="inherit"
            target="_blank"
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ marginTop: 1 }}
            >
              {description}
            </Typography>
          </Link>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ marginTop: 2 }}
          >
            {`Due Date: ${dayjs(notification.duedate).format('MMM D')} | Received: ${dayjs(
              notification.created_at,
            ).format('MMM D [at] h:mma')}`}
          </Typography>
        </CardContent>
      </Card>

      {/* Error Dialog */}
      <Dialog
        open={openErrorDialog}
        onClose={handleCloseDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent dividers>
          <Typography id="error-dialog-description">
            There was an error deleting the notification. Please try again.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationCard;
