import { FlagIcon } from 'icons/flag-icon';
import { colors } from 'utils/colors';
import {
  highTaskPriority,
  lowTaskPriority,
  mediumTaskPriority,
} from 'pages/task-manager/components/variables';

export function flagPriorityHandler(taskPriority: string) {
  return (
    <FlagIcon
      stroke={checkBoxColorPriorityHandler(taskPriority)}
      fill={checkBoxColorPriorityHandler(taskPriority)}
    />
  );
}

export function checkBoxColorPriorityHandler(taskPriority: string) {
  if (taskPriority === lowTaskPriority) {
    return colors.darkGrey;
  } else if (taskPriority === mediumTaskPriority) {
    return colors.flagBlue;
  } else if (taskPriority === highTaskPriority) {
    return colors.flagYellow;
  } else {
    return colors.flagRed;
  }
}
