import styled from 'styled-components';
import { colors } from 'utils/colors';
import { spacingWithPx } from 'utils/styles';

// TODO: Make this into resuable ui-component
export const Card = styled.div<{
  width?: string;
  height?: string;
  noPadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ noPadding }) =>
    noPadding ? '0' : `${spacingWithPx.sm} ${spacingWithPx.gap}`};

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  background-color: ${colors.white};
  /* Shadow Light */

  box-shadow: 1px 1px 1px rgba(181, 185, 191, 0.25);
  border-radius: 4px;
`;
