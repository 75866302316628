import styled from 'styled-components';
import { colors } from 'utils/styles';
import { breakpoints } from 'utils/breakpoints';

export const HeadlineContainer = styled.p<{
  color?: keyof typeof colors;
  fontWeight?: number;
  fontSize?: number;
  fontSizeSmall?: number;
}>`
  text-align: center;
  font-style: normal;
  line-height: normal;
  color: ${({ color }) => colors[color || 'grey']};
  letter-spacing: 0.085px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '2rem')};

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: ${({ fontSizeSmall }) =>
      fontSizeSmall ? `${fontSizeSmall}px` : '1rem'};
  }

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: ${({ fontSizeSmall }) =>
      fontSizeSmall ? `${fontSizeSmall}px` : '1rem'};
  }
`;
