import React from 'react';
import { Container } from 'pages/dashboard/styled';
import { Headline } from 'components';
import { useTranslation } from 'react-i18next';
import {
  AnswerContainer,
  ReviewButtonContainer,
  ButtonWrapper,
  GreenButton,
  InfoKeyContainer,
  InfoRowContainer,
  InfoWrapper,
  PrevButton,
  ReviewBoxContainer,
  ReviewInfoRowContainer,
  ReviewInputRow,
  RightAnswerContainer,
  RightReviewInputRow,
  TitleContainer,
  TitleText,
  ReviewContainer,
} from 'pages/request-account/components/styled';
import { RequestInformationType } from 'pages/request-account';

export interface ClientAccountInfoProps {
  requestedInformation: RequestInformationType;
  onSubmit: (e: React.SyntheticEvent) => Promise<void>;
  prevStep: () => void;
}

export function ReviewInfo(props: ClientAccountInfoProps): JSX.Element {
  const translation = useTranslation();
  const { requestedInformation, onSubmit, prevStep } = props;
  const { t: translate } = translation;

  const tellUsMoreArr = [
    {
      answerKey: translate?.('typeOfOrganizationQuestion'),
      answer: requestedInformation.organizationType,
    },
    {
      answerKey: translate?.('sizeOfOrganizationQuestion'),
      answer: requestedInformation.size,
    },
  ];

  const serviceArr = [
    {
      answerKey: translate?.('serviceInterestQuestion'),
      answer: requestedInformation.service,
    },
  ];

  const clientInfoArr = [
    {
      answerKey: translate?.('typeOfOrganizationQuestion'),
      answer: requestedInformation.clientName,
    },
    {
      answerKey: translate?.('requestAccountClient'),
      answer: requestedInformation.contactName,
    },
    {
      answerKey: translate?.('requestAccountPhone'),
      answer: requestedInformation.phone,
    },
    {
      answerKey: translate?.('requestAccountEmail'),
      answer: requestedInformation.email,
    },
    {
      answerKey: translate?.('requestAccountCountry'),
      answer: requestedInformation.country,
    },
    {
      answerKey: translate?.('requestAccountState'),
      answer: requestedInformation.state,
    },
    {
      answerKey: translate?.('requestAccountRegion'),
      answer: requestedInformation.region,
    },
    {
      answerKey: translate?.('requestAccountCity'),
      answer: requestedInformation.city,
    },
    {
      answerKey: translate?.('requestAccountAddress'),
      answer: requestedInformation.address,
    },
  ];

  return (
    <InfoWrapper>
      <form onSubmit={onSubmit}>
        <Container width="100%" margin="62px 0 0 0">
          <Headline fontSize={34} fontSizeSmall={24} color="secondaryBlue">
            {translate?.('reviewYourInfoTitle')}
          </Headline>
        </Container>
        <Container flexDirection={'column'}>
          <ReviewContainer>
            <ReviewInputRow>
              <ReviewBoxContainer>
                <TitleContainer>
                  <TitleText>
                    {translate?.('reviewYourInfoClientInfoTitle')}
                  </TitleText>
                </TitleContainer>

                {clientInfoArr.map((infoItem, index) => (
                  <InfoRowContainer key={`${index}` + `${infoItem.answerKey}`}>
                    <InfoKeyContainer>{infoItem.answerKey}</InfoKeyContainer>
                    <AnswerContainer>{infoItem.answer}</AnswerContainer>
                  </InfoRowContainer>
                ))}
              </ReviewBoxContainer>
            </ReviewInputRow>
            <RightReviewInputRow>
              <ReviewBoxContainer>
                <TitleContainer>
                  <TitleText>{translate?.('tellUsMoreTitle')}</TitleText>
                </TitleContainer>

                {tellUsMoreArr.map((infoItem, index) => (
                  <ReviewInfoRowContainer
                    key={`${index}` + `${infoItem.answerKey}`}
                  >
                    <InfoKeyContainer>{infoItem.answerKey}</InfoKeyContainer>
                    <RightAnswerContainer>
                      {infoItem.answer}
                    </RightAnswerContainer>
                  </ReviewInfoRowContainer>
                ))}
              </ReviewBoxContainer>

              <ReviewBoxContainer>
                <TitleContainer>
                  <TitleText>{translate?.('serviceProfileTitle')}</TitleText>
                </TitleContainer>

                {serviceArr.map((infoItem, index) => (
                  <ReviewInfoRowContainer
                    key={`${index}` + `${infoItem.answerKey}`}
                  >
                    <InfoKeyContainer>{infoItem.answerKey}</InfoKeyContainer>
                    <RightAnswerContainer>
                      {infoItem.answer}
                    </RightAnswerContainer>
                  </ReviewInfoRowContainer>
                ))}
              </ReviewBoxContainer>
            </RightReviewInputRow>
          </ReviewContainer>
          <ReviewButtonContainer>
            <ButtonWrapper>
              <PrevButton onClick={() => prevStep()}>
                {translate?.('backButton')}
              </PrevButton>
              <GreenButton type="submit">
                {translate?.('submitButton')}
              </GreenButton>
            </ButtonWrapper>
          </ReviewButtonContainer>
        </Container>
      </form>
    </InfoWrapper>
  );
}
