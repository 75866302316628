export function isFirstDatePastSecondDate(
  date1: string,
  date2: string,
): boolean {
  // Convert dates to milliseconds since Epoch
  const date1Milliseconds = new Date(date1).getTime();
  const date2Milliseconds = new Date(date2).getTime();

  // Compare milliseconds
  return date1Milliseconds > date2Milliseconds;
}
