import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppContainer,
  Button,
  Card,
  Heading,
  Span,
  TextField,
} from 'components';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { colors, spacingWithPx } from 'utils/styles';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useAuth from 'configs/AuthContext';
import { useState } from 'react';

const LoginContainer = styled(Card)`
  width: 402px;
  height: auto;
  padding: ${spacingWithPx.lg} ${spacingWithPx.lg};
  margin: 0 auto;
  @media only screen and (max-width: ${breakpoints.sm}) {
    background-color: inherit;
    outline: none;
    box-shadow: none;
  }
`;

const loginSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      RegExp('(.*[a-z].*)'),
      'Password must contain at least 1 lowercase character',
    )
    .matches(
      RegExp('(.*[A-Z].*)'),
      'Password must contain at least 1 uppercase character',
    )
    .matches(RegExp('(.*\\d.*)'), 'Password must contain at least 1 number')
    .matches(
      RegExp('[!@#$%^&*()_,.?":{}|<>]'),
      'Password must contain at least 1 Special character',
    ),
  newPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      RegExp('(.*[a-z].*)'),
      'Password must contain at least 1 lowercase character',
    )
    .matches(
      RegExp('(.*[A-Z].*)'),
      'Password must contain at least 1 uppercase character',
    )
    .matches(RegExp('(.*\\d.*)'), 'Password must contain at least 1 number')
    .matches(
      RegExp('[!@#$%^&*()_,.?":{}|<>]'),
      'Password must contain at least 1 Special character',
    )
    .notOneOf(
      [yup.ref('oldPassword')],
      'New password must be different from the old password',
    ),
  confirmedPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      RegExp('(.*[a-z].*)'),
      'Password must contain at least 1 lowercase character',
    )
    .matches(
      RegExp('(.*[A-Z].*)'),
      'Password must contain at least 1 uppercase character',
    )
    .matches(RegExp('(.*\\d.*)'), 'Password must contain at least 1 number')
    .matches(
      RegExp('[!@#$%^&*()_,.?":{}|<>]'),
      'Password must contain at least 1 Special character',
    )
    .oneOf([yup.ref('newPassword')], 'Passwords do not match')
    .notOneOf(
      [yup.ref('oldPassword')],
      'New password must be different from the old password',
    ),
});

export const ChangePassword = () => {
  const {
    control,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmedPassword: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const { completeNewPassword } = useAuth();
  const [error, setError] = useState<string>('');

  async function onSubmit(_data: FieldValues) {
    try {
      completeNewPassword(_data.newPassword, () => {
        navigate('/');
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  const checkFieldValidity = async (
    fieldName: 'oldPassword' | 'newPassword' | 'confirmedPassword',
  ) => {
    await trigger(fieldName);
  };

  return (
    <AppContainer id="ghg-app-container">
      <LoginContainer>
        <div style={{ marginBottom: '28px' }}>
          <Heading level={1} color="secondaryBlue">
            Password Reset
          </Heading>
        </div>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="oldPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                onBlur={() => {
                  checkFieldValidity('oldPassword');
                }}
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors();
                }}
                label="Old Password"
                placeholder="Old Password"
                type="password"
                status={
                  errors.oldPassword ? 'error' : isValid ? 'success' : undefined
                }
                errorMessage={errors.oldPassword && errors.oldPassword.message}
              />
            )}
          />
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                onBlur={() => {
                  checkFieldValidity('newPassword');
                }}
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors();
                }}
                label="New Password"
                placeholder="New Password"
                type="password"
                status={
                  errors.newPassword ? 'error' : isValid ? 'success' : undefined
                }
                errorMessage={errors.newPassword && errors.newPassword.message}
              />
            )}
          />
          <Controller
            name="confirmedPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                onBlur={() => {
                  checkFieldValidity('confirmedPassword');
                }}
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors();
                }}
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
                status={
                  errors.confirmedPassword
                    ? 'error'
                    : isValid
                      ? 'success'
                      : undefined
                }
                errorMessage={
                  errors.confirmedPassword && errors.confirmedPassword.message
                }
              />
            )}
          />
          <div
            style={{ width: '100%', marginTop: '12px', marginBottom: '24px' }}
          >
            <Button type="submit">RESET PASSWORD</Button>
          </div>
          <Span color="spanText">Don&apos;t have an account?</Span>
          <div
            style={{ width: '100%', marginTop: '12px', marginBottom: '32px' }}
          >
            <Button
              onClick={() => {
                alert('REQUEST Account Placeholder here...');
              }}
              colors={{
                backgroundColor: colors.secondaryBlue,
                color: colors.white,
              }}
            >
              REQUEST AN ACCOUNT
            </Button>
            {error != '' ? <p>{error} </p> : ''}
          </div>
        </form>
      </LoginContainer>
    </AppContainer>
  );
};
