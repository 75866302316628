import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';
import {
  PhotoActionButton,
  EditableProfilePicture,
} from './profile-picture-control';
import { defaultProfilePicture } from '../icons/svg-icons';
import { Stack, Typography } from '@mui/material';
import { User } from '../../../../react-query-toolkit/state/types';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import useAuth from '../../../../configs/AuthContext';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export interface MiscData {
  progressValue?: number;
  isInputDisabled?: boolean;
}

export interface StatusInfo {
  text: string;
  buttonClickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  inputChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isButtonDisabled?: boolean;
  isButtonHidden?: boolean;
  data?: MiscData;
}

export interface Preview {
  showPreview: boolean;
  previewUrl: string;
}

export interface Statuses {
  [x: string]: StatusInfo | StatusInfo[];
}

export interface ImageUploaderProps {
  isAlternate?: boolean;
  userData: User;
  openStateSetter: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  isAlternate,
  userData,
  openStateSetter,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const FOLDER_PATH = 'profile-images/';
  const { profilepicture } = userData;
  const [imageFileFullUrl, setImageFileFullUrl] = useState<string | null>(
    profilepicture ? `${FOLDER_PATH}${profilepicture}` : null,
  );
  const pictureRef = React.useRef<HTMLLabelElement>(null);
  const [preview, setPreview] = useState<Preview>({
    showPreview: false,
    previewUrl: '',
  });
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [isReplacing, setIsReplacing] = useState(false);
  const [uuidName, setUuidName] = useState<string>(profilepicture);

  useEffect(() => {
    if (!uuidName) {
      const generatedUuid = uuidv4();
      setUuidName(generatedUuid);
    }
  }, [uuidName]);

  useEffect(() => {
    profilepicture && setImageFileFullUrl(`${FOLDER_PATH}${profilepicture}`);
  }, [profilepicture]);

  const fullFilePath = `${FOLDER_PATH}${uuidName}`;

  const handleRetrieveImage = async (imageFileUrlSegment: string) => {
    if (!imageFileUrlSegment) {
      return;
    }
    try {
      const bucketName = 'profile-images-local';
      const url = await Storage.get(fullFilePath, {
        level: 'protected',
        bucket: bucketName,
        expires: 3600,
      });
      setImageUrl(url);
    } catch (error) {
      setError('Error retrieving image. Please try again.');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      const fileType = selectedFile?.type;

      // Validate file type
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        setError('Only JPEG, JPG, and PNG images are allowed.');
        alert('Only JPEG, JPG, and PNG images are allowed.');
        return;
      }

      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (selectedFile.size > maxSize) {
        setError('File size should not exceed 5MB.');
        alert(
          'The file you have selected exceeds the allowed limit of 5MB. Please select a smaller file.',
        );
        return;
      }

      setError(null);

      Resizer.imageFileResizer(
        selectedFile,
        800, // max width
        800, // max height
        'JPEG', // compress format (JPEG, PNG, WEBP)
        80, // quality (0-100)
        0, // rotation (degrees)
        (uri: Blob | string | File | ProgressEvent<FileReader>) => {
          if (uri instanceof Blob) {
            const resizedFile = new File([uri], selectedFile.name, {
              type: selectedFile.type,
            });
            setFile(resizedFile);
            setPreview({
              showPreview: true,
              previewUrl: URL.createObjectURL(resizedFile),
            });
            if (imageFileFullUrl) {
              setIsReplacing(true);
            }
          } else if (typeof uri === 'string') {
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const resizedFile = new File([blob], selectedFile.name, {
                  type: selectedFile.type,
                });
                setFile(resizedFile);
                setPreview({
                  showPreview: true,
                  previewUrl: URL.createObjectURL(resizedFile),
                });
              })
              .catch((err) => {
                setError('Error converting image string to blob.');
                alert(err);
              });
          } else {
            setError('Unexpected result from image resizing.');
          }
        },
        'blob', // output type
      );
    }
  };

  const auth = useAuth();
  const ghgciId = auth.user?.getSignInUserSession()!.getIdToken().payload[
    'custom:ghgci_id'
  ];

  const {
    handleUpdateUser,
    isUpdateUserSuccessful,
    resetUpdateUserQueryValues,
    userUpdateError,
  } = useUserDataContext();

  const [handleUpdateErrorMessage, setHandleUpdateErrorMessage] = useState<
    FetchBaseQueryError | SerializedError | undefined
  >();

  const handleUpload = async () => {
    if (!file || !uuidName) return;
    setUploading(true);

    try {
      openStateSetter(true);
      const fileName = `${uuidName}`;
      const fullFilePath = `${FOLDER_PATH}${fileName}`;

      await Storage.put(fullFilePath, file, {
        contentType: file.type,
        level: 'protected', // Set the access level to 'protected'
        bucket: 'profile-images-local',
        progressCallback(progress: ProgressEvent<XMLHttpRequestEventTarget>) {
          setUploadProgress(
            Math.round((progress.loaded / progress.total) * 100),
          );
        },
      });

      handleUpdateUser({
        ...userData,
        profilepicture: fileName,
        userId: ghgciId,
      });

      setError(null);
      alert('File uploaded successfully!');
      setImageFileFullUrl(fullFilePath);
      setPreview({
        ...preview,
      });

      setFile(null);
      setUploadProgress(0);
      setIsReplacing(false);
    } catch (error) {
      alert(`Error uploading file:${error}`);
      setError('Error uploading file. Please try again.');
    } finally {
      setHandleUpdateErrorMessage(userUpdateError);
      setUploading(false);
    }
  };

  useEffect(() => {
    if (isUpdateUserSuccessful) {
      setUploading(false);
      resetUpdateUserQueryValues();
      openStateSetter(false);
    }
  }, [isUpdateUserSuccessful]);

  useEffect(() => {
    if (profilepicture) {
      handleRetrieveImage(profilepicture);
    }
  }, [handleUpload, profilepicture]);

  const handleDelete = async () => {
    if (!imageFileFullUrl) {
      setError('No file uploaded to delete.');
      return;
    }

    try {
      const bucketName = 'profile-images-local';

      await Storage.remove(imageFileFullUrl, {
        level: 'protected',
        bucket: bucketName,
      });

      alert('File deleted successfully!');
      setImageFileFullUrl(null);
      handleUpdateUser({
        ...userData,
        profilepicture: '',
        userId: ghgciId,
      });
      setPreview({ showPreview: false, previewUrl: '' });
      setIsReplacing(false);
    } catch (error) {
      alert(`Error deleting file:${error}`);
      setError('Error deleting file. Please try again.');
    }
  };

  const handleReplace = () => {
    pictureRef.current?.click();
    setIsReplacing(true);
    setError(null);
  };

  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {}, [imageFileFullUrl]);

  const STATUS_KEYS = {
    FILE_VACANT: 'isFileVacant',
    REPLACING: 'isReplacing',
    UPLOADING: 'uploading',
    FILE_UPLOADED: 'fileUploaded',
  };

  const getStatusKey = () => {
    if (!imageFileFullUrl) return STATUS_KEYS.FILE_VACANT;
    if (isReplacing) return STATUS_KEYS.REPLACING;
    if (uploading) return STATUS_KEYS.UPLOADING;
    if (imageFileFullUrl && !isReplacing) return STATUS_KEYS.FILE_UPLOADED;
    return 'error';
  };

  const getStatusesData = (
    isButtonDisabled: boolean,
    isButtonHidden: boolean,
    uploadProgress: number,
  ): Statuses => ({
    [STATUS_KEYS.FILE_VACANT]: {
      text: 'Upload',
      buttonClickHandler: handleUpload,
      inputChangeHandler: handleFileChange,
      isButtonDisabled,
      isButtonHidden,
    },
    [STATUS_KEYS.REPLACING]: {
      text: 'Confirm ',
      buttonClickHandler: handleUpload,
      inputChangeHandler: handleFileChange,
    },
    [STATUS_KEYS.UPLOADING]: {
      text: `Uploading: ${uploadProgress}%`,
      inputChangeHandler: handleFileChange,
      isButtonDisabled,
      data: { progressValue: uploadProgress },
    },
    [STATUS_KEYS.FILE_UPLOADED]: {
      text: 'Delete',
      buttonClickHandler: handleDelete,
      inputChangeHandler: handleFileChange,
    },
  });

  const isButtonDisabled = uploading;
  const isButtonHidden = !file;

  const statusKey = getStatusKey();
  const statusesData = getStatusesData(
    isButtonDisabled,
    isButtonHidden,
    uploadProgress,
  );
  const statusInfo = statusesData[statusKey];
  const isStatusInfoAnArray = Array.isArray(statusInfo);

  const TextFeedback = ({
    caption,
    style,
  }: {
    caption: string;
    style?: object;
  }) => {
    return (
      <Typography variant="caption" sx={style}>
        {caption}
      </Typography>
    );
  };

  return (
    <Stack direction={'row'} spacing={4} alignItems={'center'}>
      <EditableProfilePicture
        inputChangeHandler={handleFileChange}
        preview={preview}
        defaultProfilePicture={defaultProfilePicture}
        uploadedFileName={imageFileFullUrl}
        ref={pictureRef}
        profilePictureUrl={imageUrl}
        userFirstName={userData.firstname}
      />

      {isAlternate ? (
        statusKey === 'error' ? (
          handleUpdateErrorMessage ? (
            <TextFeedback
              caption={handleUpdateErrorMessage as string}
              style={{ color: 'red' }}
            />
          ) : (
            <TextFeedback caption={error as string} style={{ color: 'red' }} />
          )
        ) : isStatusInfoAnArray ? (
          <Stack direction={'row'} spacing={7}>
            {statusInfo.map((_statusInfo, index) => (
              <PhotoActionButton key={index} {..._statusInfo} />
            ))}
          </Stack>
        ) : (
          <PhotoActionButton {...statusInfo} />
        )
      ) : (
        <div>
          <input
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleFileChange}
            disabled={!!imageFileFullUrl && !isReplacing} // Disable file input unless replacing
          />
          {!imageFileFullUrl && (
            <button onClick={handleUpload} disabled={uploading || !file}>
              Upload
            </button>
          )}

          {imageFileFullUrl && !isReplacing && (
            <>
              <button onClick={handleReplace}>Replace Image</button>
              <button onClick={handleDelete}>Delete</button>
            </>
          )}

          {isReplacing && (
            <button onClick={handleUpload} disabled={uploading || !file}>
              Confirm Replacement
            </button>
          )}

          {uploading && <p>Uploading: {uploadProgress}%</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      )}
    </Stack>
  );
};
