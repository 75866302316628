enum Format {
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'YYYY/MM/DD',
  'YYYY/DD/MM',
  'MonthYear',
  'MonthDayYear',
  'MMMDD,YYYY',
  'MMLongDD,YYYY',
  'YYYY-MMM-DD',
  'YYYY-MM-DD',
  'MM-DD-YYYY',
}

export const epochToDate = (
  epoch: number | Date | string,
  format?: keyof typeof Format,
  location?: string,
) => {
  const date = new Date(epoch);
  const dateString = date.toDateString();
  const [, month, year] = dateString.split(' ');
  const day = date.toLocaleString(location ? location : 'default', {
    day: '2-digit',
  });
  const monthLong = date.toLocaleString(location ? location : 'default', {
    month: 'long',
  });

  switch (format) {
    case 'MM/DD/YYYY':
      return date.toLocaleDateString();
    case 'DD/MM/YYYY':
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    case 'YYYY/DD/MM':
      return `${date.getFullYear()}/${date.getDate()}/${date.getMonth() + 1}`;
    case 'YYYY/MM/DD':
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    case 'MonthYear':
      return `${month} ${year}`;
    case 'MonthDayYear':
      return date.toDateString().slice(4);
    case 'MMMDD,YYYY':
      return month + ' ' + day + ', ' + date.getFullYear();
    case 'MMLongDD,YYYY':
      return monthLong + ' ' + day + ', ' + date.getFullYear();
    case 'YYYY-MMM-DD':
      return `${date.getFullYear()}-${month}-${day}`;
    case 'YYYY-MM-DD':
      return `${date.getFullYear()}-${date.getMonth() + 1}-${day}`;
    case 'MM-DD-YYYY':
      return `${date.getMonth() + 1}-${day}-${date.getFullYear()}`;
    default:
      return date.toLocaleString();
  }
};
