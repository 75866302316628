import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { FormControlFields } from 'pages/user-profile/components/types';
import { CustomButton } from 'components';
import { useTranslation } from 'react-i18next';
import { BasicJoinedUserSettingsType } from 'pages/user-profile/components/types';
import { FormValuesStateAndSetter } from '../../user-profile';
import { Control } from './controls';
import Grid from '@mui/material/Grid2';
import {
  ExtendedUserSettingsData,
  useUserSettingsDataContext,
} from '../../../../react-query-toolkit/state/user-settings-context';
import { ExtendedSlUserData } from '../../../../react-query-toolkit/state/user-context';
import { auth } from 'aws-crt';

export interface FormProps {
  area: string;
  formInputValuesStateAndSetter: FormValuesStateAndSetter;
  fetchedUserSettings: BasicJoinedUserSettingsType;
  formControlsInfo: FormControlFields[];
  handleSubmit:
    | ((
        userSettingsData: ExtendedUserSettingsData,
        slUserData?: ExtendedSlUserData,
      ) => Promise<void>)
    | (() => void);
}

export interface FormControlProps {
  formControlInfo: FormControlFields;
  formValuesStateAndSetter: FormValuesStateAndSetter;
  area: string;
}

export interface FormInputValues {
  [key: string]: { [key: string]: string };
}

//todo-cg: Make conditional renderings based on these values below
//const areas = ['profile', 'password', 'formats'];

const mapFormControls = (
  formsInfo: FormControlFields[],
  area: string,
  formValuesStateAndSetter: FormValuesStateAndSetter,
  fetchedUserSettings: BasicJoinedUserSettingsType,
) => {
  return formsInfo.map((formControlData: FormControlFields) => (
    <Control
      key={formControlData.name.toLowerCase()}
      formControlInfo={formControlData}
      formValuesStateAndSetter={formValuesStateAndSetter}
      area={area}
      fetchedUserSettings={fetchedUserSettings}
    />
  ));
};

export const Form = (props: FormProps) => {
  const {
    area,
    formControlsInfo,
    formInputValuesStateAndSetter,
    fetchedUserSettings,
    handleSubmit,
  } = props;
  const { formInputValues } = formInputValuesStateAndSetter;

  const {
    isCreateUserSettingsLoading,
    isUpdateUserSettingsLoading,
    isCreateUserSettingsSuccess,
    isUpdateUserSettingsSuccess,
    resetCreateUserSettingsQueryValues,
    resetUpdateTaskQueryValues,
    userSettingsCreationError,
    userSettingsUpdateError,
  } = useUserSettingsDataContext();

  const isSavingDisabled =
    isCreateUserSettingsLoading ||
    isUpdateUserSettingsLoading ||
    isCreateUserSettingsSuccess ||
    isUpdateUserSettingsSuccess;

  useEffect(() => {
    if (isCreateUserSettingsSuccess || isUpdateUserSettingsSuccess) {
      resetCreateUserSettingsQueryValues();
      resetUpdateTaskQueryValues();
    } else if (userSettingsCreationError || userSettingsUpdateError) {
      console.error(
        `Error: ${userSettingsCreationError ?? userSettingsUpdateError}`,
      );
    }
    resetCreateUserSettingsQueryValues();
    resetUpdateTaskQueryValues();
  }, [
    isCreateUserSettingsSuccess,
    isUpdateUserSettingsSuccess,
    userSettingsCreationError,
    userSettingsUpdateError,
  ]);

  const mandatoryData = formControlsInfo.filter(
    (control: FormControlFields) => control.isRequired,
  );

  const [isMandatoryPropertyMissing, setIsMandatoryPropertyMissing] =
    useState(false);

  const checkMandatoryProperties = (
    mandatories: FormControlFields[],
    formInputs: BasicJoinedUserSettingsType,
  ) => {
    for (let i = 0; i < mandatories.length; i++) {
      const controlName = mandatories[i]
        .name as keyof BasicJoinedUserSettingsType;

      if (!(formInputs[controlName].length > 0)) {
        setIsMandatoryPropertyMissing(true);
        return;
      }
    }
    setIsMandatoryPropertyMissing(false);
  };

  useEffect(() => {
    checkMandatoryProperties(mandatoryData, formInputValues);
  }, [formInputValues]);

  const handleClick = () => {
    const ghgciId = auth.user?.getSignInUserSession()!.getIdToken().payload[
      'custom:ghgci_id'
    ];

    const {
      phone,
      jobtitle,
      timezone,
      location,
      numberformat,
      timeformat,
      dateformat,
      language,
      secondaryemail,
    } = formInputValues;

    const { firstname, lastname, email, profilepicture, role } =
      formInputValues;

    const userSettingsData = {
      phone,
      jobtitle,
      timezone,
      location,
      numberformat,
      timeformat,
      dateformat,
      language,
      secondaryemail,
    };
    const slUserData = { firstname, lastname, email, profilepicture, role };

    handleSubmit(
      { sk_id: fetchedUserSettings.sk_id, ...userSettingsData },
      { userId: ghgciId, ...slUserData },
    );
  };

  const { t: translate } = useTranslation();

  return (
    <Stack
      maxWidth={{ lg: '100%' }}
      useFlexGap
      spacing={area === 'profile' ? { xs: 6 } : { xs: 7, md: 6 }}
    >
      <Grid
        container
        spacing={{ xs: 5, xl: 6 }}
        columnSpacing={{ xl: 5 }}
        direction={{
          xs: 'column',
          xl: 'row',
        }}
      >
        {mapFormControls(
          formControlsInfo,
          area,
          formInputValuesStateAndSetter,
          fetchedUserSettings,
        )}
      </Grid>

      <Stack direction={'row'} gap={{ xs: 7 }}>
        <CustomButton
          clickHandler={handleClick}
          disabled={isMandatoryPropertyMissing || isSavingDisabled}
          variant="contained"
          upperCaseText
        >
          {translate?.('formSaveButton')}
        </CustomButton>
      </Stack>
    </Stack>
  );
};
