import { yupResolver } from '@hookform/resolvers/yup';
import { AnchorLink, Button, Heading, Span, TextField } from 'components';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useAuth from 'configs/AuthContext';
import { useState } from 'react';
import { MessageContainer } from 'pages/forgot-password/components/styled';
import { useTranslation } from 'react-i18next';

export interface ResetPasswordRequestProps {
  nextStep: () => void;
  prevStep: () => void;
  userEmail: string;
}

export const InputEmailCode = (props: ResetPasswordRequestProps) => {
  const translation = useTranslation();
  const { t: translate } = translation;

  const resetPasswordSchema = yup.object().shape({
    confirmationCode: yup
      .string()
      .required(translate?.('inputEmailCodeConfirmationCodeErrorRequired'))
      .matches(
        /(.|\s)*\S(.|\s)*/,
        translate?.('inputEmailCodeConfirmationCodeErrorNotEmpty'),
      ),
    password: yup
      .string()
      .required(translate?.('inputEmailCodePasswordErrorRequired'))
      .min(8, translate?.('inputEmailCodePasswordErrorMin8'))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        translate?.('inputEmailCodePasswordErrorMatches'),
      ),
  });
  const {
    control,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      confirmationCode: '',
      password: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const navigate = useNavigate();
  const { confirmPassword } = useAuth();
  const [error, setError] = useState<string>('');
  const [showEmail, setShowEmail] = useState(false);

  async function onSubmit(_data: FieldValues) {
    try {
      confirmPassword(
        props.userEmail,
        _data.confirmationCode,
        _data.password,
        () => setError(''),
        (err: Error) => {
          setError(err.message);
        },
      );
      props.nextStep();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  const checkFieldValidity = async (
    fieldName: 'confirmationCode' | 'password',
  ) => {
    await trigger(fieldName);
  };

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <Heading level={1} color="secondaryBlue">
          {translate?.('inputEmailCodeTitle')}
        </Heading>
      </div>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={() => {
                checkFieldValidity('password');
              }}
              onChange={(e) => {
                field.onChange(e);
                clearErrors();
              }}
              label={translate?.('inputEmailCodePasswordLabel')}
              placeholder={translate?.('inputEmailCodePasswordPlaceholder')}
              type="password"
              status={
                errors.password ? 'error' : isValid ? 'success' : undefined
              }
              errorMessage={errors.password && errors.password.message}
            />
          )}
        />
        <Controller
          name="confirmationCode"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={() => {
                checkFieldValidity('confirmationCode');
              }}
              onChange={(e) => {
                field.onChange(e);
                clearErrors();
              }}
              label={translate?.('inputEmailCodeEmailCodeLabel')}
              placeholder={translate?.('inputEmailCodeEmailCodePlaceholder')}
              type="text"
              status={
                errors.confirmationCode
                  ? 'error'
                  : isValid
                    ? 'success'
                    : undefined
              }
              errorMessage={
                errors.confirmationCode && errors.confirmationCode.message
              }
            />
          )}
        />

        <div style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
          <Button type="submit">
            {translate?.('inputEmailCodeSubmitButton')}
          </Button>
        </div>
        {error != '' ? <p>{error} </p> : ''}
        <MessageContainer
          onClick={() => {
            setShowEmail(true);
          }}
        >
          <Span textDecoration={'underline'} color={'bluePrimary'}>
            {translate?.('inputEmailCodeReceiverConfirmation')}
          </Span>
          <Span fontSize={'13px'} color="spanText">
            {showEmail
              ? translate?.('inputEmailCodeEmailAddressConfirmation', {
                  userEmail: props.userEmail,
                })
              : ''}
          </Span>
          {showEmail && (
            <AnchorLink onClick={() => props.prevStep()} href="">
              {translate?.('inputEmailCodeWrongEmail')}
            </AnchorLink>
          )}
        </MessageContainer>

        <div
          style={{ width: '100%', marginTop: '12px', marginBottom: '32px' }}
        ></div>
      </form>
      <div>
        <AnchorLink onClick={() => navigate('/login')} href="">
          {translate?.('inputEmailCodeBottomMessage')}
        </AnchorLink>
      </div>
    </>
  );
};
