import styled from 'styled-components';
import { colors } from 'utils/colors';
import { breakpoints } from 'utils/breakpoints';
export const StyledSpan = styled.span`
  padding: 24px 0;
  display: flex;
  height: 13px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  color: ${colors.spanText};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
`;

export const CardText = styled.p`
  color: ${colors.darkGrey};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 450px;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 15px;
  }
`;
export const MessageContainer = styled.div`
  display: flex;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 10px;
  }
`;
