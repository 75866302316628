// AccordionItem.tsx
import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <>{children}</>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const src = node.data.target.fields.file?.url ?? '';
      const title = node.data.target.fields.title ?? '';
      return <img src={src} alt={title} width={500} height="auto" />;
    },
  },
};

interface AccordionItemProps {
  question: string;
  answer: any; // TODO - Define the type more precisely according to the content structure
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  question,
  answer,
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component={'span'}>
          {documentToReactComponents(answer, options)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
