import {
  FormControlLabel,
  MenuItem,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { transformToSnakeCase } from '../utilities/transform-to-snake-case';
import { FormControlProps } from './form';
import { DateTime } from 'luxon';

export const MapRadioOptions = (props: FormControlProps) => {
  const { options } = props.formControlInfo;
  const theme = useTheme();

  return (
    <>
      {options?.map((option) => {
        if ('iana' in option) return null;

        return (
          <FormControlLabel
            key={transformToSnakeCase(option.value)}
            value={option.value}
            componentsProps={{
              typography: {
                variant: 'body2',
                color: 'text.primary',
              },
            }}
            control={
              <Radio
                sx={{
                  height: theme.spacing(5),
                  width: theme.spacing(5),
                  padding: 0,
                  marginY: { xs: 2 },
                }}
              />
            }
            label={
              'displayedValue' in option ? option.displayedValue : option.value
            }
            sx={{
              gap: 4,
              marginX: 0,
            }}
          />
        );
      })}
    </>
  );
};

export const mapMenuItems = (props: FormControlProps) => {
  const { formControlInfo } = props;

  return formControlInfo.options?.map((option) => {
    const valueAsArrayElements = Array.isArray(option.value)
      ? option.value
      : [option.value];

    const createDateIfOptionIsTimezone =
      'iana' in option && DateTime.local({ zone: option.iana });

    const timezoneRegion =
      createDateIfOptionIsTimezone &&
      createDateIfOptionIsTimezone.toFormat('ZZZZZ');

    const returnDstOrStandard = () => {
      if (createDateIfOptionIsTimezone) {
        if (createDateIfOptionIsTimezone.isInDST) {
          return option.dstOffset;
        } else {
          return option.standardOffset;
        }
      }
    };

    const valueAndKey =
      'iana' in option ? option.iana : valueAsArrayElements[0];

    return (
      <MenuItem key={transformToSnakeCase(valueAndKey)} value={valueAndKey}>
        <Typography variant="caption">
          {timezoneRegion
            ? `${timezoneRegion} ${returnDstOrStandard()}`
            : valueAsArrayElements[0]}
        </Typography>
      </MenuItem>
    );
  });
};
