import { createTheme, CSSObject } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';

const breakpoints = {
  values: {
    xs: 0,
    sm: 324,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
};

// Extend the Theme interface using module augmentation
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true; // Add xxl to the BreakpointOverrides
  }
  interface Theme {
    customStyles: {
      drawerPaper: CSSObject;
    };
  }
  interface ThemeOptions {
    customStyles?: {
      drawerPaper?: CSSObject;
    };
  }
}

export const customSpacing = [
  0, //0
  2, //1
  4, //2
  8, //3
  12, //4
  16, //5
  20, //6
  24, //7
  32, //8
  40, //9
  48, //10
  72, //11
  96, //12
  124, //13
  160, //14
];

//using theme composition below, to allow components themed component to get values from the theme palette,typography etc...
//1.creating basic parts of the theme
let theme = createTheme({
  palette,
  spacing: customSpacing,
  typography,
  breakpoints,
});

//2. creating parts of theme that require info from other parts of the theme
theme = createTheme(theme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          minHeight: '60px !important', // Set minimum height
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: `0px ${customSpacing[7]}px !important`,
          minHeight: '60px !important', // Ensure consistent minimum height
          height: '60px !important', // Set explicit height
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'pageContainer' },
              style: {
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                height: '100%',
                maxWidth: '100%',
                overflowX: 'hidden',
              },
            },
          ],
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          //Todo - Change for the real colors and spacing after applied
          backgroundColor: 'primary',
          borderRadius: 2,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    //INPUTS
    MuiFormControl: {
      styleOverrides: {
        root: {
          label: {
            ...theme.typography.body2,
            color: theme.palette.secondary.dark,
            top: `-${theme.spacing(2)}`,
          },

          '& .MuiInputBase-root': {
            '& .MuiInputBase-input': {
              ...theme.typography.body2,
              padding: 0,
            },
          },

          '& .MuiInputLabel-shrink': {
            ...theme.typography.caption,
            color: theme.palette.primary.main,
            top: 'initial',
            transform: 'translate(14px, -9px) scale(1)', //default value provided by MUI
          },

          '& .MuiOutlinedInput-root': {
            padding: theme.spacing(4),
            '& .MuiOutlinedInput-notchedOutline ': {
              borderColor: theme.palette.primary.main,
            },

            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.dark,
            },

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.light,
            },
          },

          '& .MuiFormHelperText-root': {
            ...theme.typography.caption,
            lineHeight: 1,
            margin: 0,
            padding: `${theme.spacing(2)} ${theme.spacing(4)} 0`,
            color: theme.palette.error.main,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '& .MuiTypography-root': {
            ...theme.typography.body2,
          },
        },
        icon: {
          fill: theme.palette.primary.main,
        },
      },
    },
  },

  //CUSTOM STYLES
  customStyles: {
    drawerPaper: {
      height: '100%',
      width: 240,
      backgroundColor: theme.palette.common.white,
    },
  },
});

export default theme;
