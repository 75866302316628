import zxcvbn from 'zxcvbn';

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /// source of regex: https://medium.com/@jgratereaux/validate-emails-with-regular-expressions-2636ce08ddc2
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const passwordStrengthEvaluator = (password: string) => {
  const elevation = zxcvbn(password);
  return elevation.score;
};
