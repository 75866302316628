import { getWindowDimensions } from 'utils/window-utils';
import { useEffect, useState } from 'react';
import { LG } from 'utils/breakpoints';

// This hook returns the windows width and a boolean if window is mobile size(isDesktopWidth)
export const useIsWindowWidthDesktop = () => {
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isDesktopWidth, setIsDesktopWidth] = useState<boolean>(
    windowSize.width < LG,
  );
  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };
  const windowSizeWidth = windowSize.width;

  useEffect(() => {
    setIsDesktopWidth(windowSize.width <= 992);
    return () => {};
  }, [windowSizeWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isDesktopWidth, windowSizeWidth };
};
