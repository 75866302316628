import createPalette, {
  PaletteOptions,
} from '@mui/material/styles/createPalette';
import { colors } from '../utils';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    bg: { box: string; main: string };
    outlines: { main: string };
    neutral: { main: string };
    textColor: { main: string };
    textColorSecondary: { secondary: string };
    chevronGray: { main: string };
    notificationBorderShadow: { main: string };
  }

  interface PaletteOptions {
    bg?: { box: string; main: string };
    outlines?: { main: string };
    neutral?: { main: string };
    textColor?: { main: string };
    textColorSecondary?: { secondary: string };
    chevronGray?: { main: string };
    notificationBorderShadow?: { main: string };
  }

  interface PaletteColor {
    contrastText: string;
    box: string;
  }

  interface SimplePaletteColorOptions {
    contrast?: string;
    box?: string;
  }
  interface TypeText {
    //doesnt need an options version as the paletteOptions interface uses is as a partial (makes all its properties optional)
    contrast: string;
  }
}

const paletteObject: PaletteOptions = {
  common: {
    black: `${colors.black}`,
    white: `${colors.white}`,
  },
  primary: {
    main: `${colors.bluePrimary}`,
    light: `${colors.lightPrimaryBlue}`,
    dark: `${colors.darkPrimaryBlue}`,
  },
  secondary: {
    main: `${colors.lighterSecondaryBlue}`,
    light: `${colors.lightSecondary}`,
    dark: `${colors.darkSecondary}`,
  },
  bg: {
    box: `${colors.white}`,
    main: `${colors.lightestGrey}`,
  },
  success: {
    main: `${colors.altGreen}`,
    light: `${colors.lightGreen}`,
    dark: `${colors.darkGreen}`,
  },
  warning: {
    main: `${colors.warningOrange}`,
    light: `${colors.lightWarningOrange}`,
    dark: `${colors.darkWarningOrange}`,
  },
  error: {
    main: `${colors.red}`,
    light: `${colors.lightErrorRed}`,
    dark: `${colors.darkErrorRed}`,
  },
  info: {
    main: `${colors.blueInfo}`,
    light: `${colors.lightInfoBlue}`,
    dark: `${colors.darkInfoBlue}`,
  },
  text: {
    primary: `${colors.labelText}`,
    secondary: `${colors.bluePrimary}`,
    contrast: `${colors.white}`,
    disabled: `${colors.textDisabled}`,
  },
  outlines: {
    main: `${colors.outlineMain}`,
  },
  neutral: {
    main: `${colors.spanText}`,
  },
  textColor: {
    main: `${colors.darkGrey}`,
  },
  textColorSecondary: {
    secondary: `${colors.textColorSecondary}`,
  },
  chevronGray: {
    main: `${colors.chevronGray}`,
  },
  notificationBorderShadow: {
    main: `${colors.notificationBorderShadow}`,
  },
};

export const palette = createPalette(paletteObject);
