import { Typography, Box } from '@mui/material';
import React from 'react';
import { StringNumberOrResponsiveObject } from '../../molecules';

//TODO: Carl's notes - we might need to review the typography, --- if all headers are the same, we should use settings below in theme...we could create a new variant called pageTitle.

export const PageTitle = ({
  title,
  py = { xs: 2 },
}: {
  title: string;
  py?: StringNumberOrResponsiveObject;
}) => {
  return (
    <Box py={py}>
      <Typography
        color="primary.dark"
        sx={{ typography: { xs: 'h5', sm: 'h4' } }}
      >
        {title}
      </Typography>
    </Box>
  );
};
