import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const StyledForm = styled.form`
  //width: 568px;
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${breakpoints.md}) {
    display: block;
    height: 860px;
    width: 100%;
  }
`;
