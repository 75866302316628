import { colors } from 'utils/colors';

export const DateArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 19 19"
    fill="none"
  >
    <rect
      x="0.5"
      y="0.5"
      width="18"
      height="18"
      rx="4"
      fill={colors.bluePrimary}
    />
    <path d="M4.5 10L12.75 5.23686L12.75 14.7631L4.5 10Z" fill="white" />
  </svg>
);
