import { colors } from 'utils/colors';

export const PlusIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <circle cx="9" cy="9" r="8" stroke={color ? color : colors.bluePrimary} />
    <path
      d="M9 4V14.2857"
      stroke={color ? color : colors.bluePrimary}
      strokeLinejoin="round"
    />
    <path
      d="M14.2857 9L4 9"
      stroke={color ? color : colors.bluePrimary}
      strokeLinejoin="round"
    />
  </svg>
);
