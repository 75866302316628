import { Item } from '../react-query-toolkit/state/types';

export const findItemsByTypename = <T extends Item['typename']>(
  data: Item[],
  typename: T,
): Extract<Item, { typename: T }>[] => {
  return data.filter(
    (item): item is Extract<Item, { typename: T }> =>
      item.typename === typename,
  );
};
