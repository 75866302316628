import React, { useState } from 'react';
import { Calendar } from './components/calendar';
import { Box } from '@mui/material';
import { Card } from 'components';
import { List } from './components/list';
import { TaskManager } from './components/task-manager';
import { Container } from './styled';
import { myProjectsList } from './temp-data/my-projects';
import { TaskModalForm } from 'pages/task-manager/components/popup-modal/task-modal-form';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { Clients } from 'pages/dashboard/components/clients';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import Grid from '@mui/material/Grid2';
import NotificationCard from 'pages/dashboard/components/notification-card/notification-card';
import { notificationCardInfo } from 'pages/dashboard/components/notification-card/notification-card-info';
import LoadingComponent from 'components/loading-icon-component';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { Item, Project } from '../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';

export const Dashboard = () => {
  const [selectedData, setSelectedData] = useState<NewTaskDataType | null>(
    null,
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const { t: translate } = useTranslation();

  const { handleGetUser, isGetUserLoading } = useUserDataContext();

  // Adjust rawUserData to be Item[] | null
  const rawUserData: Item[] | null = handleGetUser() || null;

  const userProjects: Project[] =
    rawUserData && Array.isArray(rawUserData)
      ? findItemsByTypename(rawUserData, 'ref:project')
      : [];

  return (
    <>
      {isGetUserLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <PageWrapper>
            <Grid container direction="column" alignItems={'center'}>
              <Grid
                container
                spacing={4}
                maxWidth={{ xs: '570px', xl: '100%' }}
              >
                <Grid size={12}>
                  <Box maxWidth={'570px'} width={'100%'}>
                    <Container width={'100%'} margin={'0'}>
                      <PageTitle title={translate?.('headerDashboard')} />
                    </Container>
                  </Box>
                </Grid>

                <Grid
                  container
                  direction={'column'}
                  spacing={4}
                  size={{ xs: 12, xl: 7 }}
                >
                  <Grid container size={12} spacing={4}>
                    {notificationCardInfo.map((card, index) => (
                      <Grid size={4} key={index}>
                        <NotificationCard
                          key={index}
                          cardIcon={card.cardIcon}
                          cardTitle={card.cardTitle}
                          pathLink={card.pathLink}
                          activeNumber={card.activeNumber}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Grid size={12}>
                    <Box height={'100%'}>
                      <Card height={'100%'} width={'100%'} noPadding>
                        {/*Reusage of previous component - no mui*/}
                        <Clients />
                      </Card>
                    </Box>
                  </Grid>

                  <Grid size={12}>
                    <Box>
                      {/*Reusage of previous component - no mui*/}
                      <Card height={'100%'} width={'100%'} noPadding>
                        <List
                          title={translate?.('headerProjects')}
                          listData={myProjectsList(userProjects)}
                        />
                      </Card>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={4} size={{ xs: 12, xl: 5 }}>
                  <Grid size={12}>
                    <Calendar />
                  </Grid>
                  <Grid size={12}>
                    <Box>
                      {/*Reusage of previous component - no mui*/}
                      <TaskManager
                        setModalIsOpen={setModalIsOpen}
                        setModalType={setModalType}
                        setSelectedData={setSelectedData}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {selectedData ? (
              <TaskModalForm
                selectedData={selectedData}
                isOpen={modalIsOpen}
                toggleModal={setModalIsOpen}
                modalType={modalType}
              />
            ) : null}
          </PageWrapper>
        </>
      )}
    </>
  );
};
