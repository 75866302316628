import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useUpdateSlMutation,
  useDeleteSlMutation,
  useCreateSlMutation,
  useGetPlQuery,
} from '../services/ghgci-sfe';
import { PlEntities, SlEntities } from '../services/types';
import useAuth from 'configs/AuthContext';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { Task } from './types';

type taskContextType = {
  handleGetTasks: () => Task[] | undefined;
  tasksListIsLoading: boolean;
  tasksFetchingError: FetchBaseQueryError | SerializedError | undefined;
  handleCreateTask: (
    taskName: string,
    priority: string,
    dueDate: string | Date,
    description: string,
    clientId: string,
  ) => void;
  handleUpdateTask: (
    taskId: string,
    taskName: string,
    priority: string,
    completed: boolean,
    dueDate: string | Date,
    description: string,
    clientId: string,
  ) => void;
  handleDeleteTask: (customId: string) => void;
  isCreateTaskSuccess: boolean;
  isCreateTaskLoading: boolean;
  isUpdateTaskLoading: boolean;
  updateTaskError: FetchBaseQueryError | SerializedError | undefined;
  isUpdateTaskSuccess: boolean;
  resetCreateTaskQueryValues: () => void;
  resetUpdateTaskQueryValues: () => void;
  setTaskIdSelected: Dispatch<SetStateAction<string>>;
  taskIdSelected: string;
};
const defaultTaskContext: taskContextType = {
  handleGetTasks: () => [], // Provide a default implementation for handleGetTasks
  tasksListIsLoading: false,
  tasksFetchingError: {},
  handleCreateTask: () => {},
  handleUpdateTask: () => {},
  handleDeleteTask: () => {}, // Provide a default implementation for handleDeleteTask
  isCreateTaskSuccess: false,
  isCreateTaskLoading: false,
  isUpdateTaskLoading: false,
  updateTaskError: {},
  isUpdateTaskSuccess: false,
  resetCreateTaskQueryValues: () => {},
  resetUpdateTaskQueryValues: () => {},
  setTaskIdSelected: () => {},
  taskIdSelected: '',
};
const DataContext = createContext(defaultTaskContext);

export const TaskDataProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const ghgciId = auth.user?.getSignInUserSession()!.getIdToken().payload[
    'custom:ghgci_id'
  ];
  // All RTK Queries but the get query
  const [
    createTask,
    {
      isLoading: isCreateTaskLoading,
      isSuccess: isCreateTaskSuccess,
      reset: resetCreateTaskQueryValues,
    },
  ] = useCreateSlMutation();
  const [deleteTask] = useDeleteSlMutation();
  const [
    updateTask,
    {
      isLoading: isUpdateTaskLoading,
      error: updateTaskError,
      isSuccess: isUpdateTaskSuccess,
      reset: resetUpdateTaskQueryValues,
    },
  ] = useUpdateSlMutation();

  const handleCreateTask = async (
    taskName: string,
    priority: string,
    dueDate: string | Date,
    description: string,
    clientId: string,
  ) => {
    createTask({
      typename: PlEntities.User,
      id: ghgciId,
      requestBody: {
        name: taskName,
        completed: false,
        priority: priority,
        typename: SlEntities.Task,
        duedate: dueDate,
        description: description,
        clientId: clientId,
      },
    }).unwrap();
  };

  const handleUpdateTask = async (
    taskId: string,
    taskName: string,
    priority: string,
    completed: boolean,
    dueDate: string | Date,
    description: string,
    clientId: string,
  ) => {
    updateTask({
      typename: PlEntities.User,
      id: ghgciId,
      requestBody: {
        id: taskId,
        typename: SlEntities.Task,
        name: taskName,
        completed: completed,
        priority: priority,
        duedate: dueDate,
        description: description,
        clientId: clientId,
      },
    }).unwrap();
  };

  const handleDeleteTask = async (taskId: string) => {
    deleteTask({
      typename: PlEntities.User,
      id: ghgciId,
      requestBody: {
        id: taskId,
        typename: SlEntities.Task,
      },
    }).unwrap();
  };

  const { isLoading: tasksListIsLoading, error: tasksFetchingError } =
    useGetPlQuery({
      typename: PlEntities.User,
      id: ghgciId,
    });

  const handleGetTasks = () => {
    const { data } = useGetPlQuery({
      typename: PlEntities.User,
      id: ghgciId,
    });
    // Check if data is not undefined before accessing its properties
    if (data) {
      return findItemsByTypename(data.Items, 'task');
    } else {
      return [];
    }
  };

  const location = useLocation();

  const [taskIdSelected, setTaskIdSelected] = useState('');

  // This will clear the value of taskId whenever the user is out of the search page.
  useEffect(() => {
    if (location.pathname !== '/task-manager') {
      setTaskIdSelected('');
    }
  }, [location.pathname]);

  return (
    <DataContext.Provider
      value={{
        handleUpdateTask,
        handleDeleteTask,
        isUpdateTaskLoading,
        updateTaskError,
        isCreateTaskSuccess,
        isCreateTaskLoading,
        isUpdateTaskSuccess,
        handleGetTasks,
        tasksFetchingError,
        tasksListIsLoading,
        handleCreateTask,
        resetCreateTaskQueryValues,
        resetUpdateTaskQueryValues,
        taskIdSelected,
        setTaskIdSelected,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useTaskDataContext = () => useContext(DataContext);
