import styled from 'styled-components';
import { colors } from 'utils/colors';
import { breakpoints } from 'utils/breakpoints';
import theme from '../../theme/theme';
export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevents overflow */
  .fc-media-screen {
    background-color: ${colors.white};
  }
  .fc-daygrid-day-events {
    min-height: 0.1em;
  }

  .fc-more-popover-misc {
    height: 0;
  }

  .fc-popover-header {
    padding: 20px 12px;
  }

  .fc-daygrid-event-harness {
    max-width: 165px;
  }

  .fc-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 20px;
  }

  .fc-toolbar h2 {
    font-size: 24px;
    font-weight: 600;
    color: ${colors.secondaryBlue};
  }

  .fc-button {
    color: ${colors.white};
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }

  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 4px 8px;
    flex: 1 0 0;
    background-color: ${colors.white};
  }
  .fc-daygrid-day-events {
    top: 1px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .fc-col-header-cell-cushion {
    background: ${colors.white};
    font-size: ${theme.typography.subtitle2.fontSize};
    font-weight: ${theme.typography.subtitle2.fontWeight};
    line-height: ${theme.typography.subtitle2.lineHeight};
    letter-spacing: ${theme.typography.subtitle2.letterSpacing};
  }

  .fc-daygrid-day-number {
    font-size: ${theme.typography.subtitle1.fontSize};
    font-weight: ${theme.typography.subtitle1.fontWeight};
    line-height: ${theme.typography.subtitle1.lineHeight};
    letter-spacing: ${theme.typography.subtitle1.letterSpacing};

    color: ${colors.darkPrimaryBlue};
    margin-left: 8px;
  }

  .fc .fc-daygrid-day-number {
    padding: 0;
  }

  .fc-daygrid-event {
    // background-color: ${colors.lightPrimaryBlue};
    padding: 0;
    border-radius: 4px;
  }

  .fc .fc-daygrid-event {
    margin-top: 0;
  }
  .fc .fc-daygrid-more-link {
    padding: 0;
    margin: 0;
  }

  th {
    .fc-scrollgrid-sync-inner {
      background: ${colors.white};
      font-size: ${theme.typography.subtitle2.fontSize};
      font-weight: ${theme.typography.subtitle2.fontWeight};
      line-height: ${theme.typography.subtitle2.lineHeight};
      letter-spacing: ${theme.typography.subtitle2.letterSpacing};
    }
  }

  .fc-daygrid-event-dot {
  }

  .fc-title {
    white-space: normal;
    word-wrap: break-word;
  }

  .fc-more-link {
    color: ${colors.darkSecondary};
    font-size: ${theme.typography.link.fontSize};
    font-weight: ${theme.typography.link.fontWeight};
    line-height: ${theme.typography.link.lineHeight};
    letter-spacing: ${theme.typography.link.letterSpacing};
    text-decoration: underline;
  }

  .fc-popover-title {
    color: ${colors.chevronGray};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.1px;
  }

  .fc-popover-header {
    background: ${colors.white};
  }

  .fc-popover-body {
    background: ${colors.white};
  }
  .fc-daygrid-day-events {
    margin-top: auto;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${colors.white};
  }

  .fc-icon-x {
    color: ${colors.chevronGray};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.1px;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 0.1em;
    //min-height: fit-content;
    position: relative;
  }

  /* Apply a red border to all day cells */
  .fc-daygrid-day,
  .fc-col-header-cell,
  .fc-daygrid-day-frame,
  .fc-daygrid-day-frame,
  .fc-day,
  .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th,
  .fc-daygrid-event-harness,
  .fc-theme-standard,
  .fc-theme-standard,
  th {
    border-color: ${colors.outlineMain} !important;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const DateRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 4px;
  flex-direction: row;
  gap: 8px;
  align-self: stretch;
  border: 1px solid ${colors.outlineMain};
  background-color: ${colors.white};
  border-bottom: none;
  flex-shrink: 0; /* Prevents the header from shrinking */
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 28px;
`;
export const ArrowContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
`;

export const DateText = styled.div`
  text-align: center;

  font-size: ${theme.typography.h5.fontSize}
  font-weight: ${theme.typography.h5.fontWeight}
  line-height: ${theme.typography.h5.lineHeight}
  letter-spacing: ${theme.typography.h5.letterSpacing}
  font-size: ${theme.typography.h5.fontSize}
  color: ${colors.secondaryBlue};

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 10px;
  }
`;

export const CalendarEventContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: 15px;
  overflow: hidden;
`;

export const BlueCircle = styled.div`
  background: ${colors.sidebarGrey};
  border-radius: 50%;
  width: 25px; /* Adjust the size as needed */
  height: 25px; /* Adjust the size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  left: -10px;
`;
export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 20px 0;
  flex: 1 0 0;
  align-self: stretch;
  margin: 0;
`;
export const ErrorMessageContainer = styled.p`
  display: flex;
  width: 300px;
  margin: 0;
`;
export const ClosingIconContainer = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  right: 63px;
  top: 68px;
`;
