import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { fontSize, fontWeight } from 'utils/styles';
import { activeColor, hoverColor } from '../button';

const Link = styled.a`
  color: ${colors.bluePrimary};
  text-decoration: underline;
  font-size: ${fontSize.textSm};
  fontweight: ${fontWeight.semiBold};
  &:hover {
    color: ${hoverColor};
  }
  &:active {
    color: ${activeColor};
  }
`;

type MimeType =
  | 'text/plain'
  | 'text/html'
  | 'image/jpeg'
  | 'image/png'
  | 'application/json'
  | 'application/pdf';

type Props = {
  className?: string;
  id?: string;
  dataTestId?: string;
  download?: boolean;
  href: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  type?: MimeType;
  rel?: string; // This is limited to rel specific entries...
  children: JSX.Element | string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const AnchorLink = ({
  className,
  id,
  dataTestId,
  href,
  download,
  target,
  type,
  rel,
  onClick,
  children,
}: Props) => {
  return (
    <Link
      onClick={onClick}
      id={id}
      className={className}
      data-testid={dataTestId ?? 'ghg-app-anchor-link'}
      type={type}
      href={href}
      download={download}
      target={target}
      rel={rel}
    >
      {children}
    </Link>
  );
};
