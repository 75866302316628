import {
  InnerText,
  ProfileImageIcon,
} from 'pages/clients/components/clients-table/styled';
import { CopyToClipBoard } from 'utils/copy-to-clipboard';

export const ProfileImage = ({
  profileName,
  imageIdx,
  moveValue,
}: {
  profileName: string;
  imageIdx: string;
  moveValue: string;
}) => {
  // where initials is 2 length strings

  const split = profileName.toUpperCase();
  const initials = `${split[0]}`; // First character of each word
  const opacity = +imageIdx * 7;
  const profileOpacity = `rgb(${202 + opacity}, ${212 + opacity}, ${
    219 + opacity
  });`;
  const zIndex = `${10 - +imageIdx}`;

  return (
    <CopyToClipBoard copyText={profileName}>
      <ProfileImageIcon
        move={moveValue}
        opacity={profileOpacity}
        zIndex={zIndex}
      >
        <InnerText>{initials}</InnerText>
      </ProfileImageIcon>
    </CopyToClipBoard>
  );
};
