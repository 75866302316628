import { HeadingContainer } from './styled';
import React from 'react';

type Props = {
  fontWeight?: number;
  color?: string;
  children: string | React.ReactNode;
  level: number;
  padding?: string;
};

export const Heading = ({
  padding,
  fontWeight,
  color,
  children,
  level,
}: Props) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;
  return (
    <HeadingContainer padding={padding} color={color} fontWeight={fontWeight}>
      <Tag className="ghgci-heading">{children}</Tag>
    </HeadingContainer>
  );
};
