export const MobileCalendarIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8751 8.62503H8.12518V12.3749H11.8751V8.62503ZM10.6251 0.500244V1.75021H4.37528V0.500244H2.50033V1.75021H1.56246C0.703029 1.75021 0 2.45324 0 3.31267V13.9374C0 14.7968 0.703029 15.4999 1.56246 15.4999H13.4375C14.297 15.4999 15 14.7968 15 13.9374V3.31267C15 2.45324 14.297 1.75021 13.4375 1.75021H12.5001V0.500244H10.6251ZM13.4375 13.9374H1.56246V5.65636H13.4375V13.9374Z"
      fill="#CAD4DB"
    />
  </svg>
);
