export const colors = {
  alto: '#dedede',
  crow: '#202020',
  dodgerBlue: '#4993ff',
  ghost: '#c8cbd6',
  jungleGreen: '#29a573',
  kleinBlue: '#0022B0',
  linkWater: '#d8e8ff',
  secondaryBlue: '#53708a',
  lighterSecondaryBlue: '#CAD4DB',
  spanText: '#837B70',
  orange: '#ffa300',
  silver: '#d8d8d8',
  white: '#ffffff',
  hoverWhite: '#ebeedf',
  tundora: '#404040',
  black: '#000000',
  red: '#ff0000',
  bluePrimary: '#0C82B4',
  blueInfo: '#0973A0',
  greenOnline: '#2BB115',
  greenCheckmark: '#198038',
  hoverGreen: '#7f8854',
  darkestGrey: '#212121',
  darkGrey: '#51565A',
  grey: '#6C655B',
  lightGrey: '#999999',
  lightestGrey: '#f7f8fa',
  sidebarGrey: '#f5f5f5',
  sidebarHoverGrey: '#ecedee',
  transparent: 'transparent',
  statusBarGrey: '#D9D9D9',
  yellow: '#F2C94C',
  textFieldPlaceholderGrey: '#CDD2D8',
  textFieldBoxShadow: '#7a5af824',
  textFieldBorderGrey: '#9E9E9E',
  textFieldSuccessGreen: '#0f991810',
  errorRed: '#e54545',
  tableGray: '#868FA0',
  lineGray: '#cad4db40',
  subRowGray: '#f3f3f3cc',
  warningYellow: '#FEA04A',
  placeholderGrey: '#BDBDBD',
  cardShadow: 'rgba(202, 212, 219, 0.25)',
  cardShadowDarker: 'rgba(0, 0, 0, 0.1)',
  alertYellow: 'rgba(254, 160, 74, 0.60)',
  shadowGrey: 'rgba(159, 159, 159, 0.25)',
  textGrey: '#B4B4B4', //changed name from textGrey
  flagBlue: '#2B65FA',
  flagYellow: '#FEA04A',
  flagRed: '#FA2B2B',
  backgroundGray: 'rgba(156, 168, 96, 0.00)',
  boxGrey: 'rgba(217, 217, 217, 0.50)',
  boxDarkGrey: 'rgba(202, 212, 219, 0.60)',
  notificationBorderShadow: 'rgba(210, 210, 210, 0.81)',
  labelText: '#51565A', //changed name from labelText
  altGreen: '#96AC25',
  chevronGray: '#08668E',
  fillWhiteColor: '#c2c2c2',
  arrowBlue: '#08668E',
  modalBorder: '#ccc',
  textColorSecondary: '#222222',

  lightSecondary: '#E9EDF2', //added
  darkSecondary: '#889AA9', //added
  lightWarningOrange: '#FFBC6E', //added
  warningOrange: '#FF9637', //added
  darkWarningOrange: '#E97A3F', //added
  lightErrorRed: '#FF795B', //added
  darkErrorRed: '#DA0000', //added
  lightPrimaryBlue: '#1FA1D4', //added
  darkPrimaryBlue: '#0B71A0', //added
  lightInfoBlue: '#57BCDD', //added
  darkInfoBlue: '#01527F', //added
  lightGreen: '#C5E171', //added,
  darkGreen: '#6E6F0C', //added
  textDisabled: '#E7E7E7', //added
  outlineMain: '#9F9F9F40', //added
};

export type Colors = keyof typeof colors;
