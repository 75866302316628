export const MobileCalculatorIcon = () => (
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="14" height="21" rx="3" fill="#CAD4DB" />
    <rect
      x="1.6875"
      y="3.37549"
      width="10.9706"
      height="2.53167"
      rx="1"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.21924 6.75122)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.21924 10.1267)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.21924 13.5022)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.21924 16.8777)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 8.43896 6.75122)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 8.43896 10.1267)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 8.43896 13.5022)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 12.6587 6.75122)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 12.6587 10.1267)"
      fill="white"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 12.6587 13.5022)"
      fill="white"
    />
    <rect
      width="6.75113"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 12.6587 16.8777)"
      fill="white"
    />
  </svg>
);
