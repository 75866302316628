import React, { useRef, useState } from 'react';
import {
  LocalizationProvider,
  DateTimePicker,
  DateTimeValidationError,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { Stack, useTheme } from '@mui/material';
import Popper from '@mui/material/Popper';
import { DateFormats, TimeFormats } from 'pages/user-profile/components/types';
import { Timezones } from 'components/date-picker/types';

export type ViewsValues =
  | 'year'
  | 'month'
  | 'day'
  | 'hours'
  | 'minutes'
  | 'seconds';

export interface DateSelectorOptions {
  hasPastDatesSelectionActive?: boolean;
  hasFutureDatesSelectionDisabled?: boolean; //no needed
  hasOtherMonthsVisible?: boolean;
  hasLocalSettings?: boolean;
  viewsFormat?: ViewsValues[];
}

export type ReceivedValue = string | Record<string, unknown>;

export interface DateSelectorApiProps {
  dateFormat?: DateFormats;
  timeFormat?: TimeFormats;
  timeZone?: Timezones; //timezones will only come from interface or from DateTime.local().zoneName 's method.
  language?: string;
  name: string;
  dateData?: ReceivedValue;
  dateUpdater: React.Dispatch<React.SetStateAction<ReceivedValue>>;
}

export const DateSelector = (
  props: DateSelectorApiProps & DateSelectorOptions,
) => {
  const {
    hasFutureDatesSelectionDisabled,
    viewsFormat = ['year', 'day', 'hours', 'minutes'],
    hasOtherMonthsVisible,
    dateFormat = 'MMM d, y',
    timeFormat = 'HH:mm',
    timeZone = DateTime.local().zoneName,
    dateData,
    dateUpdater,
    name,
    /*     language = 'en', */
  } = props;

  const getDateValueFromData = (
    //if date value is passed a string, it will be returned as is, if it is an object, it will return the value of the key passed in the name prop.
    _dateData: ReceivedValue | undefined,
    _name: string,
  ): string | undefined => {
    if (!_dateData) {
      return;
    }
    if (typeof _dateData === 'string') {
      return _dateData;
    }

    return _dateData[_name] as string;
  };

  const DEFAULT_DATE = DateTime.local({ zone: timeZone });

  const getValidatedDateValue = (_dateValue: string | undefined) => {
    if (!_dateValue) {
      return DEFAULT_DATE;
    } else {
      const dateFromIso = DateTime.fromISO(_dateValue);

      if (dateFromIso.isValid) {
        return dateFromIso;
      } else {
        alert(
          `The date for ${name} was provided in an incorrect format,elements displayed dates have been set to current local date`,
        );
        return DEFAULT_DATE;
      }
    }
  };

  const [dateValue, setDateValue] = useState(
    getValidatedDateValue(getDateValueFromData(dateData, name)),
  );

  const toolbarDateFormat = 'dd/MM/';
  const popupAnchor = useRef(null);
  const [anchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const theme = useTheme();
  const desktopMediaQuery = `@media (min-width:${theme.breakpoints.values.lg}px)`;
  const timeZoneTester = {
    toronto: 'America/Toronto',
    london: 'Europe/London',
    berlin: 'Europe/Berlin',
    tokyo: 'Asia/Tokyo',
    paris: 'Europe/Paris',
  };

  const [error, setError] = useState<DateTimeValidationError | null>(null);
  const [helpMessage, setHelpMessage] = useState<string>('');

  const handleDateError = (_error: DateTimeValidationError) => {
    if (_error) {
      setError(_error);
    } else {
      setError(null);
      setHelpMessage('');
    }
  };

  const handleDateSelection = (selectedDateValue: DateTime<true> | null) => {
    selectedDateValue && setDateValue(selectedDateValue);
  };

  const handleConfirmation = (
    selectedDateValue: DateTime<true> | null,
    _dateData: ReceivedValue | undefined,
    _name: string,
  ) => {
    const newDate = selectedDateValue?.toISO();

    if (!error) {
      if (newDate) {
        if (typeof _dateData === 'string' || !_dateData) {
          dateUpdater(newDate);
        } else {
          dateUpdater({
            ..._dateData,
            [_name]: newDate,
          });
        }
      }
    } else {
      setHelpMessage('Please select a valid date');
    }
  };

  const dateTimePickerSlotProps = {
    tabs: {
      sx: {
        '& .MuiButtonBase-root': {
          //color: 'blue', // Override the color specifically for this component
          '&.Mui-selected': {
            backgroundColor: 'lightblue', // Background color when selected
            //color: 'darkblue', // Text color when selected
          },
        },
      },
    },
    toolbar: {
      toolbarFormat: toolbarDateFormat,
      sx: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiDateTimePickerToolbar-dateContainer': {
          flexDirection: 'row-reverse',
          alignItems: 'flex-end',
          '& .MuiTypography-root': {
            typography: 'subtitle1',
            color: theme.palette.text.primary,
          },
        },
        '& .MuiDateTimePickerToolbar-timeContainer': {
          alignItems: 'flex-end',
          '& .MuiTypography-root': {
            typography: 'subtitle1',
            color: theme.palette.text.primary,
          },
        },
      },
    },
    layout: {
      sx: {
        '& .MuiClock-root': {
          '.MuiIconButton-root.MuiClock-amButton': {
            color: 'white',
          },
          '.MuiIconButton-root.MuiClock-pmButton': {
            color: 'white',
          },
        },
        '& .MuiTypography-root': {
          color: theme.palette.common.black,
          '&:focus': {
            color: theme.palette.common.white,
          },
        },
      },
    },
    inputAdornment: {
      sx: {
        '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
      },
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Stack ref={popupAnchor} width={'auto'}>
        <Stack direction={'row'} alignItems={'center'} gap={0}>
          <DateTimePicker
            label={name}
            ampm={timeFormat === 'hh:mm a'}
            ampmInClock
            showDaysOutsideCurrentMonth={hasOtherMonthsVisible}
            disableFuture={hasFutureDatesSelectionDisabled}
            name="datePicker"
            value={dateValue}
            format={`${dateFormat} ${timeFormat}`}
            views={viewsFormat}
            desktopModeMediaQuery={desktopMediaQuery}
            sx={{
              width: '100%',
            }}
            onAccept={(newValue) =>
              handleConfirmation(newValue, dateData, name)
            }
            onChange={(newValue) => handleDateSelection(newValue)}
            onError={handleDateError}
            slotProps={{
              textField: {
                onBlur: () => handleConfirmation(dateValue, dateData, name),
                helperText: helpMessage,
              },
              popper: {
                sx: {
                  zIndex: '99999 !important',
                  '.MuiPickersArrowSwitcher-button': {
                    color: theme.palette.primary.main,
                  },
                },
              },
              ...dateTimePickerSlotProps,
              leftArrowIcon: {
                sx: { fill: theme.palette.common.black, stroke: 'none' },
              },
              rightArrowIcon: {
                sx: { fill: theme.palette.common.black, stroke: 'none' },
              },
              openPickerIcon: {
                sx: { fill: theme.palette.common.black, stroke: 'none' },
              },
            }}
          />
        </Stack>
        <Popper
          id={id}
          open={open}
          anchorEl={anchor}
          disablePortal
          popperOptions={{ placement: 'bottom' }}
          sx={{
            backgroundColor: theme.palette.common.white,
            p: 5,
            zIndex: 1000,
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
};
