import styled from 'styled-components';

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 70%;
  }
`;

export const GenericContainer = styled.div<{ escapeHatch?: string }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ escapeHatch }) => escapeHatch}
`;
