import React, { useState } from 'react';
import { ClientAccountInfo } from 'pages/request-account/components/client-account-info/client-account-info';
import { CreatePassword } from 'pages/request-account/components/create-password';
import { TellUsMore } from 'pages/request-account/components/tell-us-more/tell-us-more';
import { ServiceProfile } from 'pages/request-account/components/service-profile';
import { ReviewInfo } from 'pages/request-account/components/review-info';
import { ThankYouDisplay } from 'pages/request-account/components/thank-you-display';

export interface RequestInformationType {
  step: number;
  clientName: string;
  contactName: string;
  phone: string;
  email: string;
  country: string;
  state: string;
  region: string;
  city: string;
  address: string;
  organizationType: string;
  size: string;
  service: string;
  password: string;
  comment: string;
}
export function RequestAccount() {
  const [requestedInformation, setRequestedInformation] =
    useState<RequestInformationType>({
      step: 1,
      clientName: '',
      contactName: '',
      phone: '',
      email: '',
      country: '',
      state: '',
      region: '',
      city: '',
      address: '',
      organizationType: '',
      size: '',
      service: '',
      password: '',
      comment: '',
    });

  const onSubmitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      // TODO: - request to Submit Info
      //
      // if (answer from server is good) {
      //   nextStep();
      // }
      alert(` clientName': ${requestedInformation.clientName},
        "contactName": ${requestedInformation.contactName}},
        "phone": ${requestedInformation.phone},
        'email': ${requestedInformation.email},
        'country': ${requestedInformation.country},
        'state': ${requestedInformation.state},
        'region': ${requestedInformation.region},
        'city': ${requestedInformation.city},
        'address': ${requestedInformation.address},
        'organizationType': ${requestedInformation.organizationType},
        'size': ${requestedInformation.size},
        'service': ${requestedInformation.service},
        'password': ${requestedInformation.password},
        'comment': ${requestedInformation.comment}`);
      nextStep();
    } catch (error) {
      // console.log(error);
    }
  };

  // go back to previous step
  const prevStep = () => {
    if (requestedInformation.step > 1) {
      setRequestedInformation({ ...requestedInformation, step: step - 1 });
    }
  };

  // proceed to the next step
  const nextStep = () => {
    if (requestedInformation.step < 6) {
      setRequestedInformation({ ...requestedInformation, step: step + 1 });
    }
  };

  const { step } = requestedInformation;
  switch (step) {
    case 1:
      return (
        <ClientAccountInfo
          nextStep={nextStep}
          requestedInformation={requestedInformation}
          setRequestedInformation={setRequestedInformation}
        />
      );
    case 2:
      return (
        <CreatePassword
          setRequestedInformation={setRequestedInformation}
          requestedInformation={requestedInformation}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 3:
      return (
        <TellUsMore
          setRequestedInformation={setRequestedInformation}
          requestedInformation={requestedInformation}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 4:
      return (
        <ServiceProfile
          setRequestedInformation={setRequestedInformation}
          requestedInformation={requestedInformation}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 5:
      return (
        <ReviewInfo
          requestedInformation={requestedInformation}
          onSubmit={onSubmitForm}
          prevStep={prevStep}
        />
      );
    case 6:
      return <ThankYouDisplay />;
    default:
      return (
        <ClientAccountInfo
          nextStep={nextStep}
          requestedInformation={requestedInformation}
          setRequestedInformation={setRequestedInformation}
        />
      );
  }
}
