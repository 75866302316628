import styled from 'styled-components';
import { colors } from 'utils/colors';
import { spacingWithPx } from 'utils/styles';
import { breakpoints, LG } from 'utils/breakpoints';
import RowBootstrap from 'react-bootstrap/Row';
import { Card, Paper } from 'components';

// Use this to group small cards into a single row
export const RowContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

// TODO: rm?
// Get this to accept dynamic padding values (see DashboardSubContainer for example of dynamic props)
export const BorderContainer = styled.div<{
  width: number;
  spacing?: {
    margin?: string;
    padding?: string;
  };
  hover?: {
    backgroundColor: keyof typeof colors;
    textColor: keyof typeof colors;
  };
}>`
  border: 1.5px solid ${colors.bluePrimary};
  border-radius: 4px;
  background-color: ${colors.white};

  display: flex;
  flex-direction: column;
  padding: ${({ spacing }) => spacing?.padding ?? spacingWithPx.lg};
  margin: ${({ spacing }) => spacing?.margin ?? '0'};
  @media screen and (min-width: ${breakpoints.xl}) {
    padding: ${spacingWithPx.xl};
  }

  ${({ width, hover }) => {
    if (!hover) {
      return `
      width: ${width}%;
      `;
    }
    return `
    width: ${width}%;
    cursor: pointer;
    &:hover {
      background-color: ${colors[hover.backgroundColor]};
      path {
        fill: ${colors[hover.textColor]};

      }
      h3 {
        color: ${colors[hover.textColor]};
      }
      span {
        color: ${colors[hover.textColor]};
      }
    }
    `;
  }}
`;
export const CardTextContainer = styled.div`
  text-align: center;
`;

export const SummaryIconContainer = styled.div`
  margin-bottom: 10px;

  path {
    fill: ${colors.fillWhiteColor};
  }

  .icon-wrapper {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Row = styled(RowBootstrap)`
  display: none;
  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

export const HoverableContainer = styled.div`
  cursor: pointer;
  &:hover {
    > svg {
      fill: ${colors.bluePrimary};
    }
  }
`;

export const RowVerticalCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div<{
  show?: boolean;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  gap?: boolean;
  width?: string;
  maxWidth?: string;
  position?: string;
}>`
  display: ${({ show = true }) => (show ? 'flex' : 'none')};
  position: ${({ position }) => (position ? position : 'auto')};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'unset'};
  margin: ${({ margin }) =>
    margin ?? `${spacingWithPx.gap} ${spacingWithPx.gap}`};
  gap: ${({ gap }) => (gap ? spacingWithPx.gap : '0')};
  width: ${({ width }) => width ?? 'auto'};
  max-width: ${({ maxWidth }) => maxWidth ?? breakpoints.widescreen}};
`;
export const AlertBoxCard = styled(Card)`
  @media screen and (min-width: ${breakpoints.md}) and (max-width: ${LG -
    1}px) {
    align-items: center;
    justify-content: center;
  }
  padding: ${spacingWithPx.xs};
  @media screen and (min-width: ${breakpoints.md}) {
    padding: ${spacingWithPx.tb} ${spacingWithPx.md};
  }
`;

export const AlertBoxTextContainer = styled.div`
  display: block;
  white-space: nowrap;
  text-align: center;
`;

export const TableRowContainer = styled.div`
  padding: 0 20px;
  gap: 16px;
  display: flex;
  align-items: center;
`;

// TODO: Refactor this so that user can use Heading as a child of this one
export const CardHeader = styled.div<{ padding?: string; hasBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white};
  align-items: center;
  padding: ${({ padding }) =>
    padding ?? `${spacingWithPx.gap} ${spacingWithPx.gap}`};
  ${({ hasBorder = true }) =>
    hasBorder ? `border-bottom: 1px solid ${colors.lightGrey}25;` : ''}
`;

export const TextContainer = styled.div`
  cursor: pointer;
`;

export const StyledPaper = styled(Paper)`
  overflow-x: hidden;
`;
