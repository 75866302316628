import { NewClientDataType } from 'pages/clients/api/get-clients';

export function findClientByIdInSystemPLResponse(
  responses: NewClientDataType[],
  clientId: string,
): NewClientDataType | null {
  // Strip the "cl#" prefix from the clientId if it exists
  const formattedClientId = clientId.startsWith('cl#')
    ? clientId.slice(3)
    : clientId;

  for (const response of responses) {
    // Compare formatted clientId with response.clientId
    if (
      response.typename === 'client' &&
      response.clientId === formattedClientId
    ) {
      return response as NewClientDataType; // Type assertion to ensure TypeScript knows it's a SystemClient
    }
  }

  return null; // Return null if no matching client is found
}
