import { colors } from 'utils/colors';

export const ProjectIcon = () => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Proejct">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41667 0.5C5.80403 0.5 4.45886 1.64517 4.15001 3.16667H1.41667C1.04848 3.16667 0.75 3.46514 0.75 3.83333V15.8333C0.75 16.2015 1.04848 16.5 1.41667 16.5H13.4167C13.7849 16.5 14.0833 16.2015 14.0833 15.8333V3.83333C14.0833 3.46514 13.7849 3.16667 13.4167 3.16667H10.6833C10.3745 1.64517 9.02931 0.5 7.41667 0.5ZM8.75 3.16667C8.75 3.90305 8.15305 4.5 7.41667 4.5C6.68029 4.5 6.08333 3.90305 6.08333 3.16667C6.08333 2.43029 6.68029 1.83333 7.41667 1.83333C8.15305 1.83333 8.75 2.43029 8.75 3.16667ZM3.08333 5.83333C2.89924 5.83333 2.75 5.98257 2.75 6.16667V6.83333C2.75 7.01743 2.89924 7.16667 3.08333 7.16667H11.75C11.9341 7.16667 12.0833 7.01743 12.0833 6.83333V6.16667C12.0833 5.98257 11.9341 5.83333 11.75 5.83333H3.08333ZM2.75 8.83333C2.75 8.64924 2.89924 8.5 3.08333 8.5H11.75C11.9341 8.5 12.0833 8.64924 12.0833 8.83333V10.1667C12.0833 10.3508 11.9341 10.5 11.75 10.5H3.08333C2.89924 10.5 2.75 10.3508 2.75 10.1667V8.83333ZM3.08333 11.8333C2.89924 11.8333 2.75 11.9826 2.75 12.1667V12.8333C2.75 13.0174 2.89924 13.1667 3.08333 13.1667H8.41667C8.60076 13.1667 8.75 13.0174 8.75 12.8333V12.1667C8.75 11.9826 8.60076 11.8333 8.41667 11.8333H3.08333Z"
        fill={colors.lighterSecondaryBlue}
      />
    </g>
  </svg>
);
