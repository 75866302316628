import styled from 'styled-components';
import { colors } from 'utils/colors';
import { breakpoints } from 'utils/breakpoints';

export const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 120px;
  gap: 4px;
  padding-right: 5px;
`;

export const FullContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ProjectHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 0 0 10px;
  }
`;
export const ProjectCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  @media screen and (max-width: 615px) {
    display: block;
  }
`;

export const InputFormContainer = styled.form``;
export const InputTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  padding-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
  }
`;
export const InputTextLabel = styled.p`
  color: ${colors.bluePrimary};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  margin-bottom: 5px;
  @media screen and (max-width: ${breakpoints.md}) {
  }
`;
export const InputText = styled.textarea`
  display: flex;
  height: 81px;
  flex-direction: column;
  align-items: center;
  gap: 149px;
  align-self: stretch;

  color: ${colors.lighterSecondaryBlue};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 26.5px; /* 176.667% */
  letter-spacing: 0.1px;

  padding-left: 17px;
  padding-top: 10px;

  @media screen and (max-width: ${breakpoints.md}) {
  }
`;
export const HeaderTitleContainer = styled.div`
  display: flex;
  padding: 5px 0;
  flex-direction: row;
  align-items: flex-start;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 29px;
    align-items: center;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 18px;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 29px;
  }
`;
export const StepsButton = styled.button<{ active?: boolean }>`
  display: flex;
  height: 36px;
  padding: 6px 16px;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  color:  ${(props) => (props.active ? colors.white : colors.bluePrimary)};

  border-radius: 4px;
  border: 1px solid ${colors.bluePrimary};
  background: ${(props) => (props.active ? colors.bluePrimary : 'auto')};
  flex-direction: row;

  @media screen and (max-width: ${breakpoints.sm}) {
    display: flex;
    height: 27px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const ButtonContainer = styled.div``;

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 14px;
`;
export const SubtitleText = styled.p`
  color: ${colors.textGrey};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 161.538% */
  letter-spacing: 0.1px;
`;

export const DateLabel = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;
  margin: 0 35px;
  gap: 4px;
  border-radius: 4px;
  background: rgba(202, 212, 219, 0.6);
  height: 24px;
  width: 110px;
  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
    margin: 0;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 110px;
    margin: 0 0 0 20px;
  }
`;
export const DateLabelMobile = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(202, 212, 219, 0.6);
  height: 24px;
  width: 110px;
  margin-bottom: 29px;

  @media screen and (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;
export const LabelText = styled.p`
  color: ${colors.labelText};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.048px;
  padding-top: 12px;
  white-space: nowrap;
  overflow: hidden;
`;

export const AssigneesContainer = styled.div``;

export const StatusCell = styled.div<{
  rowIsSelected: boolean;
}>`
  width: 100%;
  height: 45px;
  align-content: center;
  align-self: stretch;
  color: ${colors.darkGrey};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.044px;
  display: flex;
  align-items: center;
  padding-left: 4px;
`;
