import { colors } from 'utils/colors';

export const DateArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 19 19"
    fill="none"
  >
    <rect
      x="18.5"
      y="18.5"
      width="18"
      height="18"
      rx="4"
      transform="rotate(-180 18.5 18.5)"
      fill={colors.bluePrimary}
    />
    <path d="M14.5 9L6.25 13.7631L6.25 4.23686L14.5 9Z" fill="white" />
  </svg>
);
