import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';

export const LoadingContainer = styled.div<{ size?: string }>`
  width: ${(props) => (props.size ? `${props.size}px` : 'auto')};
  height: ${(props) => (props.size ? `${props.size}px` : 'auto')};
`;

const LoadingComponent = ({ size }: { size?: string }) => {
  return (
    <LoadingContainer size={size}>
      <Lottie
        animationData={{
          v: '5.9.0',
          fr: 24,
          ip: 0,
          op: 60,
          w: 100,
          h: 100,
          nm: 'Comp 1',
          ddd: 0,
          assets: [],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: 'C1',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [62.663, 62.663], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.047058823529, 0.509803921569, 0.705882352941, 1],
                        ix: 3,
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 5, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0, 0.899540501015, 1, 1], ix: 4 },
                      o: { a: 0, k: 25, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: true,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0.048, -0.302], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.324], y: [1] },
                        o: { x: [0.651], y: [0] },
                        t: 24.5,
                        s: [0],
                      },
                      { t: 48, s: [100] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.34], y: [1] },
                        o: { x: [0.656], y: [0] },
                        t: 2.119,
                        s: [0],
                      },
                      { t: 24.5, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
              ],
              ip: 0,
              op: 60,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: 'C2',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [62.663, 62.663], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.053482515672, 0.568848793179, 0.787224264706, 1],
                        ix: 3,
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 5, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0, 0.899540501015, 1, 1], ix: 4 },
                      o: { a: 0, k: 25, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: true,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0.048, -0.302], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.324], y: [1] },
                        o: { x: [0.651], y: [0] },
                        t: 26.179,
                        s: [0],
                      },
                      { t: 48, s: [100] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.34], y: [1] },
                        o: { x: [0.656], y: [0] },
                        t: 1,
                        s: [0],
                      },
                      { t: 23.380859375, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
              ],
              ip: 0,
              op: 60,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: 'C3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [62.663, 62.663], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.254148027009, 0.700702442842, 0.889920343137, 1],
                        ix: 3,
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 5, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0, 0.899540501015, 1, 1], ix: 4 },
                      o: { a: 0, k: 25, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: true,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0.048, -0.302], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.324], y: [1] },
                        o: { x: [0.651], y: [0] },
                        t: 27.857,
                        s: [0],
                      },
                      { t: 48, s: [100] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.34], y: [1] },
                        o: { x: [0.656], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      { t: 22.26171875, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
              ],
              ip: 0,
              op: 60,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        }}
      />
    </LoadingContainer>
  );
};

export default LoadingComponent;
