export const Computer = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect
        x="1"
        y="1"
        width="20"
        height="17.2549"
        rx="3"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M6.14258 21H15.5711"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M1 15L21 15" stroke="white" strokeWidth="1.5" />
    </g>
  </svg>
);
