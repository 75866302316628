import { colors } from 'utils/colors';

export const EllipsisVertical = ({ height }: { height: string }) => (
  <svg
    width="5"
    height={height}
    viewBox="0 0 5 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="2.00416"
      cy="2"
      rx="2.00416"
      ry="2"
      fill={colors.lighterSecondaryBlue}
    />
    <ellipse
      cx="2.00416"
      cy="10"
      rx="2.00416"
      ry="2"
      fill={colors.lighterSecondaryBlue}
    />
    <ellipse
      cx="2.00416"
      cy="18"
      rx="2.00416"
      ry="2"
      fill={colors.lighterSecondaryBlue}
    />
  </svg>
);
