import styled, { keyframes } from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { colors } from 'utils/colors';
import { spacingWithPx } from 'utils/styles';

export const AddButtonContainer = styled.button<{ padding?: string }>`
  display: flex;
  height: 36px;
  padding: ${(props) => (props.padding ? props.padding : '8px 20px')};
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const ButtonText = styled.div`
  color: ${colors.bluePrimary};
  /* Overline */
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
  text-transform: uppercase;
`;

export const TodayTaskContainer = styled.div<{
  hidePadding?: boolean;
  background?: string;
}>`
  width: 100%;
  padding: ${(props) => (props.hidePadding ? '0' : '0 0 20px 0')};
  background: ${(props) => (props.background ? props.background : '')};
  border-radius: 4px;
`;
export const CalendarText = styled.div<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : colors.red)};
  align-items: center;
  align-content: center;
  display: flex;
  width: 120px;
`;

export const HeaderContainer = styled.div<{ showPadding?: boolean }>`
  display: flex;
  flex: 1 0 0;
  align-items: center;
  padding: ${(props) => (props.showPadding ? '0 0 14px 0' : '0')};
`;
export const ProjectNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 25px;
  flex-direction: column;
`;

export const IconsContainer = styled.div`
  display: flex;
`;
export const CardHeaderContainer = styled.div`
  display: flex;
  padding: 6px 20px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: ${colors.white};
  cursor: pointer;
`;

export const SeeAllContainer = styled.div`
  height: 5px;
  padding: 10px 20px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  justify-content: center;
  color: ${colors.darkGrey};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  cursor: pointer;
`;

export const CardHeaderTitle = styled.div`
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  cursor: pointer;

  color: ${colors.secondaryBlue};

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.15px;
  text-transform: uppercase;
`;

export const AddTaskContainer = styled.div``;
export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  top: 8px;
  position: relative;
  @media screen and (max-width: ${breakpoints.md}) {
    top: 2px;
  }
`;

export const DescriptionText = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 102;
  left: 10px;
  white-space: pre-line;
  text-align: left;

  display: flex;
  width: max-content;
  max-width: 200px;
  word-wrap: break-word;
  height: auto;
  padding: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 5px;
  background: ${colors.white};
  color: ${colors.black};

  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.044px;

  /* Card Shadow */
  box-shadow: 2px 2px 2px 0 ${colors.cardShadow};
  border: 2px solid ${colors.cardShadow};
`;

export const DescriptionContainer = styled.div``;
export const TableCellTextContainer = styled.div`
  padding-left: 6px;
  top: -2px;
  position: relative;
`;

export const CellContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;
export const CellDateContainer = styled.div`
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
`;
export const ClientIconContainer = styled.div`
  width: 11.375px;
  height: 13px;
  flex-shrink: 0;
  margin-right: 4px;
  display: flex;
`;

export const ClientInfoContainer = styled.div`
  display: flex;
`;
export const DescriptionIconContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  border-radius: 50%;
  align-items: center;
  z-index: 10;
  &:hover {
    z-index: 101;
  }
  &:hover ${DescriptionText} {
    visibility: visible;
  }
`;

export const HeaderTitle = styled.p<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : colors.chevronGray)};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.175px;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.md}) {
    padding-left: 14px;
    margin: 0 0 5px 0;
  }
`;
export const HeaderTitleButtonsContainer = styled.div`
  display: flex;
  padding: 8px 0 8px 0;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 4px;
`;

export const HeaderTitleButton = styled.button<{ active: boolean }>`
  display: flex;
  gap: 20px;
  height: 36px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${colors.bluePrimary};
  background: ${(props) =>
    props.active ? colors.bluePrimary : colors.backgroundGray};
  color: ${(props) => (props.active ? colors.white : colors.bluePrimary)};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 65vh;
  }
  to {
    opacity: 1;
    top:  60vh;
  }
`;

export const Spacer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  float: right;

  @media screen and (max-width: ${breakpoints.md}) {
    left: 10px;
  }
`;
export const AddTaskSpacer = styled.div``;

export const UndoContainer = styled.div`
  display: inline-flex;
  //padding: 12px 14px 0 14px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 40px;
  width: 160px;
  background-color: ${colors.tundora};
  color: ${colors.white};
  text-align: center;
  position: absolute;
  z-index: 102;
  left: 10%;
  top: 60vh;
  animation: ${fadeIn} 0.6s ease-in-out;
`;

export const ModalText = styled.p`
  text-decoration: none !important;
  color: ${colors.white};
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.175px;
  opacity: 1 !important;
`;

export const ModalActionText = styled.p`
  padding-left: 5px;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.175px;
  color: ${colors.flagRed};
  cursor: pointer;
`;

export const TaskHeaderContainer = styled.div`
  align-items: center;
  padding-left: 30px;
  display: inline-flex;
  position: relative;
`;

export const RowTaskTableContainer = styled.div<{
  hideSubComponentFromSizeMd?: boolean;
  hideSubComponentFromSizeSm?: boolean;
}>`
  background: ${colors.subRowGray};
  @media screen and (min-width: ${breakpoints.sm}) {
    display: ${({ hideSubComponentFromSizeSm }) =>
      hideSubComponentFromSizeSm ? 'none' : 'auto'};
  }
  @media screen and (min-width: ${breakpoints.md}) {
    display: ${({ hideSubComponentFromSizeMd }) =>
      hideSubComponentFromSizeMd ? 'none' : 'auto'};
  }
`;

export const TodayDate = styled.div`
  color: ${colors.textGrey};
  padding-left: 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
  letter-spacing: 0.1px;
  top: 8px;
  display: flex;
  position: relative;

  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const TaskSearchInput = styled.input<{ active?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  width: 100%;
  border: none;

  color: ${colors.chevronGray};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;

  @media screen and (max-width: ${breakpoints.sm}) {
    background-color: ${colors.transparent};
    display: ${({ active }) => (active ? 'auto' : 'none')};
  }
`;
export const InputContainer = styled.div<{ active?: boolean; margin?: string }>`
  width: 100%;
  max-width: 500px;
  height: 48px;
  padding: 12px 40px 12px 18px;
  align-items: center;
  gap: 12px;
  flex: 1;
  flex-shrink: 0;
  display: flex;
  margin: ${({ margin }) => (margin ? margin : 'none')};

  border-radius: 5px;
  border: 1px solid ${colors.textFieldBorderGrey};
  background: ${colors.white};

  @media screen and (max-width: ${breakpoints.sm}) {
    width: ${({ active }) => (active ? '100%' : '0')};
    flex: ${({ active }) => (active ? '1' : '0')};
    background-color: ${({ active }) =>
      active ? colors.white : colors.transparent};
    display: flex;
    justify-content: space-between;
    border: none;
  }
`;

export const GlassContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
`;
export const HeadingContainer = styled.div`
  flex: 1;
  max-width: 223px;
`;

export const TableContainer = styled.div<{
  maxHeight?: string;
}>`
  width: 100%;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '230px')};
  //TODO: - When committing with the client UI verify that this overflow scroll and the padding is needed is not needed
  //overflow: scroll;s
  //padding-right: 16px;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

export const ProjectHeaderContainer = styled.div`
  align-items: center;
  padding-left: 30px;
  display: inline-flex;
  position: relative;
`;

const collapse = keyframes`
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
`;

const expand = keyframes`
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
`;
export const AccordionContainer = styled.div<{
  isOpen?: boolean;
  padding?: string;
}>`
  height: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  padding: ${({ padding }) => (padding ? padding : 'auto')};
  width: 100%;
  overflow: hidden;
  animation: ${({ isOpen }) => (isOpen ? expand : collapse)} 0.4s ease-in-out;
`;

export const TaskTableDisplayContainer = styled.div<{
  show?: boolean;
  flexDirection?: string;
  alignItems?: string;
  margin?: string;
  gap?: boolean;
  width?: string;
}>`
  display: ${({ show = true }) => (show ? 'flex' : 'none')};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems ?? 'unset'};
  margin: ${({ margin }) =>
    margin ?? `${spacingWithPx.gap} ${spacingWithPx.gap}`};
  gap: ${({ gap }) => (gap ? spacingWithPx.gap : '0')};
  width: ${({ width }) => width ?? '100%'};
  max-width: ${breakpoints.widescreen};

  @media screen and (min-width: ${breakpoints.lg}) {
    width: ${({ width }) => width ?? '90%'};
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 0 0 20px 0;
  }
`;
