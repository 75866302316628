import {
  customIndicatorContainerStyles,
  customControlStyles,
  customOptionStyles,
  placeholderContainerStyles,
  singleValueStyles,
} from 'pages/request-account/components/styled';
import React, { SetStateAction } from 'react';
import Select, { SingleValue } from 'react-select';
import { DropDownOptionType } from 'pages/clients/api/get-location';
import { RequestInformationType } from 'pages/request-account';

export function DropDownMenu({
  options,
  selectedData,
  inputName,
  setFormData,
  placeholder,
}: {
  options: DropDownOptionType[];
  selectedData?: string;
  inputName: string;
  setFormData: React.Dispatch<SetStateAction<RequestInformationType>>;
  placeholder?: string;
}) {
  const dropMenuChangeHandler = (selectedOption: string) => {
    const name = inputName;
    const value = selectedOption;
    setFormData((prevFormData: RequestInformationType) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        name={inputName}
        onChange={(e: SingleValue<{ value: string; label: string }>) =>
          dropMenuChangeHandler(e!.value)
        }
        isSearchable
        styles={{
          control: (previous) => {
            return {
              ...previous,
              ...customControlStyles,
            };
          },
          option: (previous) => {
            return {
              ...previous,
              ...customOptionStyles,
            };
          },
          dropdownIndicator: (previous) => {
            return {
              ...previous,
              ...customIndicatorContainerStyles,
            };
          },
          placeholder: (previous) => {
            return {
              ...previous,
              ...placeholderContainerStyles,
            };
          },
          singleValue: (previous) => {
            return {
              ...previous,
              ...singleValueStyles,
            };
          },
        }}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...(selectedData
          ? {
              defaultValue: {
                value: selectedData ? selectedData : placeholder!,
                label: selectedData ? selectedData : placeholder!,
              },
            }
          : {})}
      />
    </>
  );
}
