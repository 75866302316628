import styled from 'styled-components';
import { colors } from 'utils/colors';

const Meter = styled.div`
  height: 10px;
  position: relative;
  background: ${colors.statusBarGrey};
  border-radius: 25px;
  // box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  > span {
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: ${colors.secondaryBlue};
    background-image: linear-gradient(
      center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%
    );
    // box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    //   inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  }
`;

type Props = {
  progress: number;
};

export const StatusBar = ({ progress }: Props) => {
  return (
    <Meter>
      <span style={{ width: `${progress}%` }} />
    </Meter>
  );
};
