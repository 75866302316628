import React, { useEffect, useState } from 'react';
import { PageWrapper } from 'components';
import { Container } from 'pages/dashboard/styled';
import { spacingWithPx } from 'utils/styles';
import {
  HeaderTitle,
  ProjectNameContainer,
  TaskTableDisplayContainer,
  TodayTaskContainer,
} from 'pages/task-manager/components/styled';
import { useTranslation } from 'react-i18next';
import { ProjectTable } from 'pages/projects/components/project-table';
import {
  ButtonsContainer,
  DateLabel,
  DateLabelMobile,
  HeaderTitleContainer,
  LabelText,
  ProjectCardContainer,
  ProjectHeaderContainer,
  SubtitleContainer,
  SubtitleText,
} from 'pages/projects/components/styled';
import { ProjectLogoDefault } from 'icons/project-logo-default';
import { LabelCalendarIcon } from 'icons/label-calendar-icon';
import { epochToDate } from 'utils/epoch-to-date';
import { useNavigate } from 'react-router-dom';
import { ProjectItemOverview } from 'pages/projects/components/project-item-overview';
import { BackIcon } from 'icons/back-icon';
import { Box, Button } from '@mui/material';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { ProjectComments } from './project-comments';
import { useProjectsDataContext } from '../../../react-query-toolkit/state/projects-context';
import LoadingComponent from 'components/loading-icon-component';
import { useParams } from 'react-router-dom';
import { useClientDataContext } from '../../../react-query-toolkit/state/client-context';
import { findClientByIdInSystemPLResponse } from 'utils/System-pl-data-util';
import { NewClientDataType } from 'pages/clients/api/get-clients';

const ProjectItem = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [commentsOpen, setCommentsOpen] = useState(false);
  const [stepComment, setStepComment] = useState('Set-up');
  const [formStep, setFormStep] = useState(1);
  const [stepActive, setStepActive] = useState(true);

  const { setCurrentProjectId, handleGetProject, isGetProjectsLoading } =
    useProjectsDataContext();
  const { handleGetClients } = useClientDataContext();

  const { projectId } = useParams();
  useEffect(() => {
    if (projectId) {
      setCurrentProjectId(projectId);
    }
  }, [projectId, setCurrentProjectId]); // Ensure `setCurrentProjectId` is stable or memoized

  const rawProjectData = handleGetProject() || {};

  //Getting the client Name and Profile picture
  const rawClientData = handleGetClients() || [];

  const clientData: NewClientDataType | null = rawClientData
    ? findClientByIdInSystemPLResponse(
        rawClientData,
        rawProjectData.projectClientId,
      )
    : null;

  const clientName = clientData?.clientName ?? 'Unknown Client';
  const toggleCommentsDrawer = () => {
    setCommentsOpen(!commentsOpen);
  };

  const projectDueDate = epochToDate(
    rawProjectData.projectDueDate,
    'MMLongDD,YYYY',
  );

  let componentToRender;
  switch (formStep) {
    case 1:
      componentToRender = (
        <ProjectTable
          // projectStepData={projectStepsData}
          setCommentsOpen={setCommentsOpen}
          setStepComment={setStepComment}
        />
      );
      break;
    case 2:
      componentToRender = <ProjectItemOverview />;
      break;
    default:
      componentToRender = (
        <ProjectTable
          // projectStepData={projectStepsData}
          setCommentsOpen={setCommentsOpen}
          setStepComment={setStepComment}
        />
      );
  }

  return (
    <>
      {isGetProjectsLoading || !rawProjectData ? (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box width={'100px'} height={'100px'}>
            <LoadingComponent size={'100'} />
          </Box>
        </Box>
      ) : rawProjectData.projectSteps ? (
        <>
          <ProjectComments
            open={commentsOpen}
            stepComment={stepComment}
            toggleDrawer={toggleCommentsDrawer}
          />
          <PageWrapper>
            <Container alignItems="center" width="100%" margin="0">
              <Container maxWidth={'906px'} width="100%" margin="0">
                <Container width="100%" margin="0">
                  <PageTitle title={translate?.('headerProjects')} />
                </Container>
                <Container
                  width="100%"
                  flexDirection="row"
                  margin={`${spacingWithPx.xxl} 0 0 0`}
                >
                  <TaskTableDisplayContainer width={'100%'} margin={'0'}>
                    <TodayTaskContainer>
                      <ProjectHeaderContainer>
                        <ProjectCardContainer>
                          <HeaderTitleContainer>
                            <ProjectLogoDefault />
                            <ProjectNameContainer>
                              <HeaderTitle>{`${rawProjectData.projectPeriod} ${rawProjectData.projectType} ${rawProjectData.projectJurisdiction}`}</HeaderTitle>{' '}
                              <SubtitleContainer>
                                <SubtitleText>{clientName}</SubtitleText>
                              </SubtitleContainer>
                            </ProjectNameContainer>
                            <DateLabel>
                              <LabelCalendarIcon />
                              <LabelText>{projectDueDate}</LabelText>
                            </DateLabel>
                          </HeaderTitleContainer>
                          <ButtonsContainer>
                            <Button
                              variant="outlined"
                              onClick={() => navigate('/my-projects')}
                              startIcon={<BackIcon />}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              Back
                            </Button>
                            <Button
                              variant={stepActive ? 'contained' : 'outlined'}
                              onClick={() => {
                                setFormStep(1);
                                setStepActive(!stepActive);
                              }}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              Steps
                            </Button>
                            <Button
                              variant={!stepActive ? 'contained' : 'outlined'}
                              onClick={() => {
                                setFormStep(2);
                                setStepActive(!stepActive);
                              }}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              OverView
                            </Button>
                          </ButtonsContainer>
                          <DateLabelMobile>
                            <LabelCalendarIcon />
                            <LabelText>{projectDueDate}</LabelText>
                          </DateLabelMobile>
                        </ProjectCardContainer>
                      </ProjectHeaderContainer>
                      {componentToRender}
                    </TodayTaskContainer>
                  </TaskTableDisplayContainer>
                </Container>
              </Container>
            </Container>
          </PageWrapper>
        </>
      ) : (
        <>ERROR</>
      )}
    </>
  );
};

export default ProjectItem;
