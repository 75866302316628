import { colors } from 'utils/colors';

export const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M20.4286 15.4706V20.4291C20.4286 20.9217 20.2329 21.394 19.8846 21.7423C19.5364 22.0906 19.064 22.2863 18.5714 22.2863H5.57144C5.07889 22.2863 4.60652 22.0906 4.25824 21.7423C3.90996 21.394 3.71429 20.9217 3.71429 20.4291V7.42913C3.71429 6.93658 3.90996 6.46421 4.25824 6.11593C4.60652 5.76765 5.07889 5.57199 5.57144 5.57199H10.53M18.5714 3.71484L22.2857 7.42913L13 16.7148H9.28572V13.0006L18.5714 3.71484Z"
      stroke={colors.lighterSecondaryBlue}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
