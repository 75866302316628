import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectsDataContext } from '../../../react-query-toolkit/state/projects-context';
import { useLanguageContext } from 'locales/context/LanguageContext';
import { Box, Button } from '@mui/material';
import {
  InputTextContainer,
  InputTextLabel,
  InputText,
} from 'pages/projects/components/styled';
import { useParams } from 'react-router-dom';
import { NewProjectStep } from '../../../react-query-toolkit/state/types';

export type LanguageText = { language: string; text: string };

export type NewProjectDataType = {
  projectId: string;
  projectClientId: string;
  projectJurisdiction: string;
  projectStatus: string;
  projectMandate: string;
  projectSteps: NewProjectStep[];
  projectCurrentStep: string;
  projectAssignees: string[];
  projectDueDate: string | Date;
  successCriteria: LanguageText[];
  applicableStandard: LanguageText[];
  intendedUse: LanguageText[];
  projectType: string;
  projectPeriod: string;
  nextProject: string;
  accountManager: string;
  previousProject: string;
  operational: string;
};

export const ProjectItemOverview: React.FC = () => {
  const { t: translate } = useTranslation();
  const { language } = useLanguageContext();

  const [formData, setFormData] = useState<{
    applicableStandard: string;
    successCriteria: string;
    intendedUse: string;
  }>({
    applicableStandard: '',
    successCriteria: '',
    intendedUse: '',
  });

  const [needsReset, setNeedsReset] = useState<boolean>(true); // Flag to control when formData should reset based on new data
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {
    handleUpdateProjectText,
    handleGetProject,
    isUpdateProjectLoading,
    resetUpdateProjectQueryValues,
  } = useProjectsDataContext();

  const rawProjectData: NewProjectDataType =
    handleGetProject() || ({} as NewProjectDataType);

  const getTextByLanguage = (data: LanguageText[], lang: string): string => {
    const foundText = data.find((item) => item.language === lang);
    return foundText ? foundText.text : ''; // Return empty string if no text is found for the language
  };

  // Only update formData when needsReset is true
  useEffect(() => {
    if (needsReset) {
      setFormData({
        applicableStandard: getTextByLanguage(
          rawProjectData.applicableStandard || [],
          language.toLowerCase(),
        ),
        successCriteria: getTextByLanguage(
          rawProjectData.successCriteria || [],
          language.toLowerCase(),
        ),
        intendedUse: getTextByLanguage(
          rawProjectData.intendedUse || [],
          language.toLowerCase(),
        ),
      });
      setNeedsReset(false); // Ensure formData doesn't reset on every render
    }
  }, [rawProjectData, language, needsReset]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setIsEditing(true);
  };

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      handleUpdateProjectText(
        language.toLowerCase(),
        formData.applicableStandard,
        formData.intendedUse,
        formData.successCriteria,
      );
      resetUpdateProjectQueryValues();
      setIsEditing(false);
      setNeedsReset(false); // Keep formData as it is after a successful save
    } catch (error) {
      console.error('Failed to save:', error);
    }
  };

  // Reset form data when language changes and no active edits are happening
  useEffect(() => {
    if (!isEditing) {
      setNeedsReset(true);
    }
  }, [language]);

  return (
    <Box component="form" onSubmit={onSubmitForm} sx={{ mt: 2 }}>
      <InputTextContainer>
        <InputTextLabel>{translate?.('successCriteriaLabel')}</InputTextLabel>
        <InputText
          id="successCriteriaText"
          name="successCriteria"
          rows={2}
          cols={35}
          placeholder={translate?.('taskFormDescription')}
          value={formData.successCriteria}
          onChange={handleChange}
        />
      </InputTextContainer>
      <InputTextContainer>
        <InputTextLabel>
          {translate?.('applicableStandardLabel')}
        </InputTextLabel>
        <InputText
          id="applicableStandardText"
          name="applicableStandard"
          rows={2}
          cols={35}
          placeholder={translate?.('applicableStandardLabel')}
          value={formData.applicableStandard}
          onChange={handleChange}
        />
      </InputTextContainer>
      <InputTextContainer>
        <InputTextLabel>{translate?.('intendedUseLabel')}</InputTextLabel>
        <InputText
          id="intendedUseText"
          name="intendedUse"
          rows={2}
          cols={35}
          placeholder={translate?.('intendedUseLabel')}
          value={formData.intendedUse}
          onChange={handleChange}
        />
      </InputTextContainer>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isUpdateProjectLoading}
      >
        {translate?.('projectOverviewSave')}
      </Button>
    </Box>
  );
};
