export const HamburgerMenu = () => (
  <>
    <svg
      viewBox="20 0 80 60"
      width="30"
      height="24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="10"></rect>
      <rect y="24" width="100" height="10"></rect>
      <rect y="48" width="100" height="10"></rect>
    </svg>
  </>
);
