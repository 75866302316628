import React, { ReactNode } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface NotificationCardPropType {
  activeNumber: string;
  cardTitle: string;
  cardIcon: ReactNode;
  pathLink: string;
}
export default function NotificationCard(props: NotificationCardPropType) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(props.pathLink)}
      minWidth={'content'}
      height={'100%'}
    >
      <Paper elevation={1} sx={{ height: '100%' }}>
        <Box py={7} px={6}>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            {props.cardIcon}
            {/*Todo - Change the color to the new palette color*/}
            <Typography variant="h5" color={'chevronGray'}>
              {props.activeNumber}
            </Typography>
          </Box>
          {/*Todo - Change the color to the new palette color*/}
          <Typography color={'darkGrey'} variant="body2">
            {props.cardTitle}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
