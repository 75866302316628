import { yupResolver } from '@hookform/resolvers/yup';
import { Headline, TextField } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { colors } from 'utils/styles';
import * as yup from 'yup';
import React, { useEffect } from 'react';
import { Container } from 'pages/dashboard/styled';
import { useTranslation } from 'react-i18next';
import {
  BottomContainer,
  ButtonWrapper,
  CenterWrapper,
  GreenButton,
  PrevButton,
  RowContainer,
  RowPasswordItem,
  RowsPasswordContainer,
} from 'pages/request-account/components/styled';
import { RequestInformationType } from 'pages/request-account';
import { passwordStrengthEvaluator } from 'utils/validations';

export interface createPasswordProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
  prevStep: () => void;
}

export const CreatePassword = (props: createPasswordProps) => {
  const { requestedInformation, setRequestedInformation, nextStep, prevStep } =
    props;
  const translation = useTranslation();
  const { t: translate } = translation;

  const passwordSchema = yup.object().shape({
    password: yup
      .string()
      .required(translate?.('createPasswordErrorMessageRequired'))
      .min(8, translate?.('createPasswordErrorMessageMin8'))
      .matches(
        /(?=.*?[0-9])/,
        translate?.('createPasswordErrorMessageAtLeast1Number'),
      )
      .matches(
        /(?=.*?[A-Z])/,
        translate?.('createPasswordErrorMessageAtLeast1Uppercase'),
      ),
  });

  const {
    control,
    trigger,
    formState: { isValid, errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(passwordSchema),
  });

  useEffect(() => {
    setValue('password', requestedInformation.password);
  }, [requestedInformation]);

  const handleNext = async () => {
    const isStepValid = await trigger();
    const updatedPasswordStrength = passwordStrengthEvaluator(
      requestedInformation.password,
    );
    if (
      isStepValid &&
      updatedPasswordStrength !== 0 &&
      updatedPasswordStrength !== 1 &&
      requestedInformation.password !== ''
    )
      nextStep();
  };

  const checkFieldValidity = async (fieldName: 'password') => {
    if (requestedInformation.password === '') await trigger(fieldName);
  };

  return (
    <CenterWrapper>
      <Container width="100%" margin="62px 0 0 0">
        <Headline fontSize={34} fontSizeSmall={24} color="secondaryBlue">
          {translate?.('createPasswordTitle')}
        </Headline>
      </Container>
      <Container width="100%" flexDirection="column" margin={`0 0 0 0`}>
        <RowsPasswordContainer>
          <RowContainer>
            <RowPasswordItem>
              <Controller
                name="password"
                control={control}
                defaultValue={requestedInformation.password}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => {
                      checkFieldValidity('password');
                    }}
                    onChange={(e) => {
                      setRequestedInformation({
                        ...requestedInformation,
                        password: e.target.value,
                      });
                      field.onChange(e);
                      clearErrors();
                    }}
                    label={translate?.('createPasswordLabel')}
                    placeholder={translate?.('createPasswordPlaceHolder')}
                    type="password"
                    status={
                      errors.password
                        ? 'error'
                        : isValid
                          ? 'success'
                          : undefined
                    }
                    errorMessage={errors.password && errors.password.message}
                    themeColor={colors.secondaryBlue}
                    showPasswordStrengthValue
                    value={requestedInformation.password}
                  />
                )}
              />
            </RowPasswordItem>
            <BottomContainer>
              <ButtonWrapper>
                <PrevButton onClick={() => prevStep()}>
                  {translate?.('backButton')}
                </PrevButton>
                <GreenButton onClick={() => handleNext()}>
                  {translate?.('nextButton')}
                </GreenButton>
              </ButtonWrapper>
            </BottomContainer>
          </RowContainer>
        </RowsPasswordContainer>
      </Container>
    </CenterWrapper>
  );
};
