import React, { useEffect, useState } from 'react';
import { HelpIcon, UserIcon } from 'icons';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomButton, useNavAndSideBarContext } from 'components';
import useAuth from 'configs/AuthContext';
import { GetUserFromEmail } from 'utils/get-user-from-email';
import { getLocationLabel } from 'components/nav-and-sidebar/sidebar/utils';
import { defaultTransitionTimeAndStyle } from 'utils/styles';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';
// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { AssignmentIcon } from 'icons/assignment-icon';

const tabs = {
  Dashboard: DashboardIcon,
  'My Clients': PeopleIcon,
  'My Projects': AssignmentIcon,
  Calendar: EventIcon,
  Notifications: NotificationsIcon,
  'Task Manager': TaskAltIcon,
};

type Props = {
  showSideBar: boolean;
};

export const Sidebar: React.FC<Props> = ({ showSideBar }) => {
  const { setShowSideBar } = useNavAndSideBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const theme: Theme = useTheme();

  const currentUserEmail =
    auth.user?.getSignInUserSession()?.getIdToken().payload.email || '';

  const userName = currentUserEmail
    ? GetUserFromEmail(currentUserEmail)
    : 'Guest';
  const userRole = 'Admin'; // Assuming the role is fixed

  const sidebarStyles = {
    drawer: {
      transform: {
        md: 'none',
        sm: `${showSideBar ? 'translate(0, 0)' : 'translate(-100%, 0)'}`,
        lg: 'none',
      },
      position: { sm: 'fixed', md: 'static', lg: 'static' },
      display: 'flex',
      zIndex: '2',
      height: '100%',
      width: { lg: '237px', sm: '84px' },
      flexShrink: 0,
      transition: `transform ${defaultTransitionTimeAndStyle}`,
      '& .MuiDrawer-paper': {
        width: { lg: '237px', sm: '84px' },
        transition: `transform ${defaultTransitionTimeAndStyle}`,
        transform: {
          md: 'none',
          sm: `${showSideBar ? 'translate(0, 0)' : 'translate(-100%, 0)'}`,
          lg: 'none',
        },
        boxSizing: 'border-box',
      },
    },
    list: {
      gap: 4,
      padding: () => {
        const availableHeight = window.innerHeight; // Or a specific container height
        return availableHeight > 850 ? '50px 20px 0 20px' : '20px 20px 0 20px'; //This is a dynamic padding to match Svend screen size
      },
      justifyContent: { md: 'center', lg: 'auto' },
    },
    listItemButton: {
      gap: 3,
      py: 2,
      borderRadius: 2,
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      // This is a workaround to match the design
      width: { xs: '110%', lg: '100%' },
      left: { xs: '-3px', lg: '0px' },
      top: { xs: '10px' },
      position: 'relative',
    },
    listItemIcon: { minWidth: theme.spacing(7), justifyContent: 'center' },
    listItem: {
      py: 2,
      px: 0,
      '& .MuiButtonBase-root': {
        py: 3,
        justifyContent: 'center',
      },
    },
    listItemProfile: {
      '& .MuiButtonBase-root': {
        py: 3,
        justifyContent: { sm: 'center', lg: 'start' },
      },
    },
  };

  const { t: translate } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('');
  useEffect(() => {
    const currentTab = getLocationLabel(location.pathname);
    setSelectedTab(currentTab);
  }, [location.pathname]);

  const routeChange = (key: string) => {
    const path = `/${key.replace(/\s+/g, '-').toLowerCase()}`;
    navigate(path);
    setShowSideBar(false);
    setSelectedTab(key); // Update the selected tab state when navigating
  };

  return (
    <>
      {auth.user && (
        <Drawer variant="permanent" sx={sidebarStyles.drawer}>
          <Toolbar />
          <Box height={'100%'} sx={{ overflow: 'auto' }}>
            <Divider />
            <List sx={sidebarStyles.list}>
              <ListItem
                disableGutters
                disablePadding
                alignItems="center"
                sx={sidebarStyles.listItemProfile}
              >
                <ListItemButton
                  alignItems="center"
                  sx={sidebarStyles.listItemButton}
                >
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    <UserIcon color={theme.palette.primary.dark} />
                  </ListItemIcon>
                  <Box
                    sx={{ display: { xs: 'auto', sm: 'none', lg: 'inline' } }}
                  >
                    <Typography
                      noWrap
                      display="inline"
                      variant="subtitle2"
                      color={theme.palette.primary.dark}
                    >
                      {userName}
                    </Typography>
                    <Typography
                      noWrap
                      color={theme.palette.text.primary}
                      variant="body2"
                    >
                      {userRole}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
              {Object.entries(tabs).map(([key, Icon], index) => {
                // const isSelected = key === getLocationLabel(location.pathname);
                const isSelected = key === selectedTab;
                return (
                  <ListItem
                    disableGutters
                    key={index}
                    onClick={() => routeChange(key)}
                    sx={sidebarStyles.listItem}
                  >
                    <ListItemButton
                      selected={isSelected}
                      sx={{
                        gap: 3,
                        py: 2,
                        borderRadius: 2,
                        justifyContent: 'center',
                        '&:hover': {
                          color: theme.palette.secondary.light,
                        },
                        color: isSelected ? theme.palette.bg.main : 'none',
                      }}
                    >
                      <ListItemIcon sx={sidebarStyles.listItemIcon}>
                        <Icon
                          sx={{
                            '&:hover': {
                              color: theme.palette.primary.dark,
                            },
                            color: isSelected
                              ? theme.palette.primary.dark
                              : theme.palette.text.secondary,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant="body1">{key}</Typography>}
                        sx={{
                          display: { xs: 'auto', sm: 'none', lg: 'inline' },
                          '&:hover': {
                            color: theme.palette.text.secondary,
                          },
                          color: isSelected
                            ? theme.palette.primary.dark
                            : theme.palette.text.secondary,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
              <Box sx={sidebarStyles.buttonContainer}>
                <CustomButton
                  clickHandler={() => navigate('/faq')}
                  variant="outlined"
                >
                  <HelpIcon />
                  <Typography
                    p={'0 0 0 8px'} //todo-cg:tell carlos about this and display below
                    display={{ sx: 'none', sm: 'none', md: 'none', lg: 'flex' }}
                  >
                    {translate?.('headerHelpButton')}
                  </Typography>
                </CustomButton>
              </Box>
            </List>
          </Box>
        </Drawer>
      )}
    </>
  );
};
