import React, {
  Dispatch,
  FormEventHandler,
  ReactNode,
  SetStateAction,
} from 'react';
import { ItemTopModalContainer } from './styled';
import { PopupModalOverlay } from 'components/top-modal/popup-modal-overlay';
import { StyledForm } from 'components/modal/styled';

export type WithModalProps = {
  children: ReactNode;
  isOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  width?: string;
  zIndexValue?: number;
  submitData?: FormEventHandler<HTMLFormElement>;
};

export function WithTopModal(props: WithModalProps) {
  return (
    <PopupModalOverlay
      zIndexValue={props.zIndexValue}
      modalIsOpen={props.isOpen}
      toggleModal={props.toggleModal}
    >
      <StyledForm onSubmit={props.submitData}>
        <ItemTopModalContainer> {props.children}</ItemTopModalContainer>
      </StyledForm>
    </PopupModalOverlay>
  );
}
