export function getLocationLabel(location: string) {
  switch (location) {
    case '/':
    case '/dashboard':
      return 'Dashboard';
    case '/my-clients':
      return 'My Clients';
    case '/my-projects':
      return 'My Projects';
    case '/calendar':
      return 'Calendar';
    case '/notifications':
      return 'Notifications';
    case '/task-manager':
      return 'Task Manager';
    case '/help':
      return 'Help';
    default:
      return 'Unknown Location'; // or handle other cases as needed
  }
}
