import { Container } from 'pages/dashboard/styled';
import { TableTopContainer } from '../clients/components/clients-table/styled';
import { Heading } from 'components';
import { colors, spacingWithPx } from 'utils/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TodayTasks } from 'pages/task-manager/components/TodayTasks';
import { TaskModalForm } from 'pages/task-manager/components/popup-modal/task-modal-form';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import {
  HeadingContainer,
  TaskTableDisplayContainer,
} from 'pages/task-manager/components/styled';
import { UpcomingTasks } from 'pages/task-manager/components/upcoming-tasks';
import { OverDueTasks } from 'pages/task-manager/components/overdue-tasks';
import { CompletedTasks } from 'pages/task-manager/components/completed-tasks';
import { TaskSearchBar } from 'pages/task-manager/components/task-search-bar';
import { useTaskDataContext } from '../../react-query-toolkit/state/tasks-context';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';

export const TaskManager = () => {
  const [selectedData, setSelectedData] = useState<NewTaskDataType | null>(
    null,
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const translation = useTranslation();
  const { t: translate } = translation;
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [completedTabActive, setCompletedTabActive] = useState(false);
  const { taskIdSelected, setTaskIdSelected } = useTaskDataContext();

  return (
    <>
      <PageWrapper>
        <Container width="100%" margin="0">
          <TaskTableDisplayContainer margin={'62px 0 0 0'}>
            <Container
              alignItems={'center'}
              justifyContent={'space-between'}
              width="100%"
              margin="0"
              flexDirection={'row'}
            >
              <HeadingContainer>
                <Heading level={4} color={colors.bluePrimary}>
                  {translate?.('headerTaskManager')}
                </Heading>
              </HeadingContainer>
              <TaskSearchBar
                onClick={() => {
                  setTaskIdSelected('');
                  setGlobalFilter('');
                }}
                value={taskIdSelected !== '' ? '' : globalFilter ?? ''}
                onChange={(value) =>
                  setGlobalFilter(
                    taskIdSelected !== '' ? taskIdSelected : String(value),
                  )
                }
                placeholder="Search..."
              />
            </Container>
          </TaskTableDisplayContainer>
          <Container width="100%" flexDirection="row" margin={`0`}>
            <TaskTableDisplayContainer margin={`${spacingWithPx.xxl} 0 0 0`}>
              <TableTopContainer></TableTopContainer>
              {completedTabActive ? (
                <CompletedTasks
                  setModalType={setModalType}
                  setSelectedData={setSelectedData}
                  setModalIsOpen={setModalIsOpen}
                  setCompletedTabActive={setCompletedTabActive}
                  completedTabActive={completedTabActive}
                  setGlobalFilter={setGlobalFilter}
                  globalFilter={globalFilter}
                />
              ) : (
                <>
                  <TodayTasks
                    setModalType={setModalType}
                    setSelectedData={setSelectedData}
                    setModalIsOpen={setModalIsOpen}
                    setCompletedTabActive={setCompletedTabActive}
                    completedTabActive={completedTabActive}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                  />
                  <UpcomingTasks
                    setModalType={setModalType}
                    setSelectedData={setSelectedData}
                    setModalIsOpen={setModalIsOpen}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                  />
                  <OverDueTasks
                    setModalIsOpen={setModalIsOpen}
                    setSelectedData={setSelectedData}
                    setModalType={setModalType}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                  />
                </>
              )}
            </TaskTableDisplayContainer>
          </Container>
        </Container>
      </PageWrapper>
      {selectedData ? (
        <TaskModalForm
          selectedData={selectedData}
          isOpen={modalIsOpen}
          toggleModal={setModalIsOpen}
          modalType={modalType}
        />
      ) : null}
    </>
  );
};
