import React, { useEffect, useState } from 'react';
import { Container } from 'pages/dashboard/styled';
import { Headline } from 'components';
import { useTranslation } from 'react-i18next';
import {
  ButtonServiceContainer,
  ButtonWrapper,
  CenterWrapper,
  CheckboxContainer,
  CommentBox,
  CommentsContainer,
  CommentTitle,
  GreenButton,
  InputRow,
  PrevButton,
  ServiceContainer,
  StyledCheckboxContainer,
  StyledSpan,
  StyledTextarea,
  SubTitle,
} from 'pages/request-account/components/styled';
import { RequestInformationType } from 'pages/request-account';
import RadioButton from 'pages/request-account/components/tell-us-more/radio-button';

export interface ClientAccountInfoProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
  prevStep: () => void;
}

export function ServiceProfile(props: ClientAccountInfoProps): JSX.Element {
  const { requestedInformation, setRequestedInformation, nextStep, prevStep } =
    props;
  const [error, setError] = useState(false);
  const translation = useTranslation();
  const { t: translate } = translation;

  const serviceArr = [
    translate?.('serviceArrItem1'),
    translate?.('serviceArrItem2'),
    translate?.('serviceArrItem3'),
    translate?.('serviceArrItem4'),
    translate?.('serviceArrItem5'),
  ];

  const [, setRadio] = useState(new Map<string, { val: string; id: string }>());

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRadio((radio) =>
      new Map(radio).set(e.target.name, {
        val: e.target.value,
        id: e.target.id,
      }),
    );
    setRequestedInformation({
      ...requestedInformation,
      service: serviceArr[Number(e.target.value)],
    });
  }

  function onChangeHandler(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setRequestedInformation({
      ...requestedInformation,
      comment: e.target.value,
    });
  }

  const checkedHandler = (checkboxIndex: number) => {
    const testIndex = serviceArr.indexOf(requestedInformation.service);
    return testIndex === checkboxIndex;
  };

  const handleNext = async () => {
    if (requestedInformation.service !== '') {
      nextStep();
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setError(false);
  }, [requestedInformation]);

  return (
    <CenterWrapper>
      <Container width="100%" margin="62px 0 0 0">
        <Headline fontSize={34} fontSizeSmall={24} color="secondaryBlue">
          {translate?.('serviceProfileTitle')}
        </Headline>
      </Container>
      <Container flexDirection={'column'}>
        <ServiceContainer>
          <InputRow>
            <CheckboxContainer>
              <SubTitle>{translate?.('serviceProfileSubTitle')}</SubTitle>
              {serviceArr.map((item, index) => (
                <StyledCheckboxContainer key={`${index}+a`}>
                  <RadioButton
                    id={`${index}+typeId`}
                    key={`${index}+b`}
                    label={item}
                    name="service"
                    value={index}
                    onChange={handleChange}
                    checked={checkedHandler(index)}
                  />
                </StyledCheckboxContainer>
              ))}
            </CheckboxContainer>
          </InputRow>
          <CommentsContainer>
            <CommentBox>
              <CommentTitle>
                {translate?.('serviceProfileCommentTitle')}
              </CommentTitle>
              <StyledTextarea
                value={requestedInformation.comment}
                onChange={onChangeHandler}
              />
            </CommentBox>
          </CommentsContainer>
        </ServiceContainer>
        <ButtonServiceContainer>
          <>{error && <StyledSpan>Selection is required.</StyledSpan>}</>
          <ButtonWrapper>
            <PrevButton onClick={() => prevStep()}>
              {translate?.('backButton')}
            </PrevButton>
            <GreenButton onClick={() => handleNext()}>
              {translate?.('nextButton')}
            </GreenButton>
          </ButtonWrapper>
        </ButtonServiceContainer>
      </Container>
    </CenterWrapper>
  );
}
