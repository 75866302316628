import { colors } from 'utils/colors';

export const ActiveIcon = ({ active }: { active?: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
  >
    <circle
      cx="3.6665"
      cy="3"
      r="3"
      fill={active ? colors.greenOnline : colors.red}
    />
  </svg>
);
