import styled from 'styled-components';
import { colors } from 'utils/colors';

const Line = styled.div<{ color?: keyof typeof colors }>`
  background-color: ${({ color }) => colors[color ?? 'bluePrimary']};
  height: 1px;
  width: 100%;
  opacity: 0.25;
`;

type Props = {
  color?: keyof typeof colors;
};

export const Divider = ({ color }: Props) => {
  return <Line color={color} />;
};
