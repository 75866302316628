import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { colors } from 'utils/colors';
export const MobileCalendarContainer = styled.div`
  display: none;

  @media screen and (max-width: ${breakpoints.md}) {
    display: inherit;
  }
`;
export const CalendarCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 2px 2px 2px 0 ${colors.cardShadow};
`;

export const ModalItemContainer = styled.div<{ width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: ${({ width }) => width || 'fit-content'};
  transform: translate(-50%, -50%);
  background-color: ${colors.white}; /* Optional: Set a background color */
  padding: 20px; /* Optional: Add some padding */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
`;
