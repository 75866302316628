import styled from 'styled-components';
import { colors } from 'utils/colors';
import { CSSProperties } from 'react';
import { breakpoints } from 'utils/breakpoints';
import { spacingWithPx } from 'utils/styles';

export const RowsContainer = styled.div`
  display: block;
  max-width: 690px;
  gap: 20px;
  position: relative;

  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: ${breakpoints.sm}) {
    display: block;
    width: 100%;
    padding: 0 30px 0 30px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ButtonContainer = styled.div`
  display: block;
  max-width: 690px;
  gap: 20px;
  position: relative;

  margin: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  padding-top: 24px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);

    display: flex;
    padding: 0 10%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const ReviewButtonContainer = styled.div`
  display: block;
  max-width: 690px;
  gap: 20px;
  position: relative;

  margin: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  padding-top: 24px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);

    display: flex;
    padding: 0 100px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;
export const ButtonServiceContainer = styled.div`
  display: block;
  max-width: 690px;
  gap: 20px;
  position: relative;

  margin: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  padding-top: 24px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);

    display: flex;
    padding: 0 10%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const BottomContainer = styled.div`
  display: block;
  max-width: 690px;
  gap: 20px;
  position: relative;

  margin: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  padding-top: 24px;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);

    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 0;
  display: flex;
  gap: 18px;
  margin-bottom: 0;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-top: 12px;
    display: block;
    gap: 18px;
    margin-bottom: 24px;
  }
`;
export const StyledSpan = styled.span`
  color: ${colors.errorRed};
  display: flex;
  position: relative;
  justify-content: center;
`;

export const CenterWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0 10px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 10px;
  }
`;
export const InfoWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0 100px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: ${breakpoints.lg}) {
    padding: 0 10px;
    height: auto;
    justify-content: normal;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 10px;
    height: auto;
    justify-content: normal;
  }
`;

export const TellUsContainer = styled.div<{
  show?: boolean;
  flexDirection?: string;
  alignItems?: string;
  margin?: string;
  gap?: boolean;
  width?: string;
}>`
  display: ${({ show = true }) => (show ? 'flex' : 'none')};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems ?? 'unset'};
  margin: ${({ margin }) =>
    margin ?? `${spacingWithPx.gap} ${spacingWithPx.gap}`};
  gap: ${({ gap }) => (gap ? spacingWithPx.gap : '0')};
  width: ${({ width }) => width ?? 'auto'};
  max-width: ${breakpoints.widescreen};

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;
export const InputRow = styled.div`
  display: flex;
  padding: 40px 20px;
  align-items: flex-start;
  gap: 20px;

  color: ${colors.secondaryBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 20px 20px 40px 20px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100vw;
    padding: 20px 20%;
  }
`;
export const ReviewInputRow = styled.div`
  display: flex;
  padding: 40px 20px;
  align-items: flex-start;
  gap: 20px;

  color: ${colors.secondaryBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 20px 0 40px 0;
  }
`;
export const RightReviewInputRow = styled.div`
  display: block;
  padding: 40px 20px 40px 0;
  align-items: flex-start;
  gap: 20px;

  color: ${colors.secondaryBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 20px 0 40px 0;
  }
`;
export const CommentsContainer = styled.div`
  display: flex;
  width: 286px;
  padding: 40px 20px;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 40px 0;
  }
`;
export const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
export const CommentTitle = styled.p`
  color: ${colors.darkGrey}
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  width: 141px;
  height: 13px;
`;
export const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 133px;
  background: none;
  border-radius: 3px;
  padding: 10px;

  color: ${colors.secondaryBlue};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SubTitle = styled.p`
  color: ${colors.secondaryBlue}
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  white-space: nowrap;
  margin: 0;
  display: flex;
  left: -18px;
  position: relative;

  @media screen and (max-width: ${breakpoints.md}) {
   word-break: break-word;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    word-break: break-word;
    width: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: left;
  }

`;
export const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 9px;
  cursor: pointer;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;
export const ReviewBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const InfoRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;
export const ReviewInfoRowContainer = styled.div`
  display: block;
  align-items: flex-start;
  gap: 4px;
`;

export const InfoKeyContainer = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: ${colors.secondaryBlue}
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  white-space:nowrap;


`;
export const AnswerContainer = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: ${colors.darkGrey};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
export const RightAnswerContainer = styled.div`
  display: flex;
  padding: 4px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  color: ${colors.darkGrey};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
`;
export const TitleContainer = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
export const TitleText = styled.p`
  color: ${colors.bluePrimary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.024px;
  margin: 0;
`;

export const RowsPasswordContainer = styled.div`
  display: block;
  max-width: 398px;
  gap: 20px;
  position: relative;

  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: ${breakpoints.md}) {
    display: block;
    width: 100%;
    padding: 0 30px 0 30px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    display: block;
    padding-bottom: 0;
  }
`;

export const RowItem = styled.div`
  display: grid;
  width: 334px;
  //padding-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const RowPasswordItem = styled.div`
  display: grid;
  width: 100%;
  //padding-bottom: 20px;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 20px;
    width: 100%;
  }
`;
export const RowItemLabel = styled.label`
  display: grid;

  color: ${colors.secondaryBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  text-transform: capitalize;
`;

export const customControlStyles: CSSProperties = {
  borderColor: `${colors.secondaryBlue}`,
  height: '52px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.07px',
  minHeight: '48px',
};

export const placeholderContainerStyles: CSSProperties = {
  color: `${colors.placeholderGrey}`,
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: '24px',
  paddingLeft: '12px',
};

export const customIndicatorContainerStyles: CSSProperties = {
  color: `${colors.secondaryBlue}`,
};
export const customOptionStyles: CSSProperties = {
  height: '48px',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: 'normal',
  letterSpacing: '0.064px',
};

export const singleValueStyles: CSSProperties = {
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: '24px',
};

export const PrevButton = styled.button`
  display: flex;
  width: 208px;
  height: 36px;
  padding: 6px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  color: ${colors.bluePrimary};
  border-radius: 3px;
  border: 1px solid ${colors.bluePrimary};

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-bottom: 18px;
  }
`;

export const GreenButton = styled.button`
  display: flex;
  width: 208px;
  height: 36px;
  padding: 6px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  color: ${colors.white};
  border-radius: 3px;
  background: ${colors.bluePrimary};

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const DropDownMenuContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
  width: 100%;
`;
export const ThankYouContainer = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 625px;

  border-radius: 4px;
  background: ${colors.white};

  /* Card Shadow */
  box-shadow: 2px 2px 2px 0 ${colors.cardShadow};

  @media screen and (max-width: ${breakpoints.sm}) {
    display: flex;
    min-width: 251px;
    height: 258px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`;

export const ThankYouTextTitle = styled.p`
  color: ${colors.secondaryBlue};
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.085px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.06px;
  }
`;
export const CardText = styled.p`
  color: ${colors.darkGrey};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 450px;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 15px;
  }
`;

export const ServiceContainer = styled.div`
  display: flex;
  width: 582px;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.xl}) {
    width: 100%;
    position: relative;
    display: flex;
    height: 100%;
    padding: 0 100px;
    flex-direction: column;
    justify-content: center;
    transform: translate(0%, 0%);
    left: 0;
    align-items: baseline;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100vw;
    align-items: center;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    transform: translate(-50%, 0%);
    left: 50%;
  }
`;

export const TellUsMoreContainer = styled.div`
  display: flex;
  width: 582px;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.xl}) {
    width: 100%;
    position: relative;
    display: flex;
    height: 100%;
    padding: 0 100px;
    justify-content: center;
    transform: translate(0%, 0%);
    left: 0;
    align-items: baseline;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100vw;
    align-items: center;
    padding: 40px 20px;
    flex-direction: column;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    transform: translate(-50%, 0%);
    left: 50%;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  width: 582px;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    display: flex;
    height: 100%;
    padding: 0 100px;
    flex-direction: column;
    justify-content: center;
  }
`;
