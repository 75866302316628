import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { fontSize, fontWeight } from 'utils/styles';
import { ASSETS_URL } from 'utils/urls';
import { Span } from '../text/Span';
import { EyeClosed } from 'icons';
import { EyeOpen } from 'icons/eye-open';
import { CapsLockWarning } from 'icons/caps-lock-warning';
import { passwordStrengthEvaluator } from 'utils/validations';
import { useTranslation } from 'react-i18next';

type Status = 'success' | 'in progress' | 'error' | undefined;

const Input = styled.input<{ status: Status; themeColor?: string }>`
  border: 1px solid ${(props) =>
    props.themeColor ? props.themeColor : colors.textFieldBorderGrey};
  border-radius: 5px;
  background: ${colors.white};
  color: ${colors.black};
  font-size: ${fontSize.textLarge};
  font-weight: ${fontWeight.lightest};
  line-height: ${fontSize.h2};
  width: auto;
  padding: 13px 42px 13px 24px;
  &::placeholder {
    color: ${colors.textFieldPlaceholderGrey};
  }
  &:focus, &:focus-visible {
    box-shadow: 0 0 8px 0 ${colors.textFieldBoxShadow};
    border-color: ${colors.secondaryBlue};
  }
  ${({ status }) => {
    switch (status) {
      case 'success':
        return `
          border-color: ${colors.bluePrimary} !important;
          box-shadow: 0px 1px 4px 0px ${colors.textFieldSuccessGreen} !important;
        `;
      case 'in progress':
        return '';
      case 'error':
        // box-shadow has 10% opacity
        return `
          border-color: ${colors.errorRed} !important;
          box-shadow: 0px 1px 4px 0px ${colors.errorRed}10 !important;
          color: ${colors.errorRed} !important;
        `;
      default:
        return '';
    }
  }}

  }
`;

const CapsLockText = styled.p`
  color: ${colors.warningYellow};
  font-family:
    Open Sans,
    system-ui,
    sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07px;
  line-height: normal;
  white-space: pre-wrap;
  padding-left: 6px;
`;

function handleColorType(passwordStrength: string) {
  switch (passwordStrength) {
    case 'Weak':
      return colors.errorRed;
    case 'Medium':
      return colors.warningYellow;
    case 'Strong':
      return colors.bluePrimary;
    default:
      return colors.errorRed;
  }
}

const StrengthText = styled.p<{ passwordStrength?: string }>`
  padding-left: 3px;
  color: ${({ passwordStrength }) =>
    passwordStrength ? handleColorType(passwordStrength) : ''};
`;
const StrengthContainer = styled.div`
  display: flex;
  height: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
const CapsLockContainer = styled.div`
  display: flex;
  height: 23px;
`;
const CapsLockIconContainer = styled.div`
  position: relative;
  top: -4px;
`;

const InputContainer = styled.div<{ status: Status; type: InputTypeAttribute }>`
  position: relative;
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
  width: 100%;
  ${({ status, type }) => {
    switch (status) {
      case 'success':
        return (
          type !== 'password' &&
          `
          &:after {
            position: absolute;
            content: url('${ASSETS_URL}/input-checkmark.svg');
            height: 18px;
            width: 18px;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
        `
        );
      default:
        return '';
    }
  }}
`;

const PasswordContainer = styled.div<{ show: boolean }>`
  position: absolute;
  height: 18px;
  width: 16px;
  top: 44%;
  right: 18px;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const ErrorContainer = styled.div`
  position: relative;
  left: 6px;
`;

const Label = styled.label<{ themeColor?: string }>`
  color: ${(props) => (props.themeColor ? props.themeColor : colors.lightGrey)};
  font-size: ${fontSize.text};
  font-weight: ${fontWeight.lighter};
  letter-spacing: 0.07px;
  text-transform: capitalize;
`;

type InputTypeAttribute =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

type Props = {
  type: InputTypeAttribute;
  name: string;
  label: string;
  placeholder?: string;
  status?: Status;
  value?: string;
  errorMessage?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  themeColor?: string;
  showPasswordStrengthValue?: boolean;
};

export const TextField = forwardRef(
  (
    {
      type,
      name,
      label,
      placeholder,
      status,
      value,
      errorMessage,
      onChange,
      onBlur,
      themeColor,
      showPasswordStrengthValue,
      ...rest
    }: Props,
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState<'password' | 'text'>(
      'password',
    );
    const [passwordStrength, setPasswordStrength] = useState<string>('');

    const { t: translate } = useTranslation();
    const passwordStrengthConverter = (strengthNumber: number) => {
      switch (strengthNumber) {
        case 0:
          return translate?.('passwordStrengthEvaluatorWeak');
        case 1:
          return translate?.('passwordStrengthEvaluatorWeak');
        case 2:
          return translate?.('passwordStrengthEvaluatorMedium');
        case 3:
          return translate?.('passwordStrengthEvaluatorMedium');
        case 4:
          return translate?.('passwordStrengthEvaluatorStrong');
        default:
          return translate?.('passwordStrengthEvaluatorWeak');
      }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      if (showPasswordStrengthValue) {
        const finalPasswordStrength = passwordStrengthConverter(
          passwordStrengthEvaluator(e.target.value),
        );
        setPasswordStrength(finalPasswordStrength);
      }
    };

    const [isCapsLockOn, setIsCapsLockOn] = useState(false);

    const checkCapsLock = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.getModifierState('CapsLock')) {
        setIsCapsLockOn(true);
      } else {
        setIsCapsLockOn(false);
      }
    };

    return (
      <div style={{ width: '100%' }}>
        <div style={{ marginBottom: '12px' }}>
          <Label themeColor={themeColor} htmlFor={name}>
            {label}
          </Label>
        </div>
        <InputContainer status={status} type={type}>
          <Input
            onChange={handleChange}
            onBlur={onBlur}
            ref={ref as ForwardedRef<HTMLInputElement>}
            {...rest}
            name={name}
            type={type === 'password' ? showPassword : type}
            status={status}
            data-testid="ghg-text-field"
            placeholder={placeholder}
            themeColor={themeColor}
            onKeyUp={checkCapsLock}
            value={value}
          />
          {type === 'password' && (
            <PasswordContainer
              show={showPassword !== 'password'}
              onClick={() =>
                setShowPassword(
                  showPassword === 'password' ? 'text' : 'password',
                )
              }
            >
              {showPassword !== 'password' ? (
                <EyeOpen color={themeColor} />
              ) : (
                <EyeClosed color={themeColor} />
              )}
            </PasswordContainer>
          )}
        </InputContainer>
        {isCapsLockOn && (
          <CapsLockContainer>
            <CapsLockIconContainer>
              <CapsLockWarning />
            </CapsLockIconContainer>
            <CapsLockText>Caps lock is on.</CapsLockText>
          </CapsLockContainer>
        )}
        {passwordStrength && (
          <StrengthContainer>
            <p> Password Strength: </p>
            <StrengthText passwordStrength={passwordStrength}>
              {passwordStrength}
            </StrengthText>
          </StrengthContainer>
        )}
        {status === 'error' && (
          <div
            style={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              marginTop: '8px',
            }}
          >
            <ErrorContainer>
              <Span
                fontSize="12px"
                color={'red'}
                fontFamily={'Open Sans'}
                fontStyle={'normal'}
                fontWeight={'400'}
                lineHeight={'140%'}
              >
                {'x  '}
                {errorMessage ?? translate?.('requestAccountErrorMessage')}
              </Span>
            </ErrorContainer>
          </div>
        )}
      </div>
    );
  },
);

TextField.displayName = 'Text Field Input';
