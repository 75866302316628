import { Stack, Typography } from '@mui/material';

export interface SectionHeaderProps {
  title: string;
  subtitle?: string;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <Stack
      direction="column"
      sx={{
        width: 'auto',
        rowGap: 4,
      }}
    >
      <Typography variant={'h5'} color={'text.primary'}>
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography variant="body2" color={'text.primary'}>
          {props.subtitle}
        </Typography>
      )}
    </Stack>
  );
};
