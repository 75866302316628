import { MonitorLine, Report } from 'icons';
import React from 'react';
import theme from '../../../../theme/theme';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const notificationCardInfo = [
  // Todo -  active number will be replaced , and this will be a function
  {
    activeNumber: '5',
    cardTitle: 'System Notices',
    cardIcon: <MonitorLine />,
    pathLink: '/notifications',
  },
  {
    activeNumber: '10',
    cardTitle: 'Open Project',
    cardIcon: <AssignmentIcon sx={{ color: theme.palette.secondary.main }} />,
    pathLink: '/my-projects',
  },
  {
    activeNumber: '4',
    cardTitle: 'Reports Review',
    cardIcon: <Report />,
    pathLink: '/task-manager',
  },
];
