import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { colors, spacingWithPx } from 'utils/styles';

export const CardContainer = styled.div`
  max-width: 350px;
  padding: 20px ${spacingWithPx.md} 20px ${spacingWithPx.xs};
  background-color: ${colors.white};
  border-radius: 4px;
  @media only screen and (min-width: ${breakpoints.md}) {
    max-width: 301px;
  }
  @media only screen and (min-width: ${breakpoints.xs}) {
    width: 100%;
  }
  @media only screen and (min-width: ${breakpoints.lg}) {
    max-width: 335px;
  }
`;

export const Card = styled.div`
  display: flex;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardDateLabel = styled.div<{ color: string }>`
  display: flex;
  height: 24px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;
