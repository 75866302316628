import { colors } from 'utils/colors';

export const DeleteIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M16.5625 2.76923H13.125V2.07692C13.125 1.52609 12.9077 0.997815 12.5209 0.608317C12.1341 0.218818 11.6095 0 11.0625 0H6.9375C6.39049 0 5.86589 0.218818 5.47909 0.608317C5.0923 0.997815 4.875 1.52609 4.875 2.07692V2.76923H1.4375C1.25516 2.76923 1.0803 2.84217 0.951364 2.972C0.822433 3.10184 0.75 3.27793 0.75 3.46154C0.75 3.64515 0.822433 3.82124 0.951364 3.95107C1.0803 4.08091 1.25516 4.15385 1.4375 4.15385H2.125V16.6154C2.125 16.9826 2.26987 17.3348 2.52773 17.5945C2.78559 17.8541 3.13533 18 3.5 18H14.5C14.8647 18 15.2144 17.8541 15.4723 17.5945C15.7301 17.3348 15.875 16.9826 15.875 16.6154V4.15385H16.5625C16.7448 4.15385 16.9197 4.08091 17.0486 3.95107C17.1776 3.82124 17.25 3.64515 17.25 3.46154C17.25 3.27793 17.1776 3.10184 17.0486 2.972C16.9197 2.84217 16.7448 2.76923 16.5625 2.76923ZM6.25 2.07692C6.25 1.89331 6.32243 1.71722 6.45136 1.58739C6.5803 1.45755 6.75516 1.38462 6.9375 1.38462H11.0625C11.2448 1.38462 11.4197 1.45755 11.5486 1.58739C11.6776 1.71722 11.75 1.89331 11.75 2.07692V2.76923H6.25V2.07692ZM14.5 16.6154H3.5V4.15385H14.5V16.6154ZM7.625 7.61539V13.1538C7.625 13.3375 7.55257 13.5135 7.42364 13.6434C7.2947 13.7732 7.11984 13.8462 6.9375 13.8462C6.75516 13.8462 6.5803 13.7732 6.45136 13.6434C6.32243 13.5135 6.25 13.3375 6.25 13.1538V7.61539C6.25 7.43177 6.32243 7.25568 6.45136 7.12585C6.5803 6.99602 6.75516 6.92308 6.9375 6.92308C7.11984 6.92308 7.2947 6.99602 7.42364 7.12585C7.55257 7.25568 7.625 7.43177 7.625 7.61539ZM11.75 7.61539V13.1538C11.75 13.3375 11.6776 13.5135 11.5486 13.6434C11.4197 13.7732 11.2448 13.8462 11.0625 13.8462C10.8802 13.8462 10.7053 13.7732 10.5764 13.6434C10.4474 13.5135 10.375 13.3375 10.375 13.1538V7.61539C10.375 7.43177 10.4474 7.25568 10.5764 7.12585C10.7053 6.99602 10.8802 6.92308 11.0625 6.92308C11.2448 6.92308 11.4197 6.99602 11.5486 7.12585C11.6776 7.25568 11.75 7.43177 11.75 7.61539Z"
      fill={color ? color : colors.lighterSecondaryBlue}
    />
  </svg>
);
