import { AppContainer, Card } from 'components';
import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { spacingWithPx } from 'utils/styles';
import { useState } from 'react';
import { ResetPasswordRequest } from 'pages/forgot-password/components/reset-password-request';
import { InputEmailCode } from 'pages/forgot-password/components/input-email-code';
import { ConfirmationDisplay } from 'pages/forgot-password/components/confirmation-display';

const LoginContainer = styled(Card)`
  width: 402px;
  height: auto;
  padding: ${spacingWithPx.lg} ${spacingWithPx.lg};
  margin: 0 auto;
  @media only screen and (max-width: ${breakpoints.sm}) {
    background-color: inherit;
    outline: none;
    box-shadow: none;
  }
`;

export const ForgotPassword = () => {
  const [formStep, setFormStep] = useState(1);
  const [userEmail, setUserEmail] = useState('');

  const prevStep = () => {
    if (formStep > 1) {
      setFormStep(formStep - 1);
    }
  };

  // proceed to the next step
  const nextStep = () => {
    if (formStep < 3) {
      setFormStep(formStep + 1);
    }
  };

  function renderSwitch(step: number) {
    switch (step) {
      case 1:
        return (
          <ResetPasswordRequest
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <InputEmailCode
            userEmail={userEmail}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return <ConfirmationDisplay />;
      default:
        break;
    }
  }
  return (
    <AppContainer id="ghg-app-container">
      <LoginContainer>{renderSwitch(formStep)}</LoginContainer>
    </AppContainer>
  );
};
