import { yupResolver } from '@hookform/resolvers/yup';
import { Headline, TextField } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { colors } from 'utils/styles';
import * as yup from 'yup';
import React, { useEffect } from 'react';
import { Container } from 'pages/dashboard/styled';
import { useTranslation } from 'react-i18next';
import {
  DropDownMenuContainer,
  GreenButton,
  InfoWrapper,
  RowContainer,
  RowItem,
  RowItemLabel,
  RowsContainer,
} from 'pages/request-account/components/styled';
import { RequestInformationType } from 'pages/request-account';
import { DropDownMenu } from 'pages/request-account/components/client-account-info/drop-down-menu';
import { DropDownOptionType } from 'pages/clients/api/get-location';
import {
  getCity,
  getCountry,
  getRegion,
  getState,
} from 'pages/request-account/api/get-data';

export interface createPasswordProps {
  setRequestedInformation: React.Dispatch<
    React.SetStateAction<RequestInformationType>
  >;
  requestedInformation: RequestInformationType;
  nextStep: () => void;
}
export const ClientAccountInfo = (props: createPasswordProps) => {
  const { requestedInformation, setRequestedInformation, nextStep } = props;
  const translation = useTranslation();
  const { t: translate } = translation;

  const clientAccountInfoSchema = yup.object().shape({
    clientName: yup
      .string()
      .required(translate?.('clientAccountInfoErrorMessageNameRequired'))
      .matches(
        /(.|\s)*\S(.|\s)*/,
        translate?.('clientAccountInfoErrorMessageNameNotEmpty'),
      ),
    contactName: yup
      .string()
      .required(translate?.('clientAccountInfoErrorMessageContactRequired'))
      .matches(
        /(.|\s)*\S(.|\s)*/,
        translate?.('clientAccountInfoErrorMessageContactNotEmpty'),
      ),
    phoneNumber: yup
      .string()
      .required(translate?.('clientAccountInfoErrorMessagePhoneRequired'))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        translate?.('clientAccountInfoErrorMessagePhoneInvalid'),
      ),
    email: yup
      .string()
      .required(translate?.('clientAccountInfoErrorMessageEmailRequired'))
      .email(translate?.('clientAccountInfoErrorMessageEmailInvalid')),
  });

  const {
    control,
    trigger,
    formState: { isValid, errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      clientName: '',
      contactName: '',
      phoneNumber: '',
    },
    resolver: yupResolver(clientAccountInfoSchema),
  });

  const checkFieldValidity = async (
    fieldName: 'clientName' | 'contactName' | 'phoneNumber' | 'email',
  ) => {
    await trigger(fieldName);
  };

  const COUNTRIES_LIST_OPTIONS: DropDownOptionType[] = getCountry();
  const STATES_LIST_OPTIONS: DropDownOptionType[] = getState();
  const REGION_LIST_OPTIONS: DropDownOptionType[] = getRegion();
  const CITIES_LIST_OPTIONS: DropDownOptionType[] = getCity();

  // This is needed to tell react-hook-form the values used in input are set in
  // the state at the beginning otherwise the state information is empty
  useEffect(() => {
    setValue('clientName', requestedInformation.clientName);
    setValue('contactName', requestedInformation.contactName);
    setValue('phoneNumber', requestedInformation.phone);
    setValue('email', requestedInformation.email);
  }, [requestedInformation]);

  const handleNext = async () => {
    const isStepValid = await trigger();
    try {
      if (
        isStepValid &&
        requestedInformation.clientName !== '' &&
        requestedInformation.contactName !== '' &&
        requestedInformation.phone !== '' &&
        requestedInformation.email !== ''
      )
        nextStep();
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <InfoWrapper>
      <Container width="100%" margin="62px 0 0 0">
        <Headline fontSize={34} fontSizeSmall={24} color="secondaryBlue">
          {translate?.('clientAccountInfoTitle')}
        </Headline>
      </Container>
      <Container width="100%" flexDirection="column" margin={'0'}>
        <RowsContainer>
          <RowContainer>
            <RowItem>
              <Controller
                name="clientName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => {
                      checkFieldValidity('clientName');
                    }}
                    onChange={(e) => {
                      setRequestedInformation({
                        ...requestedInformation,
                        clientName: e.target.value,
                      });
                      field.onChange(e);
                      clearErrors();
                    }}
                    label={translate?.('clientAccountInfoClientLabel')}
                    placeholder={translate?.(
                      'clientAccountInfoClientPlaceholder',
                    )}
                    type="text"
                    status={
                      errors.clientName
                        ? 'error'
                        : isValid
                          ? 'success'
                          : undefined
                    }
                    errorMessage={
                      errors.clientName && errors.clientName.message
                    }
                    themeColor={colors.secondaryBlue}
                    value={requestedInformation.clientName}
                  />
                )}
              />
            </RowItem>
            <RowItem>
              <Controller
                name="contactName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => {
                      checkFieldValidity('contactName');
                    }}
                    onChange={(e) => {
                      setRequestedInformation({
                        ...requestedInformation,
                        contactName: e.target.value,
                      });
                      field.onChange(e);
                      clearErrors();
                    }}
                    label={translate?.('clientAccountInfoContactLabel')}
                    placeholder={translate?.(
                      'clientAccountInfoContactPlaceholder',
                    )}
                    type="text"
                    status={
                      errors.contactName
                        ? 'error'
                        : isValid
                          ? 'success'
                          : undefined
                    }
                    errorMessage={
                      errors.contactName && errors.contactName.message
                    }
                    themeColor={colors.secondaryBlue}
                    value={requestedInformation.contactName}
                  />
                )}
              />
            </RowItem>
            <RowItem>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => {
                      checkFieldValidity('phoneNumber');
                    }}
                    onChange={(e) => {
                      setRequestedInformation({
                        ...requestedInformation,
                        phone: e.target.value,
                      });
                      field.onChange(e);
                      clearErrors();
                    }}
                    label={translate?.('clientAccountInfoPhoneLabel')}
                    placeholder={translate?.(
                      'clientAccountInfoPhonePlaceholder',
                    )}
                    type="number"
                    status={
                      errors.phoneNumber
                        ? 'error'
                        : isValid
                          ? 'success'
                          : undefined
                    }
                    errorMessage={
                      errors.phoneNumber && errors.phoneNumber.message
                    }
                    themeColor={colors.secondaryBlue}
                    value={requestedInformation.phone}
                  />
                )}
              />
            </RowItem>
            <RowItem>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => {
                      checkFieldValidity('email');
                    }}
                    onChange={(e) => {
                      setRequestedInformation({
                        ...requestedInformation,
                        email: e.target.value,
                      });
                      field.onChange(e);
                      clearErrors();
                    }}
                    label={translate?.('clientAccountInfoEmailLabel')}
                    placeholder={translate?.(
                      'clientAccountInfoEmailPlaceholder',
                    )}
                    type="email"
                    status={
                      errors.email ? 'error' : isValid ? 'success' : undefined
                    }
                    errorMessage={errors.email && errors.email.message}
                    themeColor={colors.secondaryBlue}
                    value={requestedInformation.email}
                  />
                )}
              />
            </RowItem>

            <RowItem>
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: '15px' }}>
                  <RowItemLabel>Country</RowItemLabel>
                </div>
                <DropDownMenuContainer>
                  <DropDownMenu
                    selectedData={requestedInformation.country}
                    placeholder={translate?.(
                      'clientAccountInfoCountryPlaceholder',
                    )}
                    options={COUNTRIES_LIST_OPTIONS}
                    inputName="country"
                    setFormData={setRequestedInformation}
                  />
                </DropDownMenuContainer>
              </div>
            </RowItem>
            <RowItem>
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: '15px' }}>
                  <RowItemLabel>State/Province</RowItemLabel>
                </div>
                <DropDownMenuContainer>
                  <DropDownMenu
                    selectedData={requestedInformation.state}
                    placeholder={translate?.(
                      'clientAccountInfoStatePlaceholder',
                    )}
                    options={STATES_LIST_OPTIONS}
                    inputName="state"
                    setFormData={setRequestedInformation}
                  />
                </DropDownMenuContainer>
              </div>
            </RowItem>
            <RowItem>
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: '15px' }}>
                  <RowItemLabel>Region</RowItemLabel>
                </div>
                <DropDownMenuContainer>
                  <DropDownMenu
                    selectedData={requestedInformation.region}
                    placeholder={translate?.(
                      'clientAccountInfoRegionPlaceholder',
                    )}
                    options={REGION_LIST_OPTIONS}
                    inputName="region"
                    setFormData={setRequestedInformation}
                  />
                </DropDownMenuContainer>
              </div>
            </RowItem>
            <RowItem>
              <div style={{ width: '100%' }}>
                <div style={{ marginBottom: '15px' }}>
                  <RowItemLabel>
                    {translate?.('clientAccountInfoCityLabel')}
                  </RowItemLabel>
                </div>
                <DropDownMenuContainer>
                  <DropDownMenu
                    selectedData={requestedInformation.city}
                    placeholder={translate?.(
                      'clientAccountInfoCityPlaceholder',
                    )}
                    options={CITIES_LIST_OPTIONS}
                    inputName="city"
                    setFormData={setRequestedInformation}
                  />
                </DropDownMenuContainer>
              </div>
            </RowItem>
            <RowItem>
              <TextField
                onChange={(e) => {
                  setRequestedInformation({
                    ...requestedInformation,
                    address: e.target.value,
                  });
                  clearErrors();
                }}
                label="Address"
                placeholder={translate?.('clientAccountInfoAddressPlaceholder')}
                type="text"
                themeColor={colors.secondaryBlue}
                value={requestedInformation.address}
                name={'address'}
              />
            </RowItem>
            <div
              style={{
                width: '100%',
                marginTop: '12px',
                display: 'flex',
                gap: '18px',
                marginBottom: '24px',
              }}
            >
              <GreenButton onClick={() => handleNext()}>
                {translate?.('nextButton')}
              </GreenButton>
            </div>
          </RowContainer>
        </RowsContainer>
      </Container>
    </InfoWrapper>
  );
};
