export const MobileArrowRightIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 4L16 9L12.25 14"
      stroke="#CAD4DB"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M4.75 8.25H4V9.75H4.75V8.25ZM16 9.75C16.4142 9.75 16.75 9.41421 16.75 9C16.75 8.58579 16.4142 8.25 16 8.25V9.75ZM4.75 9.75H16V8.25H4.75V9.75Z"
      fill="#CAD4DB"
    />
    <path
      d="M3.5 1.5H1.5C1.22386 1.5 1 1.72386 1 2V16C1 16.2761 1.22386 16.5 1.5 16.5H3.5"
      stroke="#CAD4DB"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
