import { Box } from '@mui/material';
import { ReactNode } from 'react';
import theme from '../../../theme/theme';
import Grid from '@mui/material/Grid2';
//I am importing before the Component is declared.

export type StringNumberOrResponsiveObject =
  | string
  | number
  | { [key: string]: number | string };

export const PageWrapper = ({
  children,
  paddingTop = 9,
  heightValue,
  pxValue = { xs: 4, md: 7 },
  pyValue,
  isGrid,
  gap = { xs: 7 },
}: {
  children: ReactNode;
  paddingTop?: number;
  heightValue?: string | null;
  pxValue?: StringNumberOrResponsiveObject;
  pyValue?: StringNumberOrResponsiveObject;
  isGrid?: boolean;
  gap?: StringNumberOrResponsiveObject;
}) => {
  return isGrid ? (
    <Grid
      container
      paddingTop={paddingTop}
      paddingBottom={paddingTop}
      height={'fit-content'}
      px={pxValue}
      py={pyValue}
      sx={{ flexGrow: 1 }}
      gap={gap}
    >
      {children}
    </Grid>
  ) : (
    <Box
      paddingTop={paddingTop}
      paddingBottom={theme.spacing(1)}
      height={
        heightValue ? `${heightValue}` : `calc(100% - ${theme.spacing(9)})`
      }
      px={pxValue}
      py={pyValue}
      bgcolor={'bg.main'}
      sx={{ flexGrow: 1 }}
    >
      {children}
    </Box>
  );
};
