import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardText } from 'pages/forgot-password/components/styled';
import { Button, Heading } from 'components';
import { useNavigate } from 'react-router-dom';

export function ConfirmationDisplay(): JSX.Element {
  const navigate = useNavigate();
  const translation = useTranslation();
  const { t: translate } = translation;

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center', marginBottom: '24px' }}>
        <Heading level={1} color="secondaryBlue">
          {translate?.('confirmationDisplayTitle')}
        </Heading>
      </div>
      <CardText>{translate?.('ConfirmationMessage')}</CardText>
      <div style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
        <Button onClick={() => navigate('/login')}>
          {translate?.('confirmationButton')}
        </Button>
      </div>
    </>
  );
}
