import React, { ReactNode, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

// This component will wrap component children. It will take a text to be displayed as tooltip title (copyText) when hovering, if the element is clicked then the text (copyText) is  copied to the clipboard .
// Usage:
//
// <CopyToClipBoard copyText={'Your Text'} cursorPointer>
// <YourComponent/>
// </CopyToClipBoard>
export const CopyToClipBoard = ({
  children,
  copyText,
  cursorPointer,
  onClick,
}: {
  children: ReactNode;
  copyText: string;
  onClick?: () => void;
  cursorPointer?: boolean;
}) => {
  const HIDE_COPIED_MESSAGE_TIME = 2000;

  const [titleText, setTitleText] = useState(copyText);
  const { t: translate } = useTranslation();

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(copyText);
    setTitleText(translate('textCopiedMessage'));
    setTimeout(() => {
      setTitleText(copyText);
    }, HIDE_COPIED_MESSAGE_TIME);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Tooltip title={titleText}>
      <Box
        onClick={handleTooltipOpen}
        sx={{ cursor: `${cursorPointer ? 'pointer' : 'auto'}` }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
