export const Projects = ({ color }: { color: string }) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0C5.68578 0 4.17247 1.28832 3.82501 3H0.75C0.335786 3 0 3.33579 0 3.75V17.25C0 17.6642 0.335787 18 0.75 18H14.25C14.6642 18 15 17.6642 15 17.25V3.75C15 3.33579 14.6642 3 14.25 3H11.175C10.8275 1.28832 9.31422 0 7.5 0ZM9 3C9 3.82843 8.32843 4.5 7.5 4.5C6.67157 4.5 6 3.82843 6 3C6 2.17157 6.67157 1.5 7.5 1.5C8.32843 1.5 9 2.17157 9 3ZM2.625 6C2.41789 6 2.25 6.16789 2.25 6.375V7.125C2.25 7.33211 2.41789 7.5 2.625 7.5H12.375C12.5821 7.5 12.75 7.33211 12.75 7.125V6.375C12.75 6.16789 12.5821 6 12.375 6H2.625ZM2.25 9.375C2.25 9.16789 2.41789 9 2.625 9H12.375C12.5821 9 12.75 9.16789 12.75 9.375V10.875C12.75 11.0821 12.5821 11.25 12.375 11.25H2.625C2.41789 11.25 2.25 11.0821 2.25 10.875V9.375ZM2.625 12.75C2.41789 12.75 2.25 12.9179 2.25 13.125V13.875C2.25 14.0821 2.41789 14.25 2.625 14.25H8.625C8.83211 14.25 9 14.0821 9 13.875V13.125C9 12.9179 8.83211 12.75 8.625 12.75H2.625Z"
        fill={color}
      />
    </svg>
  );
};
