import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './en.json';
import frTranslation from './fr.json';
import deTranslation from './de.json';

const resources = {
  en: { translation: enTranslation },
  de: { translation: deTranslation },
  fr: { translation: frTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language here
  fallbackLng: 'en', // Fallback language in case translation is missing for the current language
  interpolation: {
    escapeValue: false, // Allows usage of HTML tags in translations
  },
});

export const languages = i18n;
