export const PencilAngledIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.08372 2.00265L10.7282 4.45565L4.03427 10.6649L1.39128 8.21193L8.08372 2.00265ZM12.7349 1.41104L11.5555 0.317082C11.0998 -0.105694 10.3597 -0.105694 9.90235 0.317082L8.77266 1.36498L11.4172 3.81801L12.7349 2.59569C13.0884 2.26776 13.0884 1.73894 12.7349 1.41104ZM0.007359 11.6598C-0.0407674 11.8607 0.154786 12.0408 0.371404 11.9919L3.31825 11.3291L0.675254 8.87613L0.007359 11.6598Z"
      fill="#D9D9D9"
    />
  </svg>
);
