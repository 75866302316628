// TODO: Update Span

import { ReactChild } from 'react';
import styled from 'styled-components';
import {
  defaultFontFamily,
  fontSize as fontSizes,
  fontWeight as fontWeights,
  colors,
} from 'utils/styles';

const SpanStyled = styled.span<{
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  textDecoration?: string;
  color?: keyof typeof colors;
  fontStyle?: string;
  lineHeight?: string;
}>`
  font-family: ${({ fontFamily }) => fontFamily ?? defaultFontFamily};
  font-size: ${({ fontSize }) => fontSize ?? fontSizes.text};
  font-weight: ${({ fontWeight }) => fontWeight ?? fontWeights.lighter};
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  line-height: ${({ lineHeight }) => lineHeight ?? 'normal'};
  color: ${({ color }) =>
    colors[color as keyof typeof colors] ?? colors.darkGrey};
  letter-spacing: 0.07px;
  white-space: pre-wrap;
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? textDecoration : ''};
`;

type Props = {
  children: string | ReactChild;
  color?: keyof typeof colors;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  fontStyle?: string;
  lineHeight?: string;
  textDecoration?: string;
};

export const Span = ({
  children,
  color,
  fontSize,
  fontWeight,
  textDecoration,
  fontFamily,
  fontStyle,
  lineHeight,
}: Props) => {
  const size = fontSize
    ? fontSizes[fontSize as keyof typeof fontSizes]
    : '16px';
  return (
    <SpanStyled
      fontSize={size}
      color={color}
      textDecoration={textDecoration}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      lineHeight={lineHeight}
    >
      {children}
    </SpanStyled>
  );
};
