import {
  ConditionalTextContainerHeader,
  ConditionalTextContainerInfo,
  RowMobileContainer,
  RowTableContainer,
  StyledFirstSubRow,
  StyledSubRow,
  SubRowContainer,
  TruncatedTextCell,
} from 'pages/clients/components/clients-table/styled';
import React from 'react';
import { Row } from '@tanstack/react-table';
import {
  ClientSubComponentHeaders,
  NewClientDataType,
} from 'pages/clients/api/get-clients';
import { CopyToClipBoard } from 'utils/copy-to-clipboard';
export function RenderSubComponent({
  row,
  subComponentHeaders,
}: {
  row: Row<NewClientDataType>;
  subComponentHeaders: ClientSubComponentHeaders;
}) {
  return (
    <>
      <SubRowContainer>
        <StyledSubRow>
          <ConditionalTextContainerHeader>
            {subComponentHeaders.clientId}
          </ConditionalTextContainerHeader>
          <StyledFirstSubRow>
            <ConditionalTextContainerInfo>
              <CopyToClipBoard copyText={row.original.clientId}>
                <TruncatedTextCell>{row.original.clientId}</TruncatedTextCell>
              </CopyToClipBoard>
            </ConditionalTextContainerInfo>
          </StyledFirstSubRow>
        </StyledSubRow>
      </SubRowContainer>
      <RowTableContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {subComponentHeaders.clientName}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <CopyToClipBoard
                  copyText={`${row.original.firstName} ${row.original.lastName}`}
                >
                  <TruncatedTextCell>
                    {`${row.original.firstName} ${row.original.lastName}`}
                  </TruncatedTextCell>
                </CopyToClipBoard>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
      </RowTableContainer>
      <RowMobileContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {subComponentHeaders.clientPhone}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <CopyToClipBoard copyText={row.original.phone}>
                  <TruncatedTextCell>{row.original.phone}</TruncatedTextCell>
                </CopyToClipBoard>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {subComponentHeaders.clientEmail}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <CopyToClipBoard copyText={row.original.email}>
                  <TruncatedTextCell>{row.original.email}</TruncatedTextCell>
                </CopyToClipBoard>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
        <SubRowContainer>
          <StyledSubRow>
            <ConditionalTextContainerHeader>
              {subComponentHeaders.clientLocation}
            </ConditionalTextContainerHeader>
            <StyledFirstSubRow>
              <ConditionalTextContainerInfo>
                <CopyToClipBoard copyText={row.original.country}>
                  <TruncatedTextCell>{row.original.country}</TruncatedTextCell>
                </CopyToClipBoard>
              </ConditionalTextContainerInfo>
            </StyledFirstSubRow>
          </StyledSubRow>
        </SubRowContainer>
      </RowMobileContainer>
    </>
  );
}
