export const Checkmark = () => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9L8.5 16L21.5 1"
      stroke="white"
      strokeWidth="3"
      strokeLinejoin="round"
    />
  </svg>
);
