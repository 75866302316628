import { Stack } from '@mui/material';
import { SectionHeader, SectionHeaderProps } from '../section-header';
import { Form } from '../form/form';
import { FormValuesStateAndSetter } from '../../user-profile';
import { BasicJoinedUserSettingsType } from 'pages/user-profile/components/types';
import { FormControlFields } from 'pages/user-profile/components/types';
import { ExtendedUserSettingsData } from '../../../../react-query-toolkit/state/user-settings-context';

const formatsControls: FormControlFields[] = [
  {
    name: 'numberformat',
    displayedName: 'Number Format',
    controlType: 'input',
    inputType: 'radio',
    options: [
      { value: 'decimal', displayedValue: 'Decimal Comma' },
      { value: 'point', displayedValue: 'Decimal Point' },
    ],
    isRequired: true,
  },
  {
    name: 'timeformat',
    displayedName: 'Time Format',
    controlType: 'input',
    inputType: 'radio',
    options: [
      { value: 'hh:mm a', displayedValue: '12 Hours' },
      { value: 'HH:mm', displayedValue: '24 Hours' },
    ],
    isRequired: true,
  },
];

export const Formats = ({
  formInputValuesStateAndSetter,
  fetchedUserSettings,
  handleSubmit,
}: {
  formInputValuesStateAndSetter: FormValuesStateAndSetter;
  fetchedUserSettings: BasicJoinedUserSettingsType;
  handleSubmit: (userDataObject: ExtendedUserSettingsData) => Promise<void>;
}) => {
  const sectionHeader: SectionHeaderProps = {
    title: 'Formats',
  };

  return (
    <Stack bgcolor={'white'} gap={{ xs: 7, md: 6 }} borderRadius={2}>
      <SectionHeader title={sectionHeader.title} />
      <Form
        area="formats"
        formControlsInfo={formatsControls}
        formInputValuesStateAndSetter={formInputValuesStateAndSetter}
        fetchedUserSettings={fetchedUserSettings}
        handleSubmit={handleSubmit}
      />
    </Stack>
  );
};
