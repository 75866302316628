export const Pencil = () => (
  <svg
    width="6"
    height="28"
    viewBox="0 0 6 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="4" width="6" height="19" rx="0.5" fill="white" fillOpacity="0.8" />
    <path
      d="M6 23.5H0L2.16795 26.7519C2.56377 27.3457 3.43623 27.3457 3.83205 26.7519L6 23.5Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M5.8 3C5.91046 3 6.00069 2.91036 5.99334 2.80015C5.9716 2.47456 5.89685 2.15424 5.77164 1.85195C5.62087 1.48797 5.3999 1.15726 5.12132 0.87868C4.84274 0.600104 4.51203 0.379125 4.14805 0.228361C3.78407 0.0775973 3.39397 -1.72208e-08 3 0C2.60603 1.72208e-08 2.21593 0.0775973 1.85195 0.228361C1.48797 0.379126 1.15726 0.600104 0.87868 0.87868C0.600104 1.15726 0.379125 1.48797 0.228361 1.85195C0.103147 2.15424 0.0284021 2.47456 0.00666419 2.80015C-0.000694194 2.91036 0.0895431 3 0.2 3L3 3H5.8Z"
      fill="white"
      fillOpacity="0.8"
    />
  </svg>
);
