import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import theme from '../../../../theme/theme';
import { Variant } from '@mui/material/styles/createTypography';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface ContainedButtonProps extends ButtonProps {
  fontVariant?: Variant;
  spacing?: string;
  width?: string;
}

export const StyledCustomButton = styled(Button)<
  ButtonProps & ContainedButtonProps //TODO-CG: ask carlos if we need the ButtonProps here since it already is extended by ContainedButtonProps
>(({ width, spacing, fontVariant }) => ({
  minWidth: 0,
  // minHeight: `${theme.spacing(10)}`,
  padding: spacing ? spacing : `${theme.spacing(3)} ${theme.spacing(5)}`,
  fontSize: fontVariant ? theme.typography[fontVariant]?.fontSize : 'inherit',
  width: width ? width : 'auto',
  alignItems: 'center',
  display: 'flex',
}));

export const CustomButton = ({
  //todo-cg:review wether this should be kept or not based on ana maria's
  children,
  upperCaseText,
  variant,
  disabled,
  type = 'button',
  clickHandler,
}: {
  children: ReactNode;
  upperCaseText?: boolean;
  variant: ButtonProps['variant'];
  type?: 'button' | 'submit' | 'reset';
  disabled: boolean;
  clickHandler?: () => void;
}) => {
  return (
    <StyledCustomButton
      variant={variant}
      onClick={clickHandler}
      disabled={disabled}
      type={type}
    >
      <Typography
        display={'flex'}
        variant="button"
        lineHeight={'normal'}
        alignItems={'center'}
        sx={{ textTransform: `${upperCaseText ? 'uppercase' : ''}` }}
      >
        {children}
      </Typography>
    </StyledCustomButton>
  );
};
