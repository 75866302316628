import { Cell, ColumnDef } from '@tanstack/react-table';
import { ArrowDownWithBorder, ArrowRightWithBorder } from 'icons';
import { ActiveIcon, EditIcon, ProjectIcon } from 'icons';
import {
  ClientSubComponentHeaders,
  DataVisibilityType,
  NewClientDataType,
  NewDataType,
  RowType,
} from 'pages/clients/api/get-clients';
import { ProfileImage } from 'pages/clients/components/clients-table/profile-image';
import { ClientModalForm } from 'pages/clients/components/popUp-Modal/client-modal-form';
import { Container } from 'pages/dashboard/styled';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LG, MD } from 'utils/breakpoints';
import { spacingWithPx } from 'utils/styles';
import { getWindowDimensions } from 'utils/window-utils';
import { DisplayTable } from './components/clients-table';
import {
  ConsultantContainer,
  ConsultantTitleContainer,
  ExpandingButtonContainer,
} from './components/clients-table/styled';
import { RenderSubComponent } from 'pages/clients/components/clients-table/render-sub-component';
import { useClientDataContext } from '../../react-query-toolkit/state/client-context';
import LoadingComponent from 'components/loading-icon-component';
import { isFirstDatePastSecondDate } from 'utils/date-validations';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { CopyToClipboardTruncated } from 'utils/copy-to-clipboard-truncated';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { Item } from '../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { getClientIdsFromProjects } from 'utils/get-client-ids-from-projects';

export const Clients = () => {
  const [filtering, setFiltering] = useState('');
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isMobileWidth, setIsMobileWidth] = useState<boolean>(
    windowSize.width < MD,
  );
  const [isDesktopWidth, setIsDesktopWidth] = useState<boolean>(
    windowSize.width > LG,
  );
  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      contactName: isMobileWidth,
      phone: isDesktopWidth,
      email: isDesktopWidth,
      city: isDesktopWidth,
    });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<NewClientDataType>();

  const { t: translate } = useTranslation();

  const subComponentHeaders: ClientSubComponentHeaders = {
    clientId: 'ID',
    clientName: 'Name',
    clientPhone: 'Phone',
    clientEmail: 'Email',
    clientLocation: 'City',
  };

  function createColumnInfo() {
    return [
      {
        header: ' ',
        accessor: 'expand',
        size: 35,
        enableSorting: false,
        cell: ({ row }: Cell<ColumnDef<NewDataType>, NewDataType>) => (
          <ExpandingButtonContainer>
            <button
              onKeyDown={row.getToggleExpandedHandler()}
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {
                  cursor: 'pointer',
                  top: '2px',
                  display: 'flex',
                  position: 'relative',
                },
              }}
            >
              {row.getIsExpanded() ? (
                <ArrowDownWithBorder />
              ) : (
                <ArrowRightWithBorder />
              )}
            </button>
          </ExpandingButtonContainer>
        ),
      },
      {
        header: `${translate?.('headerClient')}`,
        accessorKey: 'clientName',
        size: 100,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '3px', alignItems: 'center' }}>
              <ActiveIcon
                active={isFirstDatePastSecondDate(
                  row.original.closeDate,
                  row.original.activeDate,
                )}
              />
            </div>
            <CopyToClipboardTruncated copyText={row.original.clientName}>
              {row.original.clientName}
            </CopyToClipboardTruncated>
          </div>
        ),
      },
      {
        header: 'Name',
        accessorKey: 'contactName',
        size: 80,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <CopyToClipboardTruncated
            copyText={row.original.firstName + ' ' + row.original.lastName}
          >
            {row.original.firstName + ' ' + row.original.lastName}
          </CopyToClipboardTruncated>
        ),
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        size: 60,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <CopyToClipboardTruncated copyText={row.original.phone}>
            {row.original.phone}
          </CopyToClipboardTruncated>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableSorting: false,
        size: 90,
        cell: ({ row, column }: Cell<NewClientDataType, NewClientDataType>) => {
          return (
            <CopyToClipboardTruncated copyText={row.original.email}>
              {row.original.email}
            </CopyToClipboardTruncated>
          );
        },
      },
      {
        header: 'City',
        accessorKey: 'city',
        enableSorting: false,
        size: 50,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <CopyToClipboardTruncated copyText={row.original.city}>
              {row.original.city}
            </CopyToClipboardTruncated>
          </div>
        ),
      },
      {
        header: () => (
          <ConsultantTitleContainer>A. Consultant</ConsultantTitleContainer>
        ),
        accessorKey: 'consultants',
        enableSorting: false,
        size: 1,
        cell: ({ row }: Cell<RowType, RowType>) => (
          <ConsultantContainer>
            {row.original.consultants.map((image: string, idx: number) => (
              <ProfileImage
                moveValue={`${idx}0px`}
                profileName={row.original.consultants[idx]}
                imageIdx={`${idx + 1}`}
                key={idx}
              />
            ))}
          </ConsultantContainer>
        ),
      },
      {
        header: 'Projects',
        accessorKey: 'clientId',
        enableSorting: false,
        size: 1,
        cell: () => (
          <div>
            <button
              onKeyDown={() => alert('To Projects Page!')}
              onClick={() => alert('To Projects Page!')}
              {...{
                style: { cursor: 'pointer' },
              }}
            >
              <ProjectIcon />
            </button>
          </div>
        ),
      },
      {
        header: ' ',
        accessorKey: 'action',
        enableSorting: false,
        maxSize: 40,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <button
              onClick={() => {
                setSelectedData(row.original);
                setModalIsOpen(true);
              }}
            >
              <EditIcon />
            </button>
          </div>
        ),
      },
    ];
  }

  // Column Info State
  const [columnInfo, setColumnInfo] = useState(createColumnInfo());

  // Update Column Info when translation changes
  useEffect(() => {
    const newColumnInfo = createColumnInfo();
    setColumnInfo(newColumnInfo);
  }, [translate]);

  // Fetch client data
  const { handleGetClients, clientListIsLoading, clientsFetchingError } =
    useClientDataContext();
  const rawClientData = handleGetClients() || [];

  // Fetch user data
  const { handleGetUser, isGetUserFetching, isGetUserLoading } =
    useUserDataContext();
  const rawUserData: Item[] | null = handleGetUser();

  // Extract client IDs from user projects if user data exists
  const userClientsIds =
    rawUserData && Array.isArray(rawUserData)
      ? getClientIdsFromProjects(
          findItemsByTypename(rawUserData, 'ref:project'),
        )
      : [];

  // Filter clients to display based on user client IDs
  const clientsToBeDisplayed =
    rawUserData && rawClientData && userClientsIds !== null
      ? getItemsByIds(rawClientData, userClientsIds)
      : [];

  // Helper function to filter items by IDs
  function getItemsByIds(data: NewClientDataType[], ids: (string | null)[]) {
    return data.filter((item) => ids.includes(item.clientId));
  }

  // Determine final client data to be displayed
  let clientData: NewClientDataType[];
  if (rawClientData) {
    clientData = clientsToBeDisplayed;
  }

  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMobileWidth(windowSize.width <= 768);
    setIsDesktopWidth(windowSize.width > LG);

    setColumnsVisibility({
      contactName: !isMobileWidth,
      phone: isDesktopWidth,
      email: isDesktopWidth,
      city: isDesktopWidth,
    });
    return () => {};
  }, [windowSize.width]);

  return (
    <>
      <PageWrapper>
        <Container width="100%" margin="0">
          <PageTitle title={translate?.('myClients')} />
          {/*Todo : Verify if we will create a variant for the header typography according to Carls PR - instead of using a component*/}
        </Container>
        <Container
          width="100%"
          maxWidth={'1023px'}
          //This width was defined by Ana Maria
          flexDirection="row"
          margin={`${spacingWithPx.xxl} 0 0 0`}
        >
          <DisplayTable
            filtering={filtering}
            setFiltering={setFiltering}
            data={clientData!}
            columns={columnInfo}
            getRowCanExpand={() => true}
            columnsVisibility={columnsVisibility}
            showFooter
            loadingItem={
              clientListIsLoading ? (
                <LoadingComponent size={'50'} />
              ) : clientsFetchingError ? (
                'There was an error'
              ) : undefined
            }
            RenderSubComponent={RenderSubComponent}
            subComponentHeaders={subComponentHeaders}
          />
        </Container>
      </PageWrapper>
      {selectedData ? (
        <ClientModalForm
          data={clientData!}
          selectedData={selectedData}
          isOpen={modalIsOpen}
          toggleModal={setModalIsOpen}
        />
      ) : null}
    </>
  );
};
