import { colors } from 'utils/colors';

export const CapsLockWarning = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M8 14.3501C11.3137 14.3501 14 11.6638 14 8.3501C14 5.03639 11.3137 2.3501 8 2.3501C4.68629 2.3501 2 5.03639 2 8.3501C2 11.6638 4.68629 14.3501 8 14.3501Z"
      stroke={color ? color : colors.warningYellow}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 7.8501H8V11.3501H8.5"
      stroke={color ? color : colors.warningYellow}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6.3501C8.41421 6.3501 8.75 6.01431 8.75 5.6001C8.75 5.18588 8.41421 4.8501 8 4.8501C7.58579 4.8501 7.25 5.18588 7.25 5.6001C7.25 6.01431 7.58579 6.3501 8 6.3501Z"
      fill={color ? color : colors.warningYellow}
    />
  </svg>
);
