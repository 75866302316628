export const MobileNotificationIcon = () => (
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 19.1667C8.53125 19.1667 9.375 18.3206 9.375 17.2864H5.625C5.625 18.3206 6.45937 19.1667 7.5 19.1667ZM13.125 13.5257V8.82483C13.125 5.9385 11.5875 3.52226 8.90625 2.88295V2.24363C8.90625 1.46329 8.27812 0.833374 7.5 0.833374C6.72188 0.833374 6.09375 1.46329 6.09375 2.24363V2.88295C3.40313 3.52226 1.875 5.9291 1.875 8.82483V13.5257L0 15.406V16.3462H15V15.406L13.125 13.5257Z"
      fill="#CAD4DB"
    />
  </svg>
);
