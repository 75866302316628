import React from 'react';
import { Container } from 'pages/dashboard/styled';
import { useTranslation } from 'react-i18next';
import {
  CardText,
  CenterWrapper,
  ThankYouContainer,
  ThankYouTextTitle,
} from 'pages/request-account/components/styled';
export function ThankYouDisplay(): JSX.Element {
  const translation = useTranslation();
  const { t: translate } = translation;

  return (
    <CenterWrapper>
      <Container flexDirection={'column'}>
        <ThankYouContainer>
          <ThankYouTextTitle>
            {translate?.('thankYouDisplayTitle')}
          </ThankYouTextTitle>
          <CardText>{translate?.('thankYouDisplayMessage')}</CardText>
        </ThankYouContainer>
      </Container>
    </CenterWrapper>
  );
}
