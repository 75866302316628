import { colors } from 'utils/colors';

export const NotesIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30347 9.6836C1.30347 5.41461 4.75107 1.95703 9.00005 1.95703C13.249 1.95703 16.6966 5.41461 16.6966 9.6836C16.6966 13.9526 13.249 17.4102 9.00005 17.4102C7.82563 17.4102 6.71391 17.1465 5.71927 16.6752C5.60731 16.6221 5.47965 16.6126 5.36103 16.6483L2.19244 17.6023L3.60855 15.8257C3.7672 15.6267 3.75101 15.3402 3.57095 15.1603C2.17003 13.7607 1.30347 11.8244 1.30347 9.6836ZM9.00005 0.957031C4.19534 0.957031 0.303467 4.86577 0.303467 9.6836C0.303467 11.9383 1.15624 13.9943 2.5552 15.5429L0.412478 18.2311C0.276143 18.4021 0.266809 18.6419 0.389435 18.823C0.512062 19.0042 0.738183 19.0845 0.947624 19.0215L5.46817 17.6604C6.54797 18.1424 7.74346 18.4102 9.00005 18.4102C13.8048 18.4102 17.6966 14.5014 17.6966 9.6836C17.6966 4.86577 13.8048 0.957031 9.00005 0.957031ZM5.84814 7.28476C5.572 7.28476 5.34814 7.50862 5.34814 7.78476C5.34814 8.0609 5.572 8.28476 5.84814 8.28476H12.1532C12.4294 8.28476 12.6532 8.0609 12.6532 7.78476C12.6532 7.50862 12.4294 7.28476 12.1532 7.28476H5.84814ZM5.84814 9.81629C5.572 9.81629 5.34814 10.0401 5.34814 10.3163C5.34814 10.5924 5.572 10.8163 5.84814 10.8163H12.1532C12.4294 10.8163 12.6532 10.5924 12.6532 10.3163C12.6532 10.0401 12.4294 9.81629 12.1532 9.81629H5.84814ZM5.34814 12.8469C5.34814 12.5708 5.572 12.3469 5.84814 12.3469H12.1532C12.4294 12.3469 12.6532 12.5708 12.6532 12.8469C12.6532 13.1231 12.4294 13.3469 12.1532 13.3469H5.84814C5.572 13.3469 5.34814 13.1231 5.34814 12.8469Z"
      fill={color ? color : colors.lighterSecondaryBlue}
    />
  </svg>
);
