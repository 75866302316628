export const MobileUserIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 0.5C3.36 0.5 0 3.86 0 8C0 12.14 3.36 15.5 7.5 15.5C11.64 15.5 15 12.14 15 8C15 3.86 11.64 0.5 7.5 0.5ZM7.5 2.75C8.745 2.75 9.75 3.755 9.75 5C9.75 6.245 8.745 7.25 7.5 7.25C6.255 7.25 5.25 6.245 5.25 5C5.25 3.755 6.255 2.75 7.5 2.75ZM7.5 13.4C5.625 13.4 3.9675 12.44 3 10.985C3.0225 9.4925 6 8.675 7.5 8.675C8.9925 8.675 11.9775 9.4925 12 10.985C11.0325 12.44 9.375 13.4 7.5 13.4Z"
      fill="#CAD4DB"
    />
  </svg>
);
