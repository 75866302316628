import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { findClientNameById } from 'utils/find-client-name-by-id';
import { NewClientDataType } from 'pages/clients/api/get-clients';
import { Task } from '../../../react-query-toolkit/state/types';

export function formatTaskData(
  data: Task[] = [], // Default to an empty array if data is undefined
  clientData?: NewClientDataType[],
): NewTaskDataType[] {
  if (!Array.isArray(data)) {
    console.error('Expected data to be an array, but received:', data);
    return []; // Return an empty array if data is not an array
  }

  return data
    .map((task: Task) => ({
      // Map task properties to the new format
      taskId: task.id, // Adjusted to `id` based on your sample data
      taskName: task.name,
      priority: task.priority,
      dueDate: task.duedate,
      notes: task.description,
      isCompleted: task.completed,
      clientId: task.clientId,

      // Get client name if client data is provided
      clientName: clientData
        ? findClientNameById(task.clientId, clientData)
        : '',
    }))
    .reverse(); // Reverse the array order at the end
}
