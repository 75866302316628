import { Checkbox } from '@mui/material';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { DisplayTable } from '../../clients/components/clients-table';
import {
  DisplayTableTaskCell,
  FlagPriorityHandlerContainer,
} from '../../clients/components/clients-table/styled';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { DataVisibilityType } from 'pages/clients/api/get-clients';
import TaskTableSubComponent from 'pages/task-manager/components/task-table-sub-component';
import dayjs from 'dayjs';
import {
  AccordionContainer,
  CellContainer,
  ClientIconContainer,
  ClientInfoContainer,
  DescriptionIconContainer,
  HeaderContainer,
  HeaderTitle,
  ModalActionText,
  ModalText,
  TableCellTextContainer,
  TableContainer,
  TaskHeaderContainer,
  TitleContainer,
  TodayTaskContainer,
  UndoContainer,
} from 'pages/task-manager/components/styled';
import { Calendar, DeleteIcon } from 'icons';
import { flagPriorityHandler } from 'pages/task-manager/components/utils';
import { colors } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import { useIsWindowWidthMobile } from 'utils/hooks/useIsWindowWidthMobile';
import { useTaskDataContext } from '../../../react-query-toolkit/state/tasks-context';
import { EditIcon } from 'icons/edit-icon';
import { DescriptionTableIcon } from 'icons/description-table-icon';
import { MD } from 'utils/breakpoints';
import { getWindowDimensions } from 'utils/window-utils';
import LoadingComponent from 'components/loading-icon-component';
import { formatTaskData } from 'pages/task-manager/api/format-task-data';
import { ClientIcon } from 'icons/client-icon';
import { findClientNameById } from 'utils/find-client-name-by-id';
import { CopyToClipboardTruncated } from 'utils/copy-to-clipboard-truncated';
import { CopyToClipBoard } from 'utils/copy-to-clipboard';
import { useClientDataContext } from '../../../react-query-toolkit/state/client-context';

export interface todayTaskType {
  setSelectedData?: Dispatch<SetStateAction<NewTaskDataType | null>>;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  setModalType: Dispatch<SetStateAction<string>>;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
  globalFilter: string;
}

export const OverDueTasks = (props: todayTaskType) => {
  const {
    handleGetTasks,
    tasksFetchingError,
    tasksListIsLoading,
    handleDeleteTask,
    handleCreateTask,
    handleUpdateTask,
  } = useTaskDataContext();
  const { handleGetClients } = useClientDataContext();

  const clientData = handleGetClients() || [];
  const rawTaskData = handleGetTasks() || [];
  const [taskListOpen, setTaskListOpen] = useState(true);
  let taskData: NewTaskDataType[];
  if (rawTaskData.length > 0 || clientData) {
    taskData = filterOverdueData(
      filterUncompletedData(formatTaskData(rawTaskData, clientData)),
    );
  }
  function filterOverdueData(data: NewTaskDataType[]) {
    const today = dayjs().startOf('day'); // Get today's date at the start of the day
    return data.filter((item) => dayjs(item.dueDate).isBefore(today));
  }

  function countOverdueUncompletedTasks(data: NewTaskDataType[]): number {
    const today = dayjs().startOf('day'); // Get today's date at the start of the day
    return data.filter(
      (item) => dayjs(item.dueDate).isBefore(today) && !item.isCompleted,
    ).length;
  }
  function filterUncompletedData(data: NewTaskDataType[]) {
    return data.filter((item) => !item.isCompleted);
  }

  const overDueTasksCount = countOverdueUncompletedTasks(
    formatTaskData(rawTaskData, clientData),
  );

  const [deletedTask, setDeletedTask] = useState<NewTaskDataType | null>(null);
  const { isMobileWidth, windowSizeWidth } = useIsWindowWidthMobile();
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isTableWidth, setIsTableWidth] = useState<boolean>(
    windowSize.width > MD,
  );

  const { t: translate } = useTranslation();

  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      priority: !isMobileWidth,
      notes: isTableWidth,
      clientId: isTableWidth,
      dueDate: !isMobileWidth,
      taskId: false,
      clientName: false,
    });

  const [undoTimer, setUndoTimer] = useState<NodeJS.Timeout | null>(null);

  const columns: ColumnDef<NewTaskDataType>[] = [
    {
      header: () => (
        <TaskHeaderContainer>{translate?.('headerTask')}</TaskHeaderContainer>
      ),
      accessorKey: 'taskName',
      size: 120,
      cell: ({ row }) => (
        <DisplayTableTaskCell
        // onClick={row.getToggleExpandedHandler()}
        // rowIsSelected={row.getIsSelected()}
        // Todo - Verify this can be deleted as I cannot test because API was changed. Apply to the other task tables if needed.
        >
          <Checkbox
            onClick={() => {
              row.toggleSelected();
              handleUpdateTask(
                row.original.taskId!,
                row.original.taskName,
                row.original.priority,
                !row.original.isCompleted,
                row.original.dueDate,
                row.original.notes,
                row.original.clientId,
              );
            }}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            sx={{
              color: colors.red,
              '&.Mui-checked': {
                color: colors.red,
              },
            }}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            size="medium"
          />
          <CopyToClipboardTruncated copyText={row.original.taskName}>
            {row.original.taskName}
          </CopyToClipboardTruncated>
        </DisplayTableTaskCell>
      ),
    },
    {
      header: '',
      accessorKey: 'notes',
      size: 1,
      maxSize: 5,
      enableSorting: false,
      cell: ({ row }) => (
        <DescriptionIconContainer>
          <CopyToClipBoard
            copyText={row.original.notes}
            cursorPointer
            onClick={() => row.getToggleExpandedHandler()}
          >
            <DescriptionTableIcon />
          </CopyToClipBoard>
        </DescriptionIconContainer>
      ),
    },
    {
      header: 'Client',
      accessorKey: 'clientId',
      size: 100,
      cell: ({ row }) => {
        return (
          <ClientInfoContainer>
            <ClientIconContainer>
              <ClientIcon />
            </ClientIconContainer>{' '}
            {clientData ? (
              <CopyToClipboardTruncated
                copyText={findClientNameById(row.original.clientId, clientData)}
              >
                {findClientNameById(row.original.clientId, clientData)}
              </CopyToClipboardTruncated>
            ) : null}
          </ClientInfoContainer>
        );
      },
    },
    {
      header: `${translate?.('headerPriority')}`,
      accessorKey: 'priority',
      cell: ({ row }) => (
        <>
          <FlagPriorityHandlerContainer
            onClick={row.getToggleExpandedHandler()}
            onKeyDown={row.getToggleExpandedHandler()}
          >
            <CellContainer>
              {flagPriorityHandler(row.original.priority)}
              <TableCellTextContainer>
                {row.original.priority}
              </TableCellTextContainer>
            </CellContainer>
          </FlagPriorityHandlerContainer>
        </>
      ),
      size: 1,
    },
    {
      header: `${translate?.('headerDueDate')}`,
      accessorKey: 'dueDate',
      size: 50,
      cell: ({ row }) => (
        <CellContainer
          tabIndex={0}
          role="button"
          onClick={row.getToggleExpandedHandler()}
          onKeyDown={row.getToggleExpandedHandler()}
        >
          <Calendar
            width={14}
            height={14}
            color={colors.lighterSecondaryBlue}
          />
          <TableCellTextContainer>
            {dayjs(row.original.dueDate).format('MMMM D, YYYY')}
          </TableCellTextContainer>
        </CellContainer>
      ),
    },
    {
      header: `${translate?.('headerEdit')}`,
      size: 60,
      cell: ({
        table,
        row,
      }: {
        table: Table<NewTaskDataType>;
        row: Row<NewTaskDataType>;
      }) => (
        <DisplayTableTaskCell
          tabIndex={0}
          role="button"
          onClick={row.getToggleExpandedHandler()}
          onKeyDown={row.getToggleExpandedHandler()}
        >
          <button
            onKeyDown={() => {
              props.setSelectedData && props.setSelectedData(row.original);
              props.setModalIsOpen(true);
              props.setModalType('update');
            }}
            onClick={() => {
              props.setSelectedData && props.setSelectedData(row.original);
              props.setModalIsOpen(true);
              props.setModalType('update');
            }}
          >
            <EditIcon />
          </button>
          <button
            onKeyDown={() => handlerDelete(table, row)}
            {...{
              style: { cursor: 'pointer' },
            }}
            onClick={() => handlerDelete(table, row)}
            {...{
              style: { cursor: 'pointer' },
            }}
          >
            <DeleteIcon />
          </button>
        </DisplayTableTaskCell>
      ),
    },
    {
      header: 'clientName', // This Column is hidden by default but it's used to search by clientName
      accessorKey: 'clientName',
      size: 0,
    },
    {
      header: 'taskId', // This Column is hidden by default but it's used to search by taskID
      accessorKey: 'taskId',
      size: 0,
    },
  ];

  const undoCompletedTask = () => {
    ///Here send request to Server to change task to isCompleted =false; Get response and if good then continue
    if (deletedTask) {
      handleCreateTask(
        deletedTask.taskName,
        deletedTask.priority,
        deletedTask.dueDate,
        deletedTask.notes,
        deletedTask.clientId,
      );
      setDeletedTask(null);
      clearTimeout(undoTimer!);
    }
  };

  const handlerDelete = (
    table: Table<NewTaskDataType>,
    row: Row<NewTaskDataType>,
  ) => {
    //In case of a previous delete in progress.
    clearTimeout(undoTimer!);
    setDeletedTask(null);
    row.original.isCompleted = !row.original.isCompleted;
    handleDeleteTask(row.original.taskId!);

    row.original.isCompleted = !row.original.isCompleted;
    setDeletedTask(row.original);
    table.resetRowSelection();
    table.toggleAllPageRowsSelected(false);

    const timer = setTimeout(() => {
      // If timer expires, remove the deleted item
      setDeletedTask(null);
    }, 5000);
    setUndoTimer(timer);

    setTimeout(() => {
      //Handles Expansion of the cell by interaction of delete button.
      table.resetExpanded(true);
    });
  };

  useEffect(() => {
    return () => {
      // Clear the undo timer when component unmounts
      clearTimeout(undoTimer!);
    };
  }, [undoTimer]);

  useEffect(() => {
    setIsTableWidth(windowSize.width >= MD);
    setColumnsVisibility({
      priority: !isMobileWidth,
      notes: isTableWidth,
      clientId: isTableWidth,
      dueDate: !isMobileWidth,
      taskId: false,
      clientName: false,
    });
    return () => {};
  }, [windowSizeWidth, windowSize.width]);

  const displayTableNoItemsMessage = translate?.('displayTableNoItemsMessage');

  return (
    <>
      <TodayTaskContainer>
        {deletedTask ? (
          <UndoContainer>
            <ModalText>{translate?.('taskCompletedMessage')}</ModalText>

            <ModalActionText
              onClick={() => {
                undoCompletedTask();
              }}
            >
              {translate?.('undoTaskDeleteTitle')}
            </ModalActionText>
          </UndoContainer>
        ) : null}
        <HeaderContainer>
          <TitleContainer>
            <HeaderTitle
              color={colors.red}
              onClick={() => setTaskListOpen(!taskListOpen)}
            >
              {`${taskListOpen ? '-' : '+'} ${translate?.('overDueTitle')} (${overDueTasksCount})`}
            </HeaderTitle>{' '}
          </TitleContainer>
        </HeaderContainer>
        <AccordionContainer padding={'14px 0 0 0'} isOpen={taskListOpen}>
          <TableContainer>
            <DisplayTable
              data={taskData!}
              columns={columns}
              getRowCanExpand={() => true}
              columnsVisibility={columnsVisibility}
              showFooter={false}
              RenderSubComponent={TaskTableSubComponent}
              loadingItem={
                tasksListIsLoading ? (
                  <LoadingComponent size={'50'} />
                ) : tasksFetchingError ? (
                  displayTableNoItemsMessage
                ) : undefined
              }
              hideSubComponentFromSizeMd
              setGlobalFilter={props.setGlobalFilter}
              globalFilter={props.globalFilter}
            />
          </TableContainer>
        </AccordionContainer>
      </TodayTaskContainer>
    </>
  );
};
