import React from 'react';
import { Box } from '@mui/material';
import { GhgciTextLogo, GHGLogo } from 'icons';

interface LogoContainerProps {
  onClick?: () => void;
}

export function LogoContainer({ onClick }: LogoContainerProps) {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
      gap={3}
      onClick={onClick}
    >
      <GHGLogo />
      <GhgciTextLogo />
    </Box>
  );
}
