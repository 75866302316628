import { noop } from 'lodash';
import { ReactNode, createContext, useContext, useState } from 'react';

type NavAndSideBarContext = {
  showSideBar: boolean;
  setShowSideBar: typeof noop;
  showMenuBar: boolean;
  setShowMenuBar: typeof noop;
};

const defaultNavAndSideBarContext: NavAndSideBarContext = {
  showSideBar: false,
  setShowSideBar: noop,
  showMenuBar: false,
  setShowMenuBar: noop,
};

export const NavAndSideBarContext = createContext(defaultNavAndSideBarContext);

export const NavAndSideBarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);

  const value = {
    showSideBar,
    setShowSideBar,
    showMenuBar,
    setShowMenuBar,
  };

  return (
    <NavAndSideBarContext.Provider value={value}>
      {children}
    </NavAndSideBarContext.Provider>
  );
};

export const useNavAndSideBarContext = () => {
  return useContext(NavAndSideBarContext);
};
