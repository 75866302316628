import { colors } from 'utils/colors';
import { GenericProps } from './types';

export const Report = ({ color }: GenericProps) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52175 0C2.36063 0 0.608704 1.75193 0.608704 3.91304V11.7391C0.608704 13.9002 2.36063 15.6522 4.52175 15.6522H15.4783C17.6394 15.6522 19.3913 13.9002 19.3913 11.7391V3.91304C19.3913 1.75193 17.6394 0 15.4783 0H4.52175ZM4.52175 1.56522C3.22508 1.56522 2.17392 2.61637 2.17392 3.91304V11.7391C2.17392 13.0358 3.22508 14.087 4.52175 14.087H15.4783C16.7749 14.087 17.8261 13.0358 17.8261 11.7391V3.91304C17.8261 2.61637 16.7749 1.56522 15.4783 1.56522H4.52175ZM4.52175 17.2174C4.52175 16.7852 4.87213 16.4348 5.30436 16.4348H14.6957C15.1279 16.4348 15.4783 16.7852 15.4783 17.2174C15.4783 17.6496 15.1279 18 14.6957 18H5.30436C4.87213 18 4.52175 17.6496 4.52175 17.2174Z"
      fill={color ?? colors.lighterSecondaryBlue}
    />
  </svg>
);
