import { UtcTimeZonesOptions } from '../types';

export const normalizeTimezones = (
  timezoneOffsetName: string,
  timezoneObject: UtcTimeZonesOptions[],
) => {
  const matchedTimezone = timezoneObject.find((zone: UtcTimeZonesOptions) =>
    zone.value.includes(timezoneOffsetName),
  );

  return matchedTimezone?.iana as string;
};
