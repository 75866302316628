import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    userPoolId: 'ca-central-1_HvrtuqWfp',
    userPoolWebClientId: '3qj8c6qrh0n4u8fhvlfekro25e',
    identityPoolId: 'ca-central-1:676fdb47-e6f2-4097-96a8-712a84a86597',
    // userPoolId: 'ca-central-1_ngqxA8YEi',
    // userPoolWebClientId: '5tfnivdb5l7gpu865meqm4mhjl',
    // identityPoolId: 'ca-central-1:7a2888b8-2714-456d-b986-b50483979222',
    region: 'ca-central-1',
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: {
      domain: document.location.hostname,
      secure: false,
      path: '/',
      expires: 365,
    },
  },
  Storage: {
    AWSS3: {
      bucket: 'ghgci-assets', // Default bucket in case env var is missing
      region: 'ca-central-1',
    },
  },
  Analytics: {
    disabled: true,
  },
});
