import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguagesExample = () => {
  const translation = useTranslation();
  const { i18n, t } = translation;
  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div>
      <select onChange={handleLanguageChange}>
        <option value="en">English</option>
        <option value="fr">French</option>
      </select>
      <div>
        <h1>{t('greeting')}</h1>
        <p>{t('message')}</p>
      </div>
    </div>
  );
};
