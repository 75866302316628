import { Divider, Span, useNavAndSideBarContext } from 'components';
import { spacingWithPx } from 'utils/styles';
import { MobileArrowRightIcon } from './icons/mobile-arrow-right';
import { MobileCalculatorIcon } from './icons/mobile-calculator-icon';
import { MobileCalendarIcon } from './icons/mobile-calendar-icon';
import { MobileNotificationIcon } from './icons/mobile-notification-icon';
import { MobileUserIcon } from './icons/mobile-user-icon';
import { Flex, MobileMenuContainer, NavBarItem, NavBarList } from './styled';
import useAuth from 'configs/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetUserFromEmail } from 'utils/get-user-from-email';

const menuItems = {
  'My Profile': MobileUserIcon,
  Calendar: MobileCalendarIcon,
  Notifications: MobileNotificationIcon,
  Reports: MobileCalculatorIcon,
  'Sign Out': MobileArrowRightIcon,
};

type Props = {
  showMenuBar: boolean;
};

export const MobileMenu = ({ showMenuBar }: Props) => {
  //TODO: - the signout function is temporary. We have to replace the all buttons onClick on a different PR
  const navigate = useNavigate();
  const auth = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState('');
  const { setShowMenuBar, setShowSideBar } = useNavAndSideBarContext();

  function signOutHandler() {
    try {
      auth.signout(() => navigate('/login'));
      setShowMenuBar(false);
      setShowSideBar(false);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  useEffect(() => {
    const currentUserEmail =
      auth.user?.getSignInUserSession()?.getIdToken().payload.email || '';
    setUserName(GetUserFromEmail(currentUserEmail));
  }, [auth.user]);

  return (
    <>
      {auth.user && (
        <MobileMenuContainer
          id="mobile-menu-container"
          className={showMenuBar ? 'show' : ''}
        >
          <Flex
            flexDirection="column"
            padding={`${spacingWithPx.md} ${spacingWithPx.sm}`}
          >
            <Flex flexDirection="column">
              <Span fontSize="18px" color="secondaryBlue" fontWeight="600">
                {userName}
              </Span>
              <Span fontSize="14px" color="black">
                Consultant
              </Span>
            </Flex>
            <Divider />
            <Flex flexDirection="column">
              <NavBarList>
                {Object.entries(menuItems).map(([key, Value], index) => {
                  const hasEndBorder =
                    index === Object.keys(menuItems).length - 2;
                  return (
                    <div key={`${key}-${index}`}>
                      <NavBarItem
                        key={key}
                        onClick={() => {
                          if (key === 'Sign Out') {
                            signOutHandler();
                          }
                        }}
                      >
                        {error && error}
                        <div>
                          <Value />
                          <Span color="secondaryBlue">{key}</Span>
                        </div>
                      </NavBarItem>
                      {hasEndBorder ? <Divider /> : null}
                    </div>
                  );
                })}
              </NavBarList>
            </Flex>
          </Flex>
        </MobileMenuContainer>
      )}
    </>
  );
};
