import { colors } from 'utils/colors';

export const Notifications = ({ color }: { color: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.35714 0C7.64706 0 7.07143 0.575634 7.07143 1.28571V1.92857C7.07143 2.02633 7.08234 2.12154 7.10301 2.21304C4.47857 3.0224 2.57143 5.46705 2.57143 8.35714C2.57143 8.52188 2.57763 8.68517 2.5898 8.8468C2.57779 8.89589 2.57143 8.9472 2.57143 9V11.8929C2.57143 12.0952 2.47616 12.2857 2.31429 12.4071L0.257143 13.95C0.0952678 14.0714 0 14.2619 0 14.4643V14.7857C0 15.1408 0.287817 15.4286 0.642857 15.4286H17.3571C17.7122 15.4286 18 15.1408 18 14.7857V14.4643C18 14.2619 17.9047 14.0714 17.7429 13.95L15.6857 12.4071C15.5238 12.2857 15.4286 12.0952 15.4286 11.8929V9C15.4286 8.9472 15.4222 8.89589 15.4102 8.8468C15.4224 8.68517 15.4286 8.52188 15.4286 8.35714C15.4286 5.46705 13.5214 3.0224 10.897 2.21304C10.9177 2.12154 10.9286 2.02633 10.9286 1.92857V1.28571C10.9286 0.575634 10.3529 0 9.64286 0H8.35714Z"
      fill={color ?? colors.lighterSecondaryBlue}
    />
    <path
      d="M6.75 16.0714H11.25C11.4275 16.0714 11.5676 16.2158 11.5276 16.3888C11.4012 16.9362 10.9098 18 9 18C7.09024 18 6.59883 16.9362 6.47238 16.3888C6.43243 16.2158 6.57248 16.0714 6.75 16.0714Z"
      fill={color ?? colors.lighterSecondaryBlue}
    />
  </svg>
);
