import { NewProjectDataType } from 'pages/projects/get-projects';
import {
  NewProjectStep,
  Project,
  ProjectAssigneeRole,
  ProjectComment,
  ProjectPLResponse,
  ProjectStep,
  ProjectText,
} from '../../../react-query-toolkit/state/types';

export default function refactorProjectPlData(
  data: ProjectPLResponse,
  projectId: string,
): NewProjectDataType {
  if (!data || !Array.isArray(data)) {
    return {
      projectId: '',
      projectClientId: '',
      projectJurisdiction: '',
      projectMandate: '',
      projectSteps: [],
      projectComments: [],
      projectCurrentStep: '',
      projectAssignees: [],
      projectDueDate: '',
      successCriteria: [],
      applicableStandard: [],
      intendedUse: [],
      projectType: '',
      projectPeriod: '',
      nextProject: '',
      accountManager: '',
      previousProject: '',
    };
  }

  const projectStepsOrder = [
    'set-up',
    'data-collection',
    'research',
    'calculation',
    'calculation-review',
    'draft-report',
    'report-review',
    'client-review',
    'finalize-report',
    'close-project',
  ];

  // Initialize project steps with empty assignees array
  const projectSteps: NewProjectStep[] = data
    .filter((item): item is ProjectStep => item.typename === 'projectstep')
    .map((step) => ({
      sk_id: step.sk_id || '',
      name: step.name,
      duedate: step.duedate,
      status: step.status,
      assignees: [],
    }))
    .sort(
      (a, b) =>
        projectStepsOrder.indexOf(a.name) - projectStepsOrder.indexOf(b.name),
    );

  // Filter and sort project comments
  const projectComments: ProjectComment[] = data
    .filter((item): item is ProjectComment => item.typename === 'comment')
    .map((comment) => ({
      id: comment.id,
      typename: comment.typename,
      created_at: comment.created_at,
      author: comment.author,
      parent: comment.parent,
      projectstep: comment.projectstep,
      read: comment.read,
      tags: comment.tags,
      text: comment.text,
      checked: comment.checked,
    }));

  // Categorize project texts by language
  const successCriteriaTexts: { language: string; text: string }[] = [];
  const applicableStandardTexts: { language: string; text: string }[] = [];
  const intendedUseTexts: { language: string; text: string }[] = [];

  data
    .filter((item): item is ProjectText => item.typename === 'projecttext')
    .forEach((text) => {
      successCriteriaTexts.push({
        language: text.language,
        text: text.success_criteria,
      });
      applicableStandardTexts.push({
        language: text.language,
        text: text.applicable_standard,
      });
      intendedUseTexts.push({
        language: text.language,
        text: text.intended_use,
      });
    });

  // Assign assignees to project steps
  data
    .filter(
      (item): item is ProjectAssigneeRole =>
        item.typename === 'project_assignee_role',
    )
    .forEach((role) => {
      if (role.role === '0') {
        // Role '0' means assigned to all steps
        projectSteps.forEach((step) => {
          step.assignees.push(role.user);
        });
      } else {
        // Find the corresponding project step by matching role index to projectStepsOrder
        const stepName = projectStepsOrder[parseInt(role.role, 10) - 1];
        const matchingStep = projectSteps.find(
          (step) => step.name === stepName,
        );
        if (matchingStep) {
          matchingStep.assignees.push(role.user);
        }
      }
    });

  // Extract main project data
  const mainProject = data.find(
    (item): item is Project => item.typename === 'project',
  );

  return {
    projectId: projectId || '',
    projectClientId: mainProject?.client || '',
    projectMandate: mainProject?.mandate || '',
    projectSteps: projectSteps,
    projectComments: projectComments,
    projectCurrentStep: mainProject?.step || '',
    projectJurisdiction: mainProject?.jurisdiction || '',
    projectDueDate: mainProject?.duedate || '',
    projectType: mainProject?.projecttype || '',
    projectPeriod: mainProject?.period || '',
    nextProject: mainProject?.next_project || '',
    accountManager: mainProject?.accountmanager || '',
    previousProject: mainProject?.previous_project || '',

    projectAssignees: data
      .filter(
        (item): item is ProjectAssigneeRole =>
          item.typename === 'project_assignee_role',
      )
      .map((role) => role.user),

    successCriteria: successCriteriaTexts,
    applicableStandard: applicableStandardTexts,
    intendedUse: intendedUseTexts,
  };
}
