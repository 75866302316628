export const ThreeDots = () => (
  <svg
    width="4"
    height="14"
    viewBox="0 0 4 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.11466 3.66666C3.03133 3.66666 3.78133 2.91666 3.78133 1.99999C3.78133 1.08333 3.03133 0.333328 2.11466 0.333328C1.198 0.333328 0.447998 1.08333 0.447998 1.99999C0.447998 2.91666 1.198 3.66666 2.11466 3.66666ZM2.11466 5.33333C1.198 5.33333 0.447998 6.08333 0.447998 6.99999C0.447998 7.91666 1.198 8.66666 2.11466 8.66666C3.03133 8.66666 3.78133 7.91666 3.78133 6.99999C3.78133 6.08333 3.03133 5.33333 2.11466 5.33333ZM2.11466 10.3333C1.198 10.3333 0.447998 11.0833 0.447998 12C0.447998 12.9167 1.198 13.6667 2.11466 13.6667C3.03133 13.6667 3.78133 12.9167 3.78133 12C3.78133 11.0833 3.03133 10.3333 2.11466 10.3333Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
