import { DisplayTable } from '../../clients/components/clients-table';
import { ConsultantContainer } from '../../clients/components/clients-table/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { DataVisibilityType } from 'pages/clients/api/get-clients';
import { TableContainer } from 'pages/task-manager/components/styled';
import { epochToDate } from 'utils/epoch-to-date';
import { useTranslation } from 'react-i18next';
import { ProfileImage } from 'pages/clients/components/clients-table/profile-image';
import { colors } from 'utils/colors';
import {
  FullContainer,
  ProjectHeaderContainer,
  StatusContainer,
} from 'pages/projects/components/styled';
import ProjectTableSubComponent from 'pages/projects/components/project-table-sub-component';
import { MD } from 'utils/breakpoints';
import { getWindowDimensions } from 'utils/window-utils';
import LoadingComponent from 'components/loading-icon-component';
import { CommentsIcon } from 'icons/comments-icon';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Badge,
} from '@mui/material';
import { Calendar } from 'icons';
import { NewProjectStep } from '../../../react-query-toolkit/state/types';
import { useProjectsDataContext } from '../../../react-query-toolkit/state/projects-context';
import {
  DateSelector,
  ReceivedValue,
} from 'components/date-picker/components/date-selector';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { getCurrentProjectStep } from 'utils/get-project-current-step';

type ProjectTableProps = {
  setCommentsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStepComment: React.Dispatch<React.SetStateAction<string>>;
};

const statuses = [
  { value: 'not-started', label: 'Not Started', color: 'secondary.dark' },
  { value: 'on-hold', label: 'On Hold', color: 'error.main' },
  { value: 'in-progress', label: 'In Progress', color: 'primary.dark' },
  { value: 'done', label: 'Done', color: 'success.main' },
];

export const ProjectTable = (props: ProjectTableProps) => {
  const { setCommentsOpen, setStepComment } = props;
  const { t: translate } = useTranslation();

  const {
    handleGetProject,
    isGetProjectsLoading,
    handleUpdateProject,
    handleUpdateProjectStep,
    updateProjectError,
    resetUpdateProjectQueryValues,
  } = useProjectsDataContext();

  const { projectId } = useParams<{ projectId: string }>();

  const rawProjectData = handleGetProject() || {};
  const projectStepsData: NewProjectStep[] = rawProjectData.projectSteps || [];

  useEffect(() => {
    console.log(rawProjectData);
  }, [rawProjectData]);

  const [data, setData] = useState<NewProjectStep[]>(projectStepsData);
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isTableWidth, setIsTableWidth] = useState<boolean>(
    windowSize.width > MD,
  );
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      assignees: isTableWidth,
      dueDate: isTableWidth,
    });

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
    resetUpdateProjectQueryValues(); // Reset error state after closing dialog
  };

  // Window resize handling
  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsTableWidth(windowSize.width >= MD);
    setColumnsVisibility({
      projectAssignees: isTableWidth,
      dueDate: isTableWidth,
    });
  }, [windowSize.width]);

  const stepOrder = [
    'set-up',
    'data-collection',
    'research',
    'calculation',
    'calculation-review',
    'draft-report',
    'report-review',
    'client-review',
    'finalize-report',
    'close-project',
  ];

  // Function to get the `ps#(number)` based on the step name
  function getTheSk_id(stepName: string): string {
    const stepIndex = stepOrder.indexOf(stepName);
    if (stepIndex !== -1) {
      return `${stepIndex + 1}`;
    }
    return 'Step not found';
  }

  const handleStatusChange = async (
    newStatus: string,
    stepName: string,
    stepSkId: string,
    dueDate: string,
    previousData: NewProjectStep[],
  ) => {
    try {
      await handleUpdateProjectStep(stepSkId, dueDate, newStatus, stepName);
      handleUpdateProject({
        projectId: projectId!,
        projectCurrentStep: getCurrentProjectStep(
          rawProjectData.projectCurrentStep,
          stepName,
          newStatus,
          data,
        ),
      });
      if (updateProjectError) {
        throw new Error('API Error');
      }
    } catch (error) {
      setData(previousData);
      setErrorMessage(`${error}`);
      setErrorDialogOpen(true);
    }
  };

  const columns: ColumnDef<NewProjectStep>[] = useMemo(
    () => [
      {
        header: () => (
          <ProjectHeaderContainer>
            {translate?.('headerProjectStatus')}
          </ProjectHeaderContainer>
        ),
        accessorKey: 'expand',
        enableSorting: false,
        cell: ({ row }) => (
          <FullContainer
            onKeyDown={row.getToggleExpandedHandler()}
            onClick={row.getToggleExpandedHandler()}
          >
            <StatusContainer>
              <FormControl variant="outlined" fullWidth>
                <Select
                  fullWidth
                  value={row.original.status.toLowerCase()} // Convert to lowercase
                  onChange={async (e) => {
                    const newStatus = e.target.value.toLowerCase(); // Convert newStatus to lowercase
                    const stepName = row.original.name;
                    const stepSkId = getTheSk_id(stepName);

                    // Backup the current state before optimistic update
                    const previousData = [...data];

                    // Optimistically update the status in the UI
                    setData((prevData) =>
                      prevData.map((step) =>
                        step.name === stepName
                          ? { ...step, status: newStatus }
                          : step,
                      ),
                    );

                    // Handle the status change with error handling
                    handleStatusChange(
                      newStatus,
                      stepName,
                      stepSkId,
                      row.original.duedate,
                      previousData,
                    );
                  }}
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                >
                  {statuses.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      <Typography color={status.color} variant="caption">
                        {status.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StatusContainer>
          </FullContainer>
        ),
      },
      {
        header: `${translate?.('headerProjectSteps')}`,
        accessorKey: 'projectStep',
        size: 120,
        cell: ({ row }) => (
          <div
            onKeyDown={row.getToggleExpandedHandler()}
            onClick={row.getToggleExpandedHandler()}
          >
            {row.original.name}
          </div>
        ),
      },
      {
        header: `${translate?.('headerProjectAssignees')}`,
        accessorKey: 'projectAssignees',
        size: 100,
        cell: ({ row }) => (
          <ConsultantContainer onClick={row.getToggleExpandedHandler()}>
            {row.original.assignees.map((image: string, idx: number) => (
              <ProfileImage
                moveValue={`${idx}0px`}
                profileName={row.original.assignees[idx]}
                imageIdx={`${idx + 1}`}
                key={idx}
              />
            ))}
          </ConsultantContainer>
        ),
      },
      {
        header: `${translate?.('headerDueDate')}`,
        accessorKey: 'dueDate',
        size: 100,
        cell: ({ row }) => (
          <div
            tabIndex={0}
            role="button"
            onClick={row.getToggleExpandedHandler()}
            onKeyDown={row.getToggleExpandedHandler()}
          >
            <Calendar
              width={14}
              height={14}
              color={colors.lighterSecondaryBlue}
            />{' '}
            {epochToDate(row.original.duedate, 'MMMDD,YYYY')}
          </div>
        ),
      },
      {
        header: `${translate?.('headerProjectComments')}`,
        size: 120,
        cell: ({ row }) => (
          <Box
            justifyContent="start"
            display="flex"
            pr={5}
            onKeyDown={row.getToggleExpandedHandler()}
            onClick={row.getToggleExpandedHandler()}
          >
            <IconButton
              onClick={() => {
                setCommentsOpen(true);
                setStepComment(row.original.name);
              }}
            >
              <Badge
                color={'primary'}
                badgeContent={'123'}
                style={{ transform: 'translate(30px, -20px)' }}
                sx={{
                  transform: 'translate(0px, 0px)',
                  '& .MuiBadge-badge': {
                    fontSize: 10,
                    height: 15,
                    minWidth: 15,
                  },
                }}
              ></Badge>
              <CommentsIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [setCommentsOpen, translate, data],
  );

  return (
    <TableContainer maxHeight="auto">
      {/* Loading State */}
      {isGetProjectsLoading ? (
        <LoadingComponent size="20px" />
      ) : (
        <DisplayTable
          // @ts-ignore
          data={data}
          columns={columns}
          getRowCanExpand={() => true}
          columnsVisibility={columnsVisibility}
          showFooter={false}
          RenderSubComponent={ProjectTableSubComponent}
          loadingItem={translate?.('ProjectTableLoadingMessage')}
          hideSubComponentFromSizeMd
        />
      )}

      {/* Error Dialog */}
      <Dialog open={errorDialogOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>{translate?.('errorUpdatingTitle')}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {translate?.('errorUpdatingMessage')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose} variant="contained">
            {translate?.('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};
