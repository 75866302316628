import { colors } from 'utils/colors';

export const Calendar = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: number;
  height?: number;
}) => (
  <svg
    width={width ?? '24'}
    height={height ?? '24'}
    viewBox="0 0 24 24"
    fill={color ?? colors.white}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0.5C2 0.223858 2.22386 0 2.5 0H4.5C4.77614 0 5 0.223858 5 0.5V1.84615H19V0.5C19 0.223858 19.2239 0 19.5 0H21.5C21.7761 0 22 0.223858 22 0.5V1.84615C23.1046 1.84615 24 2.74158 24 3.84615V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V3.84615C0 2.74158 0.89543 1.84615 2 1.84615V0.5ZM3.93333 8.4C3.27059 8.4 2.73333 8.93726 2.73333 9.6V20.0667C2.73333 20.7294 3.27059 21.2667 3.93333 21.2667H20.0667C20.7294 21.2667 21.2667 20.7294 21.2667 20.0667V9.6C21.2667 8.93726 20.7294 8.4 20.0667 8.4H3.93333Z"
      fill={color ?? colors.white}
    />
    <path
      d="M10.6667 13.0667C10.6667 12.4039 11.2039 11.8667 11.8667 11.8667H16.6667C17.3294 11.8667 17.8667 12.4039 17.8667 13.0667V16.6667C17.8667 17.3294 17.3294 17.8667 16.6667 17.8667H11.8667C11.2039 17.8667 10.6667 17.3294 10.6667 16.6667V13.0667Z"
      fill={color ?? colors.white}
    />
  </svg>
);
