import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Avatar,
  Stack,
  Divider,
  TextField,
  Icon,
  useTheme,
  Tooltip,
} from '@mui/material';
import Card from '@mui/material/Card';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useProjectsDataContext } from '../../../../react-query-toolkit/state/projects-context';
import { ProjectComment } from '../../../../react-query-toolkit/state/types';

interface Comment extends ProjectComment {
  replies: Comment[];
  isMine: boolean;
}

interface CommentsDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  stepComment: string;
}

const ghgci_id = '1'; // Replace with actual logic to retrieve user id

const nestComments = (comments: ProjectComment[]): Comment[] => {
  const commentMap = new Map<string, Comment>();
  const nestedComments: Comment[] = [];

  comments.forEach((comment) => {
    const newComment = {
      ...comment,
      replies: [],
      isMine: comment.author === ghgci_id,
    };
    commentMap.set(comment.id, newComment);

    if (comment.parent) {
      const parentComment = commentMap.get(comment.parent);
      parentComment?.replies.push(newComment);
    } else {
      nestedComments.push(newComment);
    }
  });

  return nestedComments;
};

export const ProjectComments: React.FC<CommentsDrawerProps> = ({
  open,
  toggleDrawer,
  stepComment,
}) => {
  const theme = useTheme();
  const { handleGetProject, handleCreateProjectComment, handleDeleteComment } =
    useProjectsDataContext();

  const rawProjectData = handleGetProject();
  const commentsData: ProjectComment[] = rawProjectData?.projectComments || [];

  // Memoize the filtered data to avoid unnecessary re-renders
  const filteredCommentsData = useMemo(() => {
    return commentsData.filter(
      (comment) => comment.projectstep === stepComment,
    );
  }, [commentsData, stepComment]);

  // Initialize comments state and update it only if filteredCommentsData changes
  const [comments, setComments] = useState<Comment[]>([]);

  useEffect(() => {
    const nested = nestComments(filteredCommentsData);
    // Only update state if the nested data is different
    if (JSON.stringify(comments) !== JSON.stringify(nested)) {
      setComments(nested);
    }
  }, [filteredCommentsData]);

  const [areAllRepliesVisible, setAreAllRepliesVisible] =
    useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [replyTo, setReplyTo] = useState<{
    id: string | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [inputValue, setInputValue] = useState<string>('');
  const [replyVisibility, setReplyVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleRepliesVisibility = (commentId: string) => {
    setReplyVisibility((prevVisibility) => ({
      ...prevVisibility,
      [commentId]: !prevVisibility[commentId],
    }));
  };

  const toggleAllRepliesVisibility = () => {
    setAreAllRepliesVisible((prev) => !prev);
    if (areAllRepliesVisible) setReplyVisibility({});
  };

  const handleSend = () => {
    if (!inputValue.trim()) return;

    const newComment: Comment = {
      id: Date.now().toString(),
      typename: 'comment',
      created_at: new Date().toISOString(),
      author: ghgci_id,
      parent: replyTo.id ? replyTo.id : '',
      projectstep: stepComment,
      read: [],
      tags: '',
      text: inputValue,
      replies: [],
      isMine: true,
      checked: true,
    };

    if (replyTo.id) {
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === replyTo.id
            ? { ...comment, replies: [...comment.replies, newComment] }
            : comment,
        ),
      );
      handleCreateProjectComment(
        newComment.id,
        newComment.parent,
        newComment.projectstep,
        newComment.read,
        newComment.tags,
        newComment.text,
        newComment.checked,
      );
    } else {
      setComments((prevComments) => [...prevComments, newComment]);
      handleCreateProjectComment(
        newComment.id,
        newComment.parent,
        newComment.projectstep,
        newComment.read,
        newComment.tags,
        newComment.text,
        newComment.checked,
      );
    }

    setInputValue('');
    setReplyTo({ id: null, name: null });
  };

  const deleteCommentOrReply = (
    comments: Comment[],
    commentId: string,
  ): Comment[] => {
    return comments.reduce<Comment[]>((acc, comment) => {
      if (comment.id === commentId) return acc;
      const updatedReplies = deleteCommentOrReply(comment.replies, commentId);
      return [...acc, { ...comment, replies: updatedReplies }];
    }, []);
  };

  const handleDelete = (commentId: string) => {
    const commentToDelete = commentsData.find(
      (comment) => comment.id === commentId,
    );
    console.log('lalal', commentsData);
    if (commentToDelete) {
      // Check if the comment is a top-level comment (no parent)
      if (!commentToDelete.parent) {
        // Confirmation message for deleting a top-level comment and its replies
        const confirmDelete = window.confirm(
          'You are about to delete this comment and all of its replies. Are you sure?',
        );

        if (confirmDelete) {
          // Remove the top-level comment and all comments with this ID as their parent
          setComments((prevComments) =>
            prevComments.filter(
              (comment) =>
                comment.id !== commentId && comment.parent !== commentId,
            ),
          );

          // Here is the delete query
          handleDeleteComment(commentId);
        }
      } else {
        // Confirmation message for deleting a reply only
        const confirmDelete = window.confirm(
          'Are you sure you want to delete this reply?',
        );

        if (confirmDelete) {
          // Remove only the specific reply from the nested structure
          setComments((prevComments) =>
            deleteCommentOrReply(prevComments, commentId),
          );

          // Here is the delete query
          handleDeleteComment(commentId);
        }
      }
    }
  };

  const highlightText = (text: string) => {
    if (!filterText) return text;
    const parts = text.split(new RegExp(`(${filterText})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === filterText.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </>
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterText(value);
    if (value.trim()) {
      setReplyVisibility({});
      setAreAllRepliesVisible(true);
    } else {
      setAreAllRepliesVisible(false);
    }
  };

  const getFilteredComments = (filter: string) => {
    if (!filter) return comments;
    return comments
      .map((comment) => ({
        ...comment,
        replies: comment.replies.filter((reply) =>
          reply.text.toLowerCase().includes(filter.toLowerCase()),
        ),
      }))
      .filter(
        (comment) =>
          comment.text.toLowerCase().includes(filter.toLowerCase()) ||
          comment.replies.length > 0,
      );
  };

  const handleReply = (commentId: string, commenterName: string) => {
    setReplyTo({ id: commentId, name: commenterName });
    inputRef.current?.focus();
  };

  const renderComment = (comment: Comment, indentLevel: number = 0) => {
    const repliesCount = comment.replies.length;
    const areRepliesVisible = replyVisibility[comment.id] || false;

    return (
      <Box key={comment.id} ml={indentLevel * 2}>
        <Card elevation={3} sx={{ my: 4, mx: 2, padding: 2 }}>
          <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <Avatar>
              <Icon>person</Icon>
            </Avatar>
            <Typography
              color={comment.isMine ? 'primary.dark' : 'text.primary'}
              fontWeight="bold"
            >
              {comment.isMine ? 'Me' : comment.author}
            </Typography>
            <Box flexGrow={1} />
            <Typography color="error" variant="caption">
              {comment.created_at}
            </Typography>
          </Stack>
          <Typography variant="body2" color="textSecondary" mb={1}>
            {highlightText(comment.text)}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography
              color="primary"
              variant="body2"
              sx={{
                cursor: 'pointer',
                visibility: indentLevel === 0 ? 'visible' : 'hidden',
              }}
              onClick={() => handleReply(comment.id, comment.author)}
            >
              Reply
            </Typography>
            <Box display="flex" alignItems="center">
              <IconButton size="small" onClick={() => handleDelete(comment.id)}>
                <DeleteIcon color="disabled" />
              </IconButton>
            </Box>
          </Box>
          {repliesCount > 0 && (
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                mt: 1,
                color: theme.palette.text.secondary,
              }}
              onClick={() => toggleRepliesVisibility(comment.id)}
            >
              {areRepliesVisible
                ? 'Hide Replies'
                : `${repliesCount} replies - Last reply today at ${new Date().toLocaleTimeString()}`}
            </Typography>
          )}
          {(areRepliesVisible || areAllRepliesVisible) &&
            comment.replies
              .slice()
              .reverse()
              .map((reply) => renderComment(reply, indentLevel + 1))}
          <Divider sx={{ my: 2 }} />
        </Card>
      </Box>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: { sm: '100%', md: 300 },
          boxSizing: 'border-box',
          top: 60,
          padding: 2,
        },
        '& .MuiBackdrop-root': { backgroundColor: 'transparent !important' },
      }}
    >
      <Box display="flex" flexDirection="column" height="calc(100% - 60px)">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" fontWeight="bold" color="primary.dark">
            Comments
          </Typography>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Tooltip
              title={
                areAllRepliesVisible ? 'Hide all replies' : 'Expand all replies'
              }
            >
              <IconButton onClick={toggleAllRepliesVisibility}>
                {areAllRepliesVisible ? (
                  <VisibilityOffIcon color="primary" />
                ) : (
                  <VisibilityIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Typography>{stepComment}</Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Filter comments and replies..."
          value={filterText}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setFilterText('');
                  setAreAllRepliesVisible(false);
                  setReplyVisibility({});
                }}
                style={{ visibility: filterText ? 'visible' : 'hidden' }}
              >
                <CloseIcon color="primary" />
              </IconButton>
            ),
          }}
        />
        <Box flexGrow={1} overflow="auto">
          {getFilteredComments(filterText).map((comment) =>
            renderComment(comment),
          )}
        </Box>
        <Box mt={2}>
          {replyTo.id && replyTo.name && (
            <Box
              mb={1}
              bgcolor={theme.palette.grey[200]}
              padding={1}
              borderRadius={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2" color="textSecondary">
                Replying to {replyTo.name}
              </Typography>
              <IconButton
                size="small"
                onClick={() => setReplyTo({ id: null, name: null })}
              >
                <CloseIcon color="primary" fontSize="small" />
              </IconButton>
            </Box>
          )}
          <TextField
            fullWidth
            variant="outlined"
            placeholder={
              replyTo.id ? 'Type a Reply...' : 'Type a Comment here...'
            }
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSend}>
                  <SendIcon color="primary" />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
    </Drawer>
  );
};
