import { yupResolver } from '@hookform/resolvers/yup';
import { AnchorLink, Button, Span, TextField } from 'components';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { colors } from 'utils/styles';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'configs/AuthContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

export interface LocationState {
  from: {
    pathname: string;
  };
}

export const LoginForm = () => {
  const { t: translate } = useTranslation();
  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email(translate?.('loginSchemaInvalidEmail'))
      .required(translate?.('loginSchemaEmailRequired')),
    password: yup
      .string()
      .required(translate?.('loginSchemaPasswordRequired'))
      .min(8, translate?.('loginSchemaPasswordMinLength'))
      .matches(/.*[a-z].*/, translate?.('loginSchemaPasswordLowercase'))
      .matches(/.*[A-Z].*/, translate?.('loginSchemaPasswordUppercase'))
      .matches(/.*\d.*/, translate?.('loginSchemaPasswordNumber'))
      .matches(
        /[!@#$%^&*()_,.?":{}|<>]/,
        translate?.('loginSchemaPasswordSpecialCharacter'),
      ),
  });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { user, signin } = useAuth();
  const [error, setError] = useState<string>('');
  const from = (location.state as LocationState)?.from || '';

  async function onSubmit(_data: FieldValues) {
    try {
      signin(
        _data.email,
        _data.password,
        () => {
          setError('');
          if (user) {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              navigate('/change-password');
            } else {
              navigate(from || '/');
            }
          }
        },
        (err: Error) => {
          setError(err.message);
        },
      );
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  const checkFieldValidity = async (fieldName: 'email' | 'password') => {
    await trigger(fieldName);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Box
        height="100%"
        maxHeight="530px"
        //This will ensure Svend screen is rendered properly while it will render proper padding to
        // large screens without zoom
        my={4}
        maxWidth="402px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        bgcolor={isSmallScreen ? 'transparent' : 'white'}
        boxShadow={isSmallScreen ? 0 : 3}
        borderRadius={2}
        display="flex"
      >
        {/*Material ui does not react correctly with style components this is a workaround so that we don't have rebuild the inputs*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            padding: '0 40px',
          }}
        >
          <Typography variant="h4" mb={3} color={theme.palette.secondary.main}>
            {translate?.('headerLogin')}
          </Typography>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  type="email"
                  {...field}
                  onBlur={() => checkFieldValidity('email')}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors();
                  }}
                  label={translate?.('LoginFormEmailLabel')}
                  placeholder={translate?.('LoginFormEmailPlaceholder')}
                  status={
                    errors.email ? 'error' : isValid ? 'success' : undefined
                  }
                  errorMessage={errors.email && errors.email.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  onBlur={() => checkFieldValidity('password')}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors();
                  }}
                  label={translate?.('LoginFormPasswordLabel')}
                  placeholder={translate?.('LoginFormPasswordPlaceholder')}
                  type="password"
                  status={
                    errors.password ? 'error' : isValid ? 'success' : undefined
                  }
                  errorMessage={errors.password && errors.password.message}
                />
              )}
            />
            <div
              style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}
            >
              <Button type="submit">
                {translate?.('LoginFormButtonAction')}
              </Button>
            </div>
            <Span color="spanText">
              {translate?.('LoginFormRequestAccountMessage')}
            </Span>
            <div
              style={{ width: '100%', marginTop: '12px', marginBottom: '24px' }}
            >
              <Button
                onClick={() => navigate('/request-account')}
                colors={{
                  backgroundColor: colors.secondaryBlue,
                  color: colors.white,
                }}
              >
                {translate?.('LoginFormButtonRequestAccount')}
              </Button>
              {error && <p>{error}</p>}
            </div>
            <div>
              <AnchorLink onClick={() => navigate('/forgot-password')} href="">
                {translate?.('LoginFormForgotPassword')}
              </AnchorLink>
            </div>
          </form>
        </div>
      </Box>
    </Box>
  );
};
