export type DropDownOptionType = {
  value: string;
  label: string;
};
import { NewClientDataType } from 'pages/clients/api/get-clients';
const countries: string[] = [];
const states: string[] = [];
const cities: string[] = [];
const municipalities: string[] = [];
export function getCountry(data: NewClientDataType[]): DropDownOptionType[] {
  const uniqueCountries = [
    ...new Set(data.map((client) => client.country)),
  ].sort();

  return uniqueCountries.map((country) => ({
    value: country,
    label: country,
  }));
}

//This is DUMMY DATA TO BE DETERMINED LATER
export function getState(data: NewClientDataType[]): DropDownOptionType[] {
  const uniqueStates = [...new Set(data.map((client) => client.state))].sort();

  return uniqueStates.map((state) => ({
    value: state,
    label: state,
  }));
}

//This is DUMMY DATA TO BE DETERMINED LATER
export function getMunicipality(
  data: NewClientDataType[],
): DropDownOptionType[] {
  const municipalities = data.map((client) => client.municipality);
  const uniqueMunicipalities = [...new Set(municipalities)].sort();

  return uniqueMunicipalities.map((municipality) => ({
    value: municipality,
    label: municipality,
  }));
}

//This is DUMMY DATA TO BE DETERMINED LATER
export function getCity(data: NewClientDataType[]): DropDownOptionType[] {
  const uniqueCities = [...new Set(data.map((client) => client.city))].sort();

  return uniqueCities.map((city) => ({
    value: city,
    label: city,
  }));
}
