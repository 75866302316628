import { configureStore } from '@reduxjs/toolkit';
import { ghgSfeApi } from '../react-query-toolkit/services/ghgci-sfe';

export const store = configureStore({
  reducer: {
    [ghgSfeApi.reducerPath]: ghgSfeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ghgSfeApi.middleware),
  //
});

// setupListeners(store.dispatch)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
