import { colors } from 'utils/colors';

export const EyeOpen = ({ color }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5.24902C4.5 5.24902 1.5 11.9998 1.5 11.9998C1.5 11.9998 4.5 18.749 12 18.749C19.5 18.749 22.5 11.9998 22.5 11.9998C22.5 11.9998 19.5 5.24902 12 5.24902Z"
      stroke={color ? color : colors.black}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
      stroke={color ? color : colors.black}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
