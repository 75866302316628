import { colors } from 'utils/colors';

type Props = {
  color?: string;
  height?: string;
  width?: string;
};

export const Warning = ({ color, height, width }: Props) => {
  const svgColor = color ?? colors.lighterSecondaryBlue;
  const svgHeight = height ?? 48;
  const svgWidth = width ?? 48;

  return (
    <>
      <svg
        width={svgWidth}
        height={svgHeight}
        viewBox="0 0 48 48"
        fill={svgColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.9467 0H14.0533L0 14.0533V33.9467L14.0533 48H33.9467L48 33.9467V14.0533L33.9467 0ZM24 38.1333C22.08 38.1333 20.5333 36.5867 20.5333 34.6667C20.5333 32.7467 22.08 31.2 24 31.2C25.92 31.2 27.4667 32.7467 27.4667 34.6667C27.4667 36.5867 25.92 38.1333 24 38.1333ZM26.6667 26.6667H21.3333V10.6667H26.6667V26.6667Z"
          fill={svgColor}
        />
      </svg>
    </>
  );
};
