import { colors } from 'utils/colors';

interface ArrowDownProps {
  color?: string;
  style?: React.CSSProperties; // Define style prop
}

export const ArrowDown = ({ color, style }: ArrowDownProps) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style} // Pass style prop to the <svg> element
  >
    <path
      d="M1 1.5L6 6.5L11 1.5"
      stroke={color ?? colors.white}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
