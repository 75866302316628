{
  "calendarShortWeekDayMonday": "",
  "dashboard": "Armaturenbrett",
  "dataTable": "Datentabelle",
  "greeting": "Hallo Welt!",
  "headerClient": "Klient",
  "headerConsultant": "Consultant",
  "headerEmail": "Email",
  "headerLocation": "Location",
  "headerName": "Name",
  "headerPhone": "Telefon",
  "headerProjects": "",
  "message": "Willkommen bei GHG CI.",
  "myClients": "Meine Kunden"
}
