import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGES } from '../default-languages';
import { Callback, TFunction } from 'i18next';

// TODO: Uninstall
// import { Callback, TFunction, i18n } from 'i18next';

// TODO: Static Typing?
type LanguageContext = {
  selected: {
    en: { translation: Record<string, unknown> };
    de: { translation: Record<string, unknown> };
    fr: { translation: Record<string, unknown> };
  };
  language: string;
  addLanguage: <T>(
    data: T,
    parseData: (data: T) => Record<string, Record<string, string>>,
  ) => void;
  changeLanguage?: (
    lng?: string | undefined,
    callback?: Callback | undefined,
  ) => Promise<TFunction<'translation', undefined>>;
  t?: TFunction<'translation', undefined>;
};

const defaultContext: LanguageContext = {
  selected: DEFAULT_LANGUAGES,
  language: 'EN',
  addLanguage: function <T>(
    _data: T,
    _parseData: (data: T) => Record<string, Record<string, string>>,
  ): void {
    throw new Error('Function not implemented.');
  },
};

export const LanguageContext = createContext(defaultContext);

export const LanguageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const translation = useTranslation();
  const { i18n, t } = translation;
  const { changeLanguage, addResource, language } = i18n;

  const value = {
    ...defaultContext,
    addLanguage: <T,>(
      data: T,
      parseData: (data: T) => Record<string, Record<string, string>>,
    ) => {
      const languageData = parseData(data);

      for (const key in languageData) {
        const selectedData = languageData[key];
        for (const languageDataKey in selectedData) {
          addResource(
            key,
            'translation',
            languageDataKey,
            selectedData[languageDataKey],
          );
        }
      }
    },
    changeLanguage,
    language: language.toUpperCase(),
    t,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  return useContext(LanguageContext);
};
