import { HeadlineContainer } from './styled';
import { colors } from 'utils/colors';

type Props = {
  fontWeight?: number;
  fontSize?: number;
  fontSizeSmall?: number;
  color?: keyof typeof colors;
  children: string;
};

export const Headline = ({
  fontWeight,
  color,
  children,
  fontSize,
  fontSizeSmall,
}: Props) => {
  return (
    <HeadlineContainer
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontSizeSmall={fontSizeSmall}
    >
      {children}
    </HeadlineContainer>
  );
};
