export const Settings = ({ color }: { color: string }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8807 9.81827L15.8439 10.105L16.0739 10.28L17.9834 11.7324L16.1745 14.7766L13.9031 13.8893L13.6479 13.7896L13.4265 13.9511C12.9713 14.2831 12.4897 14.5663 11.9723 14.7725L11.7049 14.8792L11.6629 15.1641L11.3185 17.5H7.68559L7.34124 15.1641L7.29924 14.8792L7.03174 14.7725C6.51084 14.5649 6.03087 14.2902 5.58246 13.9547L5.3599 13.7881L5.10098 13.8893L2.82962 14.7766L1.02064 11.7324L2.93016 10.28L3.16022 10.105L3.12337 9.81827C3.08771 9.54078 3.06268 9.26715 3.06268 9C3.06268 8.73285 3.08771 8.45922 3.12337 8.18173L3.16022 7.89504L2.93016 7.72004L1.02064 6.26757L2.82962 3.22343L5.10098 4.11072L5.35622 4.21043L5.5776 4.04893C6.03277 3.71687 6.51435 3.43371 7.03174 3.22745L7.29924 3.12081L7.34124 2.83592L7.68559 0.5H11.3185L11.6629 2.83592L11.7049 3.12081L11.9723 3.22745C12.4932 3.43511 12.9732 3.70982 13.4216 4.04534L13.6442 4.21187L13.9031 4.11072L16.1745 3.22343L17.9834 6.26757L16.0739 7.72004L15.8439 7.89504L15.8807 8.18173C15.9164 8.45971 15.9414 8.72416 15.9414 9C15.9414 9.27584 15.9164 9.54029 15.8807 9.81827ZM5.76368 9C5.76368 11.0261 7.4533 12.65 9.50204 12.65C11.5508 12.65 13.2404 11.0261 13.2404 9C13.2404 6.97391 11.5508 5.35 9.50204 5.35C7.4533 5.35 5.76368 6.97391 5.76368 9Z"
      fill={color}
      stroke={color}
    />
  </svg>
);
