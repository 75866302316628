import { colors } from 'utils/colors';

export const PageArrowForward = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="9"
    viewBox="0 0 5 9"
    fill="none"
  >
    <path d="M0 0.5L5 4.5L0 8.5V0.5Z" fill={colors.spanText} />
  </svg>
);
