import { colors } from 'utils/colors';

export const DescriptionTableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.973633 1.21094C0.973633 0.934795 1.19749 0.710938 1.47363 0.710938H17.1052C17.3814 0.710938 17.6052 0.934795 17.6052 1.21094C17.6052 1.48708 17.3814 1.71094 17.1052 1.71094H1.47363C1.19749 1.71094 0.973633 1.48708 0.973633 1.21094ZM0.973633 5.00041C0.973633 4.72427 1.19749 4.50041 1.47363 4.50041H18.5263C18.8024 4.50041 19.0263 4.72427 19.0263 5.00041C19.0263 5.27655 18.8024 5.50041 18.5263 5.50041H1.47363C1.19749 5.50041 0.973633 5.27655 0.973633 5.00041ZM1.47363 8.28988C1.19749 8.28988 0.973633 8.51374 0.973633 8.78988C0.973633 9.06603 1.19749 9.28988 1.47363 9.28988H17.1052C17.3814 9.28988 17.6052 9.06603 17.6052 8.78988C17.6052 8.51374 17.3814 8.28988 17.1052 8.28988H1.47363Z"
      fill={colors.chevronGray}
    />
  </svg>
);
