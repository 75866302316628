import { colors } from 'utils/colors';

export const HelpIcon = ({ height }: { height?: string }) => (
  <svg
    height={height ? height : '18'}
    width="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.63199 14.1086C9.92437 14.1086 10.1614 13.8716 10.1614 13.5792V12.8771C10.1614 12.5848 9.92437 12.3477 9.63198 12.3477H8.91255C8.62017 12.3477 8.38314 12.5848 8.38314 12.8771V13.5792C8.38314 13.8716 8.62017 14.1086 8.91255 14.1086H9.63199Z"
      fill={colors.bluePrimary}
    />
    <path
      d="M9.60504 11.4537C9.89743 11.4537 10.1253 11.2114 10.2069 10.9307C10.3855 10.3164 10.8683 9.89221 11.3636 9.45699C12.0309 8.87072 12.721 8.26441 12.721 7.14637C12.721 5.23378 11.1772 3.70588 9.27227 3.70588C7.54511 3.70588 6.11476 4.97534 5.86289 6.64594C5.8193 6.93506 6.06056 7.17346 6.35294 7.17346H7.01849C7.31087 7.17346 7.54082 6.93082 7.63139 6.65282C7.85602 5.96338 8.50818 5.42883 9.27227 5.42883C10.2207 5.42883 10.9966 6.19278 10.9966 7.14637C10.9966 7.91328 10.489 8.30906 9.92346 8.74994C9.301 9.23522 8.60844 9.77514 8.44566 10.9248C8.40467 11.2143 8.64711 11.4537 8.9395 11.4537H9.60504Z"
      fill={colors.bluePrimary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM1.05882 9C1.05882 4.61421 4.61421 1.05882 9 1.05882C13.3858 1.05882 16.9412 4.61421 16.9412 9C16.9412 13.3858 13.3858 16.9412 9 16.9412C4.61421 16.9412 1.05882 13.3858 1.05882 9Z"
      fill={colors.bluePrimary}
    />
  </svg>
);
