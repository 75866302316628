import React from 'react';
import { Box, Typography } from '@mui/material';

interface PageNameContainerProps {
  onClick?: () => void;
  pageName: string;
}

export function PageNameContainer({ pageName }: PageNameContainerProps) {
  return (
    <Box flexGrow={1} textAlign={'center'} sx={{ display: { md: 'none' } }}>
      <Typography variant={'h6'}>{pageName}</Typography>
    </Box>
  );
}
