import { colors } from 'utils/colors';
export const FlagIcon = ({
  stroke,
  fill,
}: {
  stroke: string;
  fill: string;
}) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.24707V10.2471"
      stroke={stroke ? stroke : colors.red}
      strokeWidth="0.5"
    />
    <path
      d="M5.5 1.24707C4.5 -0.252856 1.16667 0.913739 1 1.24707V4.747C2.16667 4.41367 3.5 4.247 5 4.747C6.5 5.247 7.5 6.747 9.5 5.747V1.247C9.5 1.58036 6.5 2.747 5.5 1.24707Z"
      fill={fill ? fill : colors.red}
    />
  </svg>
);
