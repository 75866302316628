import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { fontSize, fontWeight } from 'utils/styles';

import { ASSETS_URL } from 'utils/urls';

export const hoverColor = colors.chevronGray;
export const activeColor = colors.chevronGray;

const StyledButton = styled.button<{
  disabled?: boolean;
  customColors?: Colors;
}>`
  ${({ customColors, disabled }) => {
    return `
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: ${
        customColors ? customColors.backgroundColor : colors.bluePrimary
      };
      color: ${customColors ? customColors.color : colors.white};
      border-radius: 4px;
      padding: 8px 16px;
      cursor: pointer;
      font-size: ${fontSize.text};
      font-weight: ${fontWeight.semiBold};
      letter-spacing: 0.175px;
      line-height: normal;
      &:hover {
        background-color: ${hoverColor};
        color: ${colors.white};
      }
      &:active {
        background-color: ${activeColor};
        color: ${colors.white};
      }
      &:focus {
        background-color: ${colors.bluePrimary};
        color: ${colors.white};
      }
      ${
        disabled &&
        `
        color: ${colors.secondaryBlue} !important;
        background-color: ${colors.lighterSecondaryBlue} !important;
        cursor: not-allowed !important;
      `
      }
    `;
  }}
`;

const IconContainer = styled.div<{ iconUrl?: string }>`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  ${({ iconUrl }) => {
    return (
      iconUrl &&
      `
      &:before {
        content: url('${ASSETS_URL}/${
          iconUrl[0] === '/' ? iconUrl.slice(1) : iconUrl
        }');
      }
    `
    );
  }}
`;

type Colors = {
  color: string;
  backgroundColor: string;
};

type Props = {
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  iconUrl?: string;
  colors?: Colors;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: JSX.Element | string;
};

export const Button = ({
  type,
  disabled,
  iconUrl,
  colors,
  onClick,
  children,
}: Props) => {
  return (
    <StyledButton
      onClick={onClick}
      type={type ?? 'button'}
      disabled={disabled}
      customColors={colors}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}> */}
      {iconUrl && <IconContainer iconUrl={iconUrl} />}
      {children}
      {/* </div> */}
    </StyledButton>
  );
};
