import type { GenericProps } from './types';

export const CalculatorIcon = ({ width, height }: GenericProps) => (
  <svg
    width={width ?? '15'}
    height={height ?? '22'}
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.653809"
      y="0.0294189"
      width="14.3462"
      height="21.9412"
      rx="3"
      fill="#EBEEDF"
    />
    <rect
      x="2.34155"
      y="3.40491"
      width="10.9706"
      height="2.53167"
      rx="1"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.87317 6.7804)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.87317 10.1561)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.87317 13.5317)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 4.87317 16.9072)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 9.09265 6.7804)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 9.09265 10.1561)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 9.09265 13.5317)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 13.3123 6.7804)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 13.3123 10.1561)"
      fill="#9CA860"
    />
    <rect
      width="2.53167"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 13.3123 13.5317)"
      fill="#9CA860"
    />
    <rect
      width="6.75113"
      height="2.53167"
      rx="1"
      transform="matrix(-1 0 0 1 13.3123 16.9072)"
      fill="#9CA860"
    />
  </svg>
);
