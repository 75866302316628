import { colors } from './colors';

export * from './colors';

export const zIndex = {
  default: 1,
};

export const defaultFontFamily = 'Open Sans, system-ui, sans-serif';

export const fontSize = {
  h1: '28px',
  h2: '24px',
  h3: '20px',
  h4: '18px',
  textLarge: '16px',
  text: '14px',
  textSm: '12px',
  textXs: '10px',
  nano: '8px',
};

export const fontWeight = {
  lightest: 300,
  lighter: 400,
  normal: 500,
  semiBold: 600,
  bold: 700,
};

export const spacing = {
  xxl: 56,
  xl: 48,
  lg: 42,
  tb: 30,
  md: 24,
  gap: 20,
  sm: 16,
  xs: 8,
};

export const spacingWithPx = {
  xxl: '56px',
  xl: '48px',
  lg: '40px',
  tb: '30px',
  md: '24px',
  gap: '20px',
  sm: '14px',
  xs: '8px',
};

const elevationMap = {
  '12dp': `0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)`,
  '8dp': `0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)`,
  '6dp': `0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)`,
  '3dp': `0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2)`,
};

export const elevation = {
  xl: elevationMap['12dp'],
  lg: elevationMap['8dp'],
  md: elevationMap['6dp'],
  sm: elevationMap['3dp'],
};

export const defaultTransitionTimeAndStyle = '200ms ease-in-out';

export const normalBladeFontStyling = `
  font-family: ${defaultFontFamily};
  color: ${colors.secondaryBlue};
  font-style: normal;
  font-weight: ${fontWeight.lighter};
  font-size: ${fontSize.text};
  letter-spacing: 0.1px;
`;

export const subtextFontStyling = `
  font-family: "Roboto";
  font-style: normal;
  font-weight: ${fontWeight.lighter};
  font-size: ${fontSize.textSm};
  letter-spacing: 0.4px;
  `;
