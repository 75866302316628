import styled from 'styled-components';
import { colors, spacingWithPx } from 'utils/styles';
import { breakpoints } from 'utils/breakpoints';

export const Paper = styled.div<{
  gap?: string;
  padding?: string;
  paddingMobile?: string;
  width?: string;
  flexDirection?: string;
  hideScrollBar?: boolean;
  position?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  position: ${({ position }) => (position ? position : '')};
  background-color: ${colors.lightestGrey};
  width: ${({ width }) => width ?? '100%'};
  gap: ${({ gap }) => gap ?? `${spacingWithPx.gap}`};
  max-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  padding: ${({ padding }) => padding ?? 'auto'};

  ${({ hideScrollBar }) =>
    hideScrollBar && 'scrollbar-width: none;\n  -ms-overflow-style: none;'};
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: ${({ paddingMobile }) => paddingMobile ?? 'auto'};
  }
`;
