import React, { ChangeEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'utils/colors';
import { breakpoints } from 'utils/breakpoints';

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

const Label = styled.label<{ disabled?: boolean; fontSizeMobile?: number }>`
  position: relative;
  display: inline-block;
  cursor: ${(disabled) => (disabled ? 'not-allowed' : 'pointer')};
  margin: 0;
  white-space: nowrap;

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: ${(fontSizeMobile) =>
      fontSizeMobile ? `${fontSizeMobile}px` : ''};
  }
`;

const Indicator = styled.div`
  border: 1px solid ${colors.bluePrimary};
  border-radius: 1em;
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  left: -1.5em;
  top: 2px;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    border: solid ${colors.bluePrimary};
    border-radius: 1em;
    background-color: ${colors.bluePrimary};
    width: 0.8em;
    height: 0.8em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: ${colors.white};
  }
`;

export interface RadioButtonType {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  id: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
}
export default function RadioButton(props: RadioButtonType) {
  const { value, onChange, name, id, label, disabled, checked } = props;
  return (
    <Label htmlFor={id} fontSizeMobile={12} disabled={disabled}>
      {label}
      <Input
        id={id}
        type="radio"
        role="radio"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <Indicator />
    </Label>
  );
}
