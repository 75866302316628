import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PlEntities, PlEntity, SlEntities, SlEntity } from './types';
import { Auth } from 'aws-amplify';

// Define all the tags in one array
const tagList = [
  PlEntities.Client,
  PlEntities.Project,
  PlEntities.User,
  PlEntities.System,
  SlEntities.ProjectText,
  SlEntities.ProjectStep,
  SlEntities.Notification,
  SlEntities.Task,
] as const;

type TagType = (typeof tagList)[number];
type TagTypeWithOptionalId = { type: TagType; id?: string };

export const ghgSfeApi = createApi({
  reducerPath: 'ghgSfeApi',
  tagTypes: tagList, // Use the array here
  baseQuery: fetchBaseQuery({
    //baseUrl: process.env.API_URL_STAGING, /// This is the correct implementation so that Amplify can read it.
    //baseUrl: 'https://uv3kx46qr4.execute-api.ca-central-1.amazonaws.com/test', //for local dev / This is a the version 1 updated
    baseUrl: '/', //for local dev / This is a the version 1 updated

    prepareHeaders: async (headers) => {
      try {
        const session = await Auth.currentSession();
        const idToken = session?.getIdToken();
        const jwtToken = idToken?.getJwtToken();
        if (jwtToken) {
          headers.set('Authorization', jwtToken);
          console.log('token lala', jwtToken);
        }
      } catch (e) {
        console.log('There was an error: ', e);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getPl: builder.query<PlEntity, { typename: string; id: string }>({
      query: ({ typename, id }) => `pl/${typename}/${id}`,
      providesTags: (result, error, { typename, id }) => {
        const baseTags = tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }, typename as TagType]
          : [];

        // Add a wildcard User tag for global invalidation
        if (typename === PlEntities.User) {
          baseTags.push({ type: PlEntities.User, id: 'ALL' });
        }

        return baseTags;
      },
    }),
    getPlAll: builder.query<PlEntity, { typename: string }>({
      query: ({ typename }) => `pl/${typename}/`,
      providesTags: (result, error, { typename }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType }]
          : [];
      },
    }),

    getPlByClass: builder.query({
      query: ({ typename, id, classItem }) =>
        `pl/${typename}/${id}?class=${classItem}`,
      providesTags: (result, error, { typename, id }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];
      },
    }),

    createPl: builder.mutation<string, PlEntity>({
      query: ({ typename, id, requestBody }) => ({
        url: `pl/${typename}?customId=${id}`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: (result, error, { typename, id }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];
      },
    }),

    updatePl: builder.mutation<PlEntity, PlEntity>({
      query: ({ typename, id, requestBody }) => ({
        url: `pl/${typename}/${id}`,
        method: 'PUT',
        body: requestBody,
      }),
      invalidatesTags: (result, error, { typename, id }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];
      },
    }),

    createSl: builder.mutation<SlEntity, SlEntity>({
      query: ({ typename, id, requestBody }) => ({
        url: `sl/${typename}/${id}`,
        method: 'POST',
        body: requestBody,
        formData: true,
      }),
      invalidatesTags: (result, error, { typename, id }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];
      },
    }),

    createSlWithCustomId: builder.mutation<SlEntity, PlEntity>({
      query: ({ typename, id, customId, requestBody }) => ({
        url: `sl/${typename}/${id}?customId=${customId}`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: (result, error, { typename, id }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];
      },
    }),

    updateSl: builder.mutation<string, SlEntity>({
      query: ({ typename, id, requestBody }) => ({
        url: `sl/${typename}/${id}/`,
        method: 'PUT',
        body: requestBody,
      }),
      invalidatesTags: (result, error, { typename, id }) => {
        const tagsToInvalidate = tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];

        // Add client invalidation for handleGetClients
        if (typename === PlEntities.Client) {
          tagsToInvalidate.push({ type: PlEntities.Client });
        }

        return !error ? tagsToInvalidate : [];
      },
    }),

    deleteSl: builder.mutation<string, SlEntity>({
      query: ({ typename, id, requestBody }) => ({
        url: `sl/${typename}/${id}`,
        method: 'DELETE',
        body: requestBody,
      }),
      invalidatesTags: (result, error, { typename, id }) => {
        return tagList.includes(typename as TagType)
          ? [{ type: typename as TagType, id }]
          : [];
      },
    }),
  }),
});

export const {
  useGetPlQuery,
  useGetPlAllQuery,
  useGetPlByClassQuery,
  useCreatePlMutation,
  useCreateSlMutation,
  useCreateSlWithCustomIdMutation,
  useUpdateSlMutation,
  useUpdatePlMutation,
  useDeleteSlMutation,
} = ghgSfeApi;
