import styled from 'styled-components';

import { colors } from 'utils/styles';
import { breakpoints } from 'utils/breakpoints';

// Rems are relative to root font size
// Using 16px which is default as root font size
export const HeadingContainer = styled.div<{
  color?: string;
  fontWeight?: number;
  padding?: string;
}>`
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ color }) => (color ? color : colors.darkPrimaryBlue)};
    margin: 0;
    padding: 0;
  }
  > h1 {
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  }
  > h2 {
    font-size: 1.5rem;
    letter-spacing: 2px;
    font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  }
  > h3 {
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  }
  > h4 {
    font-size: 2.125rem;
    letter-spacing: 0.085px;
    line-height: normal;
    padding: ${({ padding }) => padding ?? 0};
    font-weight: ${({ fontWeight }) => fontWeight ?? 400};

    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 1.25rem;
    }
  }
  > h5 {
    font-size: 0.875rem;
    letter-spacing: 0;
    font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  }
  > h6 {
    font-size: 0.875rem;
    letter-spacing: 0;
    font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03px;
  }
`;
