export const AlertIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 14V18"
      stroke="#FEA04A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.269 4.99809L3.27198 23.9971C3.09602 24.3011 3.00322 24.6461 3.00293 24.9974C3.00264 25.3486 3.09487 25.6938 3.27033 25.9981C3.44579 26.3024 3.6983 26.5551 4.00246 26.7308C4.30661 26.9065 4.65167 26.999 5.00294 26.999H26.997C27.3483 26.999 27.6933 26.9065 27.9975 26.7308C28.3016 26.5551 28.5541 26.3024 28.7296 25.9981C28.9051 25.6938 28.9973 25.3486 28.997 24.9974C28.9967 24.6461 28.9039 24.3011 28.7279 23.9971L17.7309 4.99809C17.5552 4.69457 17.3028 4.44258 16.999 4.2674C16.6952 4.09222 16.3507 4 16 4C15.6493 4 15.3047 4.09222 15.0009 4.2674C14.6971 4.44258 14.4447 4.69457 14.269 4.99809V4.99809Z"
      stroke="#FEA04A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 22.5C16.5 22.7761 16.2761 23 16 23C15.7239 23 15.5 22.7761 15.5 22.5C15.5 22.2239 15.7239 22 16 22C16.2761 22 16.5 22.2239 16.5 22.5Z"
      fill="#FEA04A"
      stroke="#FEA04A"
      strokeWidth="2"
    />
  </svg>
);
